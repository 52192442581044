import NavItem from "../NavItem"
import { ServicesThemesList } from "../../../Pages/Home/HoroscopeOfDay"

const MenuServices = ({mobile}) => {
    // #region Mobile item menu
    if (mobile === true) {
        return (
            <NavItem mobile={true} url="/servicios" label="Servicios" />
        )
    }
    //#endregion

    //#region Desktop submenu
    return (
        <NavItem className="flex-grow" url="/servicios" label="Servicios">
            {
                Object.keys(ServicesThemesList).map((key, idx) => (
                    <NavItem key={`service-${idx}`} url={`/servicios/${ServicesThemesList[key]['link']}`} label={key} />
                ))
            }
        </NavItem>
    )
    //#endregion
}

export default MenuServices