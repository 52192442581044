import React, { useContext } from "react";
import { Context } from '../../store/useGlobalState'
import { calculateUserDisplayName } from "../../utils/misc";
import Avatar from '../Avatar'
import Dropdown from "../Dropdown";
import UserAvatar from "../../Components/UserAvatar"
// UserProfile
export const UserProfile = () => {
    const { globalState } = useContext(Context)

    return <>
        <UserAvatar className="relative w-12 h-12 mr-4 inline-block" />
        <Dropdown>
            {calculateUserDisplayName(globalState.user)}
        </Dropdown>
    </>
}

export default UserProfile