import NavItem from "../NavItem"

const categories = {
    'psychology': 'Psicología',
    'therapy': 'Terapia',
    'health': 'Salud y bienestar',
    'love': 'Amor',
    'life': 'Vida',
    'depress': 'Depresión',
    'anxiety': 'Ansiedad',
    'tips': 'Tips'
}

const categoriesTexts = {
    'psychology': 'psicologia',
    'therapy': 'terapia',
    'health': 'Salud-y-bienestar',
    'love': 'amor',
    'life': 'vida',
    'depress': 'depresion',
    'anxiety': 'ansiedad',
    'tips': 'tips'
}

const MenuArticles = ({ mobile }) => {
    if (mobile === true) {
        return <NavItem mobile={true} url="/articulos" label="Artículos" />
    }

    return (
        <NavItem className="flex-grow" url="/articulos" label="Artículos">
            {
                Object.keys(categories).map((x) => {
                    return <NavItem url={`/articulos/${categoriesTexts[x]}`} label={categories[x]} key={`nav-article-${x}`} />
                })
            }
        </NavItem>
    )
    //#endregion
}

export default MenuArticles