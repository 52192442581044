import assets from './../../Assets'

const Glossary = () => {
   return (
      <div className="grid grid-cols-1 md:grid-cols-5 px-6 pt-10">
         <div className="col-span-2 lg:pr-10 pb-3">
            <div className="mb-8">
               <span className="font-semibold" style={{ color: '#C0C9FF' }}>
                  Glosario
               </span>
            </div>
            <div className="text-3xl font-semibold mb-3">Glosario</div>
            <sub className="block text-base formatted-text-container">
               Conoce más de los términos que usan nuestros psicólogos, terapeutas y coaches.
            </sub>
         </div>
         <div className="col-span-3">
            <img className="rounded mx-auto" src={assets.GlossaryImage} />
            <div>
               <div>
                  <div className="font-semibold mt-4">
                     Actitud
                  </div>
                  <p>
                     Tendencia o predisposición adquirida y relativamente duradera a evaluar de determinado modo a una persona, suceso o situación” (Vander, J., 1986; p.614).
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Adaptación
                  </div>
                  <p>
                     Según Piaget, el desarrollo de capacidades mentales conforme los organismos interactúan y aprenden a enfrentarse con su medio. Está compuesto por la asimilación y la acomodación o ajuste.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Adicciones
                  </div>
                  <p>
                     La adicción es una enfermedad primaria, crónica con factores genéticos, psicosociales y ambientales que influencian su desarrollo y manifestaciones. Está caracterizada por episodios continuos o periódicos de: descontrol sobre el uso, uso a pesar de consecuencias adversas, y distorsiones del pensamiento, siendo la más frecuente la negación.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Agresividad
                  </div>
                  <p>
                     Es un estado emocional que consiste en sentimientos de odio y deseos de dañar a otra persona, animal u objeto, pretender herir física y o psicológicamente a alguien.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Ajuste
                  </div>
                  <p>
                     Proceso en el que se intenta satisfacer las exigencias del yo y del entorno.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Ansiedad
                  </div>
                  <p>
                     Estado de malestar o sufrimiento emocional ante la anticipación de una posible estimulación aversiva en el futuro, que genera reacciones cognitivas, fisiológicas y conductuales.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Antisocial
                  </div>
                  <p>
                     Aplicado al comportamiento que hace que los individuos entren en conflicto con la sociedad.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Aprendizaje
                  </div>
                  <p>
                     Para Hilgard es el proceso en virtud del cual se origina o se cambia la actividad de reacción ante una situación dada. Según Guthrie es responder de un modo diferente a una situación como consecuencia de una precedente respuesta a la situación.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Asimilación
                  </div>
                  <p>
                     Término de Piaget para la toma de información y su categorización con base en lo que ya se sabe, más común, absorción; asimismo es un fenómeno psicológico social, subrayando la analogía entre individuos similares.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Autoconfianza
                  </div>
                  <p>
                     Según la teoría de Albert Bandura, la autoconfianza alude a la percepción de la propia capacidad para lograr determinados objetivos y llevar a cabo diversas acciones de forma exitosa. También conocida como autoeficacia.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Autoestima
                  </div>
                  <p>
                     Valoración que hacemos de nosotros mismos, el grado que nos queremos como personas en todas y cada una de sus dimensiones. Una autoestima adecuada, vinculada a un concepto positivo de sí mismo, potenciará la capacidad de las personas para desarrollar sus habilidades y aumentará el nivel de seguridad personal, mientras que una autoestima baja enfocará a la persona hacia la derrota y el fracaso.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Autoconocimiento
                  </div>
                  <p>
                     Es la habilidad de conectarse con nuestros sentimientos, pensamientos y acciones. Tener autoconocimiento también significa poder reconocer cómo nos perciben otras personas.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Coaching
                  </div>
                  <p>
                     Es un proceso de acciones coordinadas de aprendizaje interno y autoconocimiento. En este proceso el coachee o cliente, con ayuda del coach trabajan para alcanzar las metas y objetivos del coachee ya sea en el plano personal, profesional o cualquier otro.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Codependencia
                  </div>
                  <p>
                     Hace referencia a una actitud obsesiva y compulsiva hacia el control de otras personas y las relaciones, fruto de la propia inseguridad.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Codificación
                  </div>
                  <p>
                     Proceso de preparar información para almacenarla en la memoria; a menudo implica la percepción y la presentación de material en forma tal que el sistema de almacenamiento pueda manejarlo; quizá incluya la asociación con el conocimiento o la experiencia.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Cognición
                  </div>
                  <p>
                     Es el proceso de conocer empleado por los psicólogos para designar cualquier actividad mental, abarca el uso del lenguaje, el pensamiento, el razonamiento, la solución de problemas, la conceptualización, el recuerdo y la imaginación.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Complejo de Edipo
                  </div>
                  <p>
                     Conflicto que se manifiesta en la etapa fálica, según la teoría freudiana, cuando un niño pequeño desea a su madre en nivel sexual y siente mucha rivalidad hacia su padre.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Complejo de Electra
                  </div>
                  <p>
                     Según Freud, es un conflicto experimentado por las niñas pequeñas durante la etapa fálica cuando se supone que las mujeres se vuelven contra sus madres y desean a sus padres de forma sexual. Su resolución está vinculada con el desarrollo de la identidad femenina y la incorporación de un superego.
                     Condicionamiento
                     Término utilizado en forma sinónima con tipos sencillos de aprendizaje, en especial el condicionamiento operante y respondiente.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Comportamiento
                  </div>
                  <p>
                     Manera de actuar frente a determinada situación. Frecuentemente se emplea como sinónimo de conducta.
                     Comunicación
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Conducta
                  </div>
                  <p>
                     Cualquier acto o acción realizado por un organismo, generalmente de manera intencional y voluntaria.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Delirio
                  </div>
                  <p>
                     Alteración del contenido del pensamiento. Se presenta una idea o creencia determinada que se vivencia como real y se caracteriza por ser de gran intensidad, irracional y resistente al cambio a pesar de pruebas contrarias a ella.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Depresión
                  </div>
                  <p>
                     Trastorno del estado de ánimo caracterizado por un estado de ánimo triste y/o irritable, la presencia de anhedonia y otros síntomas como desesperanza, sensación de indefensión, disminución de la capacidad de atención, y percepción de la realidad desde una perspectiva negativa en lo que se refiere al mundo, a sí mismos y al propio futuro.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Ego
                  </div>
                  <p>
                     De acuerdo con Freud, un componente de la personalidad que emerge en los niños en desarrollo para manejar las transacciones con el ambiente; localiza los objetos reales que satisfacen las necesidades del id.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Empatía
                  </div>
                  <p>
                     Capacidad de percibir, detector y compartir el estado de ánimo y/o la perspectiva de una persona, sabiendo ponerse en su lugar.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Estimulación
                  </div>
                  <p>
                     Entrada a los sentidos; es también una técnica para estudiar la relación entre el cerebro y en comportamiento o la cognición; se aplican sustancias químicas o corriente eléctrica muy leve a regiones específicas del cerebro para aprender sobre sus funciones.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Estímulo
                  </div>
                  <p>
                     Suceso, objeto o situación que provoca una respuesta.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Estrés
                  </div>
                  <p>
                     Estado de intensa activación fisiológica que pretende actuar como mecanismo para afrontar una situación amenazante. Si se prolonga en el tiempo puede generar cansancio y agotamiento ante el desgaste provocado por el uso continuado de recursos energéticos tanto a nivel físico como mental.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Exposición
                  </div>
                  <p>
                     Tipo de terapia que se basa en la confrontación del paciente con aquello que teme o le causa ansiedad, de cara a que este sea capaz de hacerle frente y disminuir progresivamente el nivel de temor que le causa. Suele emplearse en base a una jerarquía según lo que supone mayor o menor nivel de ansiedad al paciente, avanzando de manera más o menos paulatina.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Fobia
                  </div>
                  <p>
                     Miedo irracional, desproporcionado y de elevada intensidad a un estímulo o situación concreto que provoca la evitación (o deseo de evitación) conductual del estímulo. El estímulo temido genera ansiedad y angustia. La existencia de una fobia puede provocar una alteración en la vida habitual del individuo que lo padece.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Hemisferio cerebral
                  </div>
                  <p>
                     Una de las dos mitades casi simétricas del cerebro. El hemisferio derecho gobierna la parte izquierda del cuerpo, mientras que el hemisferio izquierdo controla el lado derecho del cuerpo. El hemisferio especializado para el lenguaje se conoce como el principal, el otro como el menor.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Hipnosis
                  </div>
                  <p>
                     Estado alterado de conciencia producido por una serie de sugestiones persuasivas, durante el cual los individuos se sienten muy sensibles a la influencia del hipnotista.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Id
                  </div>
                  <p>
                     Componente de la personalidad, según Freud, que yace en el corazón primitivo de la gente; contiene los impulsos básicos que presionan para obtener gratificación inmediata.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Identificar
                  </div>
                  <p>
                     Término freudiano que significa el esfuerzo por convertirse en alguien más, por lo general, un padre.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Ilusión
                  </div>
                  <p>
                     Percepción o interpretación distorsionada de un estímulo real existente, aunque ambiguo.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Inconsciente
                  </div>
                  <p>
                     Que no se percata de algo; Freud suponía que las personas no se dan cuenta de la mayor parte de sus pensamientos, sentimientos y deseos, y no pueden hacerlo sin la ayuda del psicoanálisis.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Influencia
                  </div>
                  <p>
                     Capacidad de un sujeto para alterar el comportamiento o pensamiento de otro.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Instinto
                  </div>
                  <p>
                     Necesidades fisiológicas y modelos de comportamiento complejos determinados en gran parte por la herencia.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Inteligencia
                  </div>
                  <p>
                     Capacidad para la actividad mental que no puede medirse de modo directo.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Introspección
                  </div>
                  <p>
                     Es el proceso de mirar al interior de uno mismo y analizar las experiencias personales.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Introversión
                  </div>
                  <p>
                     Rasgo de personalidad caracterizado por la concentración en el mundo interior y en los procesos mentales propios, precisando de una menor activación proveniente del medio.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Lenguaje
                  </div>
                  <p>
                     Sistema que asocia símbolos con significado y proporciona reglas para combinarlos y remezclarlos para la comunicación.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Libido
                  </div>
                  <p>
                     Concepto freudiano que denota una cantidad fija de energía generada por los impulsos sexuales para las funciones mentales y conductuales.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Liderazgo
                  </div>
                  <p>
                     Capacidad de guiar y dirigir la conducta de otras personas hacia un objetivo concreto, pudiendo gestionar la actuación de otras personas, estableciendo objetivos y motivando hacia su consecución.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     LGBTQ+
                  </div>
                  <p>
                     Lesbianas, gays, bisexuales, transexuales, transgénero, y queer.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Manía
                  </div>
                  <p>
                     Estado de ánimo expansivo y altamente activado, con aceleración del pensamiento y del habla, elevada impulsividad que puede llegar a la irritabilidad y a la hostilidad.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Mecanismo de defensa
                  </div>
                  <p>
                     Término freudiano para tácticas cognoscitivas que el ego utiliza, sin que la persona se percate de ello, para falsificar y distorsionar vivencias amenazadoras, impulsos, conflictos e ideas, con el fin de aliviar la tensión.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Memoria a corto plazo (MCP)
                  </div>
                  <p>
                     Sistema de memoria que retiene todos los materiales de los cuales los individuos están conscientes en un momento dado; almacena una cantidad limitada de datos, por lo general, por casi 15 segundos.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Memoria a largo plazo (MLP)
                  </div>
                  <p>
                     Sistema de memoria más o menos permanente.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Miedo
                  </div>
                  <p>
                     Emoción que puede diferenciarse de la ansiedad por la existencia de un objeto fácil de señalar y por la intensidad que es proporcional a la magnitud del peligro; a veces se usa como sinónimo de ansiedad.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Modificación de conducta
                  </div>
                  <p>
                     Conjunto de procedimientos reeducativos usados para solucionar problemas humanos, derivados o congruentes con la investigación psicológica y evaluados de manera sistemática. A menudo llamada terapia conductual cuando se le utiliza en ambientes de salud mental.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Motivación
                  </div>
                  <p>
                     La motivación es aquello que impulsa a una persona a adoptar una cierta conducta en pos de un objetivo.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Negativo
                  </div>
                  <p>
                     Un término de condicionamiento operante que se aplica al refuerzo o castigo queriendo decir que la consecuencia se retiró después de la operante.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Obsesión
                  </div>
                  <p>
                     Pensamiento repetitivo e incontrolado que aparece de forma espontánea en la mente y procede a repetirse insistentemente, siendo percibido como inadecuado y altamente angustiante. Este pensamiento es vivido como como propio a pesar no ser controlado, y suele intentar evitarse a través de diversos mecanismos. Se trata del núcleo principal del trastorno obsesivo-compulsivo.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Pensamiento
                  </div>
                  <p>
                     Actividad mental orientada a la interpretación de símbolos (internos o externos) con la consiguiente atribución de significados.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Percepción
                  </div>
                  <p>
                     Proceso de organización e interpretación de datos sensoriales que entran para desarrollar una conciencia del yo y del entorno; incluye sistemas visual, auditivo, somatosensorial, químico y propioceptivo.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Personalidad
                  </div>
                  <p>
                     Identidad distintiva formada por patrones de relativa consistencia y durabilidad de percepción, pensamiento, sentimiento y comportamiento.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Personalidad múltiple
                  </div>
                  <p>
                     Perturbación en la cual un individuo muestra dos o más personalidades bien desarrolladas en rápida sucesión.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Positivo
                  </div>
                  <p>
                     Característico de un reforzador o estímulo punitivo, significando que se presenta después de que ocurre la conducta que se condicionará.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Proyección
                  </div>
                  <p>
                     Mecanismo de defensa en el cual el sujeto identifica características propias en otros individuos, colectivos, objetos o entidades.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Psicoanálisis
                  </div>
                  <p>
                     Paradigma de la psicología centrado en el inconsciente y en la existencia de conflictos entre los instintos y su expresión externa. Se centra en gran medida en la parte profunda de la psique y su análisis, trabajando con elementos simbólicos. Asimismo, también se focaliza en las vivencias pasadas de los pacientes, especialmente en aquellas ocurridas a lo largo del desarrollo. El psicoanálisis es considerado un marco teórico, una técnica de investigación y un método terapéutico.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Rasgo
                  </div>
                  <p>
                     Característica de la persona consistente a través de las diferentes situaciones y que permanece estable a través del tiempo. Se trata de un elemento que permite establecer una tendencia a ser, ver el mundo o actuar de determinada manera.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Represión
                  </div>
                  <p>
                     Principal mecanismo de defensa, consistente en la erradicación de los contenidos mentales que resultan inaceptables para el sujeto.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Resiliencia
                  </div>
                  <p>
                     Capacidad de hacer frente a situaciones traumáticas de manera adaptativa, saliendo fortalecido de dichas situaciones.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Respuesta
                  </div>
                  <p>
                     Conducta suscitada por un estímulo; es también cualquier reacción.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Rol
                  </div>
                  <p>
                     Papel o función asignado a una persona en una situación determinada. Supone una serie de expectativas respecto a la conducta de la persona a la cual se ha asignado con el rol en cuestión.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Sentido
                  </div>
                  <p>
                     Mecanismo de recopilación de información que permite a los animales captar datos referentes a ellos mismos y al entorno, de modo que pueda planear y controlar la conducta para moverse de manera más efectiva.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Signo
                  </div>
                  <p>
                     Elemento directamente observable para un observador externo. Se trata de un elemento objetivo que puede servir como indicador fiable de la existencia de un trastorno o problema.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Sinapsis
                  </div>
                  <p>
                     Punto de interconexión entre dos o más neuronas. Se trata de un espacio intermedio en el que se produce la emisión y recaptación de los diversos neurotransmisores.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Síntoma
                  </div>
                  <p>
                     Señal o indicio de carácter subjetivo de un estado anómalo o patológico. Deber ser referido por el propio sujeto para ser detectado, al no ser directamente observable.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Síntomas positivos
                  </div>
                  <p>
                     Tipo de síntomas que se añaden al funcionamiento habitual del sujeto. Ejemplos de ello pueden ser las alucinaciones, los delirios o el comportamiento desorganizado. Típicos de la esquizofrenia y de los trastornos psicóticos.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Síntomas negativos
                  </div>
                  <p>
                     Conjunto de síntomas que provocan una disminución en las capacidades habituales ya existentes en un sujeto. Por ejemplo, embotamiento afectivo o alogia. Suelen tener peor respuesta al tratamiento que los síntomas positivos y pueden causar deterioro a largo plazo.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Socialización
                  </div>
                  <p>
                     Proceso de orientación de los niños hacia el comportamiento, valores, metas y motivos considerados apropiados por una cultura.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Relaciones tóxicas
                  </div>
                  <p>
                     Son relaciones en las que ambas partes son incapaces, por alguna razón, de impedir hacerse daño. Puede tratarse de una relación de pareja, pero también de amistad, de trabajo, incluso de una relación familiar.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     TDAH
                  </div>
                  <p>
                     Trastorno por déficit de atención e hiperactividad
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Terapia Psicológica
                  </div>
                  <p>
                     Es un tratamiento de colaboración basado en la relación entre una persona y el psicólogo. Como su base fundamental es el diálogo, proporciona un ambiente de apoyo que le permite hablar abiertamente con alguien objetivo, neutral e imparcial.
                  </p>
               </div>

               <div>
                  <div className="font-semibold mt-4">
                     Timidez
                  </div>
                  <p>
                     Es un estado de ánimo que afecta a las relaciones personales. Se la considera una pauta de comportamiento que limita el desarrollo social de quienes lo experimentan dentro de su vida cotidiana.
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Glossary