import React, { useEffect, useState } from "react"
import ImagePaymentProcessed from "../../Pages/Profile/ImagePaymentProcessed"
import SecondaryLinkButton from "../../Components/Button/SecondaryLinkButton"

const MessageSuccess = ({ orderId }) => {
    return <section className="flex flex-col gap-y-6">
        <div className="flex-row text-2xl font-semibold text-center justify-center">¡Pago procesado!</div>
        <span className="text-center">Gracias por continuar con nosotros</span>
        <ImagePaymentProcessed className="h-96" />
        <p className="text-center">Tu orden #{orderId} ha sido confirmada exitosamente</p>
        <p className="text-center">Ya puedes platicar y consultar a los mejores expertos</p>
        <div className="flex flex-row justify-center mb-3">
            <SecondaryLinkButton className="px-6 py-2 max-w-sm mx-auto" url={`/expertos`} label="Continuar navegando" />
        </div>
    </section>
}

export default MessageSuccess