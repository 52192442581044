import { useEffect, useState } from "react"
import { formatCurrency, multiply, sum, toNumber } from "../../utils/parsers"

const PaymentInfoRow = ({ label, value, title, divider }) => (
   <div className="flex flex-col pt-4 gap-y-4">
      {title ? <legend className="font-semibold text-xl">{title}</legend> : null}

      {Array.isArray(label) && Array.isArray(value)
         ?
         label.map((labelText, key) =>
            <div key={key} className="flex flex-row">
               <label className="flex-col flex-grow text-left">{labelText}</label>
               <label className="flex-col flex-shrink text-right">{value[key]}</label>
            </div>
         )
         :
         <div className="flex flex-row">
            <label className="flex-col flex-grow text-left">{label}</label>
            <label className="flex-col flex-shrink text-right">{value}</label>
         </div>
      }
      {divider === true ? <hr /> : null}
   </div>
)

export const PaymentConfirmation = ({ paymentid, paymentName, cardNumber, amount, couponData }) => {
   const [totalToPay, setTotalToPay] = useState(amount)

   useEffect(()=> {
      if(couponData && couponData.discountType && couponData.value) {
         let tempValue = 0

         if (couponData.discountType==='PRICE') {
            tempValue = amount-couponData.value
         } else {
            tempValue = Math.ceil(amount*(1- (couponData.value/100)))
         }

         setTotalToPay(tempValue>=10? tempValue: 10)
      }
   }, [couponData])

   return (
      <aside className="w-full px-2 lg:px-8 lg:py-8 pb-9">
         <PaymentInfoRow title="Método de pago"
            label={paymentName}
            value={cardNumber}
            divider={true}
         />

         <PaymentInfoRow title="Resumen de pago"
            label={[
               "Monto de la compra"
            ]}
            value={[
               isNaN(toNumber(amount)) ? '0.00' : formatCurrency(amount)
            ]}
            divider={true}
         />

         {
            (couponData && couponData.discountType && couponData.value) && (
               <PaymentInfoRow title="Cupón de descuento"
                  label={couponData.discountType==='PRICE'?'Valor descontado': 'Porcentaje descontado'}
                  value={couponData.discountType==='PRICE'?formatCurrency(couponData.value):
                     (couponData.value + '%')}
                  divider={true}
               />
            )
         }

         <PaymentInfoRow
            label="Total a pagar"
            value={formatCurrency(totalToPay)}
         />
      </aside>
   )
}

export default PaymentConfirmation