import React from 'react'
import { Link } from 'react-router-dom'
import assets from '../../Assets/'
import { IconChevronRight } from '../../Components/Icons'

const WhyUs = () => {
    return (
        <section className="md:grid md:grid-cols-2 mx-auto">
            <div className='md:mr-1'>
                <img src={assets.Home_WhyOurPlatform} className="w-full h-full" alt="Paz Mental" />
            </div>
            <div>
                <div className="p-4 flex items-center">
                    <div>
                        <div className="flex text-4xl nunito-font-semibold">
                            <div>Nuestra</div>
                            <div className="mx-2 text-primary">Misión</div>
                        </div>
                        <p className="mt-4">
                            Hacer que la terapia profesional sea accesible, asequible y conveniente,
                            para que cualquiera que luche con los desafíos de la vida pueda obtener
                            ayuda, en cualquier momento y en cualquier lugar.
                        </p>
                        <div className="flex flex-col w-full lg:gap-y-6 md:gap-y-4 mt-14 xl:gap-y-10" >
                            <ReasonRow
                                icon={assets.Icon_Etica}
                                title="Código de ética"
                                text="Nuestros psicólogos, terapeutas y coaches se comprometen a tratar a los clientes con respeto, compasión y profesionalismo."
                            />
                            <ReasonRow
                                icon={assets.Icon_Expertos}
                                title="Los mejores psicólogos, terapeutas y coaches"
                                text="Conocimiento, dones, habilidades y experiencia. Cada uno de nuestros psicólogos, terapeutas y coaches cuenta con capacidades y estilos diferentes. Encuentra el que más te guste."
                            />
                            <ReasonRow
                                icon={assets.Icon_Recompensas}
                                title="Flexibilidad"
                                text="Haz tu consulta desde donde quieras y cuando quieras. Directo y fácil de usar."
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 flex items-center">
                <div>
                    <div className="flex text-4xl nunito-font-semibold">
                        ¿<div>Cómo</div>
                        <div className="mx-2 text-primary">funciona?</div>
                    </div>
                    <div className="mt-10">
                        <HowItWorksRow number="1"
                            text="Elige a tu psicólogo, terapeuta o coach. En base a tus preferencia filtra los terapeutas, psicólogos y coaches, y elige al que tu quieras." />
                        <HowItWorksRow number="2"
                            text="Si ya tienes una cuenta, solo tienes que dar clic en el botón para iniciar la sesión por llamada o por videollamada. Si prefieres hacer una cita para otra ocasión, elige el día y hora de preferencia y agenda tu sesión. Si no tienes cuenta, es hora de que te registres y pruebes los servicios que ofrecemos" />
                        <HowItWorksRow number="3"
                            text="Termina la sesión cuando tu quieras." />
                    </div>
                </div>
            </div>
            <div className='md:ml-1'>
                <img src={assets.Home_WhyOurPlatform2} className="w-full h-full" alt="Paz Mental" />
            </div>
            <div className="col-span-2 mt-24">
                <div className="flex flex-row w-full mx-auto lg:w-10/12 rounded-2xl xl:max-w-7/12 bg-secondary overflow-hidden">
                    <div className="flex flex-col xl:pl-20 xl:pr-10 text-center">
                        <div className="flex-row mt-10 mb-4 tracking-wide text-3xl nunito-font-bold">
                            Satisfacción garantizada
                        </div>
                        <p className="flex-row leading-normal mt-2 text-primary">
                            Mantenémos tu información segura con diferentes medidas de
                            seguridad. Así mismo, garantizamos nuestro servicio contigo,
                            si no estás satisfecho con tu última consulta pagada, !Te
                            devolvemos tu dinero!
                        </p>
                        <p className="flex-row py-2 xl:py-0 mt-6 text-sm">
                            <Link to="/satisfaccion-garantizada" className="bg-primary text-white rounded-md px-4 py-3 inline-flex items-center">
                                <span>Conoce más</span>
                            </Link>
                        </p>
                    </div>
                    <div className="hidden xl:flex-col xl:inline-block">
                        <div className="flex-col items-center grid-cols-1 align-middle diagonal-fractions justify-items-center md:h-72" style={{ width: '30em', overflow: 'hidden' }}>
                            <div style={{ width: '25em', position: 'absolute', height: '18em' }} className="flex flex-col items-center content-end justify-center">
                                <div className="grid flex-col col-span-2 align-middle" style={{ position: "absolute", right: 0, zIndex: 1 }}>
                                    <span className=""><img src={assets.Home_ShieldCheck} className="w-full h-full" alt="Satisfacción garantizada" /></span>
                                </div>
                            </div>
                            <div style={{ height: '100%' }}>
                                <div style={{ background: 'rgb(168,228,224)', borderRadius: '50%', transform: 'translate(5em, -3.1em)', height: '25em', width: '25em' }}>
                                    <div style={{ background: 'rgb(162,220,221)', borderRadius: '50%', transform: 'translate(8em, 1em)', height: '22em', width: '22em' }}>
                                        &amp;nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

// #region Subcomponentes
const ReasonRow = ({ title, icon, text }) => (
    <article className="flex flex-row">
        <div style={{ minWidth: '80px' }}>
            <img src={icon} alt={title} className="inline-block w-12 h-12" />
        </div>
        <div>
            <div className="text-primary">{title}</div>
            <p className="text-sm">{text}</p>
        </div>
    </article>
)

const HowItWorksRow = ({ number, text }) => (
    <div className="flex flex-row items-center my-3">
        <div style={{ minWidth: '80px' }} className="inline-flex items-center justify-start">
            <span className="inline-flex h-12 w-12 text-primary text-xl items-center justify-center bg-gray-200 rounded-full">
                {number}
            </span>
        </div>
        <div>
            <p className="text-sm">{text}</p>
        </div>
    </div>
)

// #endregion

export default WhyUs