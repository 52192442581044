import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const Manifest = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Manifiesto</div>
          <sub className="block text-base"></sub>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Manifest} alt="Manifest" />
          <div className="block mt-5">
            <div className="mb-5">
              <div className="block mb-5 font-semibold">Estamos muy contentos de que 
              estés aquí y conozcas a nuestros expertos de confianza.</div>
              <p className="mb-2">
                En Mi Paz Mental estamos dedicados a ofrecer soluciones personalizadas 
                para tus necesidades de paz mental seguras, confiables y profesionales. 
                Los expertos pueden ayudarte en tu proceso a la felicidad, el éxito y 
                la realización. Cuando haces una consulta en Mi Paz Mental, creas una 
                conexión con uno de nuestros psicólogos, terapeutas y coaches quien 
                te ayudará a llegar a tus fuerzas internas y recursos para que logres 
                tus metas. Nuestros expertos crean una atmósfera cálida y enriquecedora 
                en la cual podrás discutir hasta las situaciones más íntimas de manera 
                segura.
              </p>
              <ol className="mb-5 pl-4">
                <li className="list-disc">Seguro</li>
                <li className="list-disc">Profesional</li>
                <li className="list-disc">Confiable</li>
                <li className="list-disc">Escuchar</li>
                <li className="list-disc">Apoyo</li>
                <li className="list-disc">Conveniente</li>
                <li className="list-disc">Personal</li>
                <li className="list-disc">Auténtico</li>
                <li className="list-disc">Íntimo</li>
                <li className="list-disc">Perspectivas</li>
                <li className="list-disc">Comprensivo</li>
                <li className="list-disc">Amigable</li>
                <li className="list-disc">Orientación</li>
                <li className="list-disc">Confidencialidad</li>
                <li className="list-disc">Transparencia</li>
                <li className="list-disc">Pasión</li>
                <li className="list-disc">Compromiso</li>
                <li className="list-disc">Accesible</li>
                <li className="list-disc">Atento</li>
                <li className="list-disc">Compasivo</li>
                <li className="list-disc">Privacidad</li>
                <li className="list-disc">Comodidad</li>
                <li className="list-disc">Validación</li>
                <li className="list-disc">Respeto</li>
                <li className="list-disc">Cortesía</li>
                <li className="list-disc">Comunidad mundial</li>
                <li className="list-disc">Servicio</li>
                <li className="list-disc">Motivación</li>
                <li className="list-disc">Positivismo</li>
                <li className="list-disc">Amable</li>
              </ol>
            </div>
            <div className="mb-5">
              <p>
                Nos orgullece nuestra comunidad de psicólogos, terapeutas y coaches 
                atentos, compasivos, con gran experiencia y conocimiento. Nuestros 
                psicólogos, terapeutas y coaches están aquí para apoyarte, proporcionando 
                recomendaciones precisas y directas, para motivarte a dar los siguientes pasos.
              </p>
            </div>
            <div className="mb-5">
              <div className="block mb-5 font-semibold">Obtén las respuestas que necesitas y haz que las cosas sucedan.</div>
              <p>
                Sigue adelante con confianza. Vive la experiencia de una consulta profesional ahora mismo.
              </p>
            </div>
          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Manifest