import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"

const ClientService = () => {
    const sideMenuItems = [
        {
            title: "Manifiesto",
            url: "manifiesto"
        },
        {
            title: "Código de ética",
            url: "codigo-de-etica"
        },
        {
            title: "Satisfacción garantizada",
            url: "satisfaccion-garantizada"
        },
        {
            title: "Testimonios",
            url:"testimonios"
        },
        {
            title: "Contáctanos",
            url:"contacto"
        },
        {
            title: "Preguntas frecuentes",
            url:"preguntas-frecuentes"
        },
        {
            title: "Cómo funciona",
            url:"como-funciona"
        },
        {
            title: "Precios",
            url:"precios"
        }

    ]
    return <>
        <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
            <section className="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div className="col-span-2 lg:pr-10 pb-3">
                    <div className="text-3xl font-semibold mb-3">Centro de servicio al cliente</div>
                    <sub className="block text-base">
                        En Mi Paz Mental estamos dedicados a ofrecerte la mejor atención y servicio, 
                        creemos de todo corazón que cada experiencia debe ser impecable, y estamos 
                        comprometidos a lograrlo.
                    </sub>
                    <SideMenu src={sideMenuItems} />
                </div>
                <div className="col-span-3">
                    <img className="rounded" src={assets.ClientServiceHeader} />
                    <div className="block mt-5">
                        <div className="mb-5">
                            <div className="block mb-5 font-semibold">Gracias por darnos la oportunidad de servirte</div>
                            <p>
                                Mi Paz Mental es una comunidad confiable de psicólogos, terapeutas 
                                y coaches que se dedican a empoderar vidas ayudando a las personas 
                                en sus necesidades de paz mental. No hay dos expertos en nuestra 
                                comunidad iguales, ya que cada uno tiene su propio toque personal 
                                cuando se trata de brindar soluciones personalizadas para tus 
                                necesidades de paz mental.
                            </p>
                        </div>
                        <div className="mb-5">
                            <p>
                                Nuestro equipo de servicio al cliente está disponible los siete días 
                                de la semana, las 24 horas del día, para hacer de tu experiencia en 
                                nuestra plataforma de psicólogos, terapeutas y coaches, simple, 
                                directa y satisfactoria siempre.
                            </p>
                        </div>
                        <div className="mb-5">
                            <div className="block mb-5 font-semibold">Seguridad y confianza</div>
                            <p>
                                Nuestra prioridad es conectarte con los mejores expertos, psicólogos, 
                                terapeutas y coaches en la industria mientras protegemos tu seguridad 
                                y garantizamos una experiencia de cliente de primer nivel. Mi Paz 
                                Mental actúa como barrera entre tú y tu experto, psicólogo, terapeuta 
                                o coach. Nos aseguramos de que tu número de teléfono, correo electrónico 
                                y cualquier otra información personal nunca sean revelados porque 
                                administramos la conexión entre tu y cualquier persona con la que 
                                contactes en la plataforma de psicólogos, terapeutas y coaches de Mi 
                                Paz Mental. Al realizar las llamadas, el experto, psicólogo, terapeuta o 
                                coach con el que estas conectando no puede ver tu número de teléfono, 
                                ni siquiera con el identificador de llamadas. Cada transacción es anónima 
                                y segura. No importa con cuantos psicólogos, terapeutas o coaches te 
                                conectes, ten la confianza de que tu cuenta es privada y segura. 
                                Cuando te registras solo necesitas ingresar tu información personal una 
                                sola vez y listo.
                            </p>
                        </div>
                        <div className="mb-5">
                            <p>
                                ¡Contáctanos cuando quieras! Estamos para servirte.
                                Llámanos al 3311421316 o envíanos un correo electrónico a hola@mipazmental.com
                            </p>
                        </div>
                    </div>
                    <div>
                        <img className="h-40" src={assets.SatisfactionGuaranteed} /> 
                    </div>
                </div>
            </section>
        </div>
        <Recommended />
    </>
}

const SideMenu = ({ src, ...props }) => {
    return <div className="mt-30 pr-10 lg:pr-0">
        {
            src.map((x, i) => {
                return <div className="mb-6" key={`sub-consultation-${i}`}>
                    <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
                </div>
            })
        }
    </div>
}


export default ClientService