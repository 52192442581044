import { useEffect, useState } from "react"
import { ToastError } from "../../Components/Toast"
import sessionInt from '../../integrations/session'
import { calculateUserDisplayName, parseErrorMsg } from "../../utils/misc"
import time from "../../utils/time"
import DataGrid from "./partials/DataGrid2"
import Loader from "./partials/Loader"
import RateAnExpert from "../../Components/RateAnExpert"
import moment from "moment"
import Pagination from "../../Components/Pagination"

const NoData = () => (
    <div className="py-20 mt-8 text-center text-gray-400">
        <div className="mb-6 text-2xl font-semibold">No has realizado ninguna transacción</div>
        <p className="mx-auto lg:max-w-lg">
            Te invitamos a ponerte en contacto con alguno 
            de nuestros psicólogos, terapeutas y coaches, y conocerás el mejor servicio de consultas en línea.
        </p>
    </div>
)

const getServiceType = sessionType => {
    switch(sessionType) {
        case 'video_call': return "Videollamada";
        case 'phone_call': return "Llamada por teléfono";
        case 'internet_call': return "Llamada por internet";
    }
}

const SessionHistory = () => {
    const [sessions, setSessions] = useState([])
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState({})
    const [page, setPage] = useState(1)

    useEffect(() => {
        const fetchData = async () => {
            setSessions([])
            if (page !== 1) return
            try {
                let response = await sessionInt.getHistory()

                setSessions(response.payload.data)
                setPagination({
                    prev: page === 1 ? null : page - 1,
                    next: page === response.payload.totalPages ? null : page + 1,
                    totalPages: response.payload.totalPages,
                    pages: new Array(typeof response.payload.totalPages === 'number' ? Math.ceil(response.payload.totalPages) : 0).fill('page'),
                    current: page
                })
            } catch (error) {
                console.log(error)
                ToastError(parseErrorMsg(error))
            }
            setLoading(false)
        };
        fetchData()
    }, [page])

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    
    const rowParserer = (rowData) => {
        let duration = rowData?.duration < 60 ? `${rowData?.duration} seg.` : `${parseInt(rowData?.duration / 60)} min. `;
        duration += rowData?.duration > 60 && rowData?.duration % 60 > 0 ? `${rowData?.duration % 60} seg.` : '';

        const momentTime = moment.utc(rowData.createdAt).tz("America/Chicago");

        const { totalAmount } = rowData;
        let totalPrice = totalAmount?.toFixed(2);

        if (!totalAmount) {
            totalPrice = totalPrice = rowData.sessionType == 'video_call' ? (rowData.duration / 60 * rowData.expert.price_videocall).toFixed(2) : (rowData.duration / 60 * rowData.expert.price_phonecall).toFixed(2);
        }

        return ([
            calculateUserDisplayName(rowData.expert),
            getServiceType(rowData.sessionType),
            time.toHumanShort(rowData.date),
            momentTime.subtract(rowData.duration, 'seconds').tz(localTimeZone).format('HH:mm:ss'),
            duration,
            `$${totalPrice}`,
            <RateAnExpert evaluated={rowData.evaluated} expert={rowData.expert} session={rowData._id} handleClose={setPage}/>
        ])
    }

    const handlePagination = async (page) => {
        setPage(page)
        setLoading(true)
        try {
            let response = await sessionInt.getHistory(page)

            setSessions(response.payload.data)
            setPagination({
                prev: page === 1 ? null : page - 1,
                next: page === response.payload.totalPages ? null : page + 1,
                totalPages: response.payload.totalPages,
                pages: new Array(typeof response.payload.totalPages === 'number' ? Math.ceil(response.payload.totalPages) : 0).fill('page'),
                current: page
            })
        } catch (error) {
            ToastError(parseErrorMsg(error))
        }
        setLoading(false)
    }

    const headings = [
        'Experto',
        'Servicio',
        'Fecha de consulta',
        'Hora',
        'Duración',
        'Costo'
    ]

    return (
        <div className="border-2 rounded-lg border-secondary-lightest p-6 m-2">
            <div className="text-xl leading-loose font-semibold">Historial de sesiones</div>
            <p className="mt-4 text-wm">Estas son las consultas que has realizado en la plataforma</p>

            {loading
                ? <Loader />
                : sessions.length
                    ? <DataGrid
                        bold
                        className="w-full mt-8 overflow-x-auto"
                        data={sessions}
                        rowParserer={rowParserer}
                        headings={headings}
                        ratingOption={true}
                    />
                    : <NoData />
            }
            <div className="flex justify-end">
                <Pagination handlePagination={handlePagination} pagination={pagination} />
            </div>
        </div>
    )
}
export default SessionHistory