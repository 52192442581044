import React, { useContext, useEffect, useState } from "react"
import Loader from "../../../Components/Loader";
import { useParams, useHistory, Link } from "react-router-dom";
import assets from "../../../Assets"
import RecomendedLectures from "../../../Components/RecomendedLectures"
import SubConsultations from "./SubConsultations";
import { SecondaryButton } from "../../../Components/Button"
import consultationMethods from "../../../integrations/consultation"
import { IconChevronRight } from "../../../Components/Icons";

const ConsultationInfo = () => {
    const { consultationId, subConsultationId } = useParams()
    const [parentConsultation, setParentConsultation] = useState(null)
    const [consultationData, setConsultationData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dataNotFound, setDataNotFound] = useState(false)

    useEffect(async () => {
        setLoading(true)
        try {
            const res = await consultationMethods.getById(consultationId, subConsultationId)

            if (subConsultationId) {
                const parentRes = await consultationMethods.getById(consultationId, null)
                setParentConsultation(parentRes)
            } if (res) {
                setConsultationData(res)
            }
            setDataNotFound(res ? false : true)
            setLoading(false)
        } catch (error) {
            setDataNotFound(true)
            setLoading(false)
        }
    }, [consultationId, subConsultationId])
    return <>
        <div className="w-full max-w-md px-6 pt-10 mx-auto sm:max-w-3xl lg:max-w-7xl px-6 lg:px-0">
            {
                loading ? <Loader /> :
                    <section className="grid grid-cols-1 md:grid-cols-5">
                        {
                            dataNotFound ? <DataNotFound /> : <>
                                <div className="md:col-span-5">
                                    <div className="block lg:flex text-primary items-center mb-8">
                                        <Link to='/consultas' className="inline-block" style={{color: '#6E92C9'}}>
                                            <span>Acerca de las consultas</span>
                                        </Link>
                                        <span className="mx-3 inline-block"><IconChevronRight fill="currentColor" /></span>
                                        {
                                            subConsultationId ?
                                                <>
                                                    <Link to={`/consultas/${consultationId}`} style={{ color: '#6E92C9' }}>
                                                        <span>
                                                            {
                                                                parentConsultation?.title
                                                            }
                                                        </span>
                                                    </Link>
                                                    <span className="mx-3"><IconChevronRight fill="currentColor" /></span>
                                                </> : null
                                        }
                                        <p className="font-semibold -mt-2"
                                            style={{ color: '#6E92C9', wordBreak: 'break-word', hyphens: 'auto', marginBottom:'-2px' }}>
                                            {consultationData.title}
                                        </p>
                                    </div>
                                </div>
                                <div className="md:col-span-2 sm:pr-10 pb-3">
                                    <h1 className="text-2xl font-bold mb-3"
                                        style={{ wordBreak: 'break-word', hyphens: 'auto' }}>
                                            {consultationData.title}
                                    </h1>
                                    <sub className="block text-base formatted-text-container"
                                        dangerouslySetInnerHTML={{ __html: consultationData.description }}></sub>
                                    {subConsultationId ? <SubConsultationSideContent /> : <SubConsultations src={consultationData?.subrequests} />}
                                </div>
                                <div className="md:col-span-3 mb-4">
                                    <img className="rounded mx-auto" src={consultationData.image} />
                                    <div className="block mt-5 formatted-text-container" dangerouslySetInnerHTML={{ __html: consultationData.resume }}>
                                    </div>
                                </div>
                            </>
                        }
                    </section>
            }
        </div>
        <RecomendedLectures template="horizontal" />
    </>
}

const SubConsultationSideContent = () => {
    let history = useHistory()

    const handleScheduleConsultationClick = () => {
        history.push("/expertos");
    }
    return <div className="mt-20">
        <button className="px-5 py-3 text-sm mb-3 text-white bg-primary rounded-md"
            style={{fontWeight: '600' }}
            onClick={handleScheduleConsultationClick}>Agendar una consulta</button>
        <div className="mt-20">
            <img src={assets.SatisfactionGuaranteed} />
        </div>
    </div>
}

const DataNotFound = () => {
    return <div className="col-span-2 lg:pr-10 pb-3">
        <h1 className="text-3xl font-bold mb-3">No se encontraron resultados</h1>
        <sub className="block text-base">Intente más tarde</sub>
    </div>
}

export default ConsultationInfo