import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const Prices = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Precios</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Prices} alt="Prices" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                En Mi Paz Mental siempre sabes cuanto estás pagando por tu consulta. 
                Ya sea en línea o por teléfono, las tarifas de nuestros psicólogos, 
                terapeutas y coaches están claramente marcadas y establecidas para 
                tu conveniencia.
              </p>
            </div>
            <div className="mb-5">
              <div className="mb-5 font-semibold">Oferta de cliente nuevo</div>
              <p>
                Para los clientes nuevos tenemos una tarifa especial, por única vez, 
                en su primera compra. Debido a que esta oportunidad está disponible 
                solo una vez, les recomendamos a nuestros clientes que aprovechen 
                la tarifa especial en una consulta larga o varias cortas dependiendo 
                de lo que les permita la cantidad de minutos que hayan comprado. 
                Usa la oferta inicial para construir relaciones con los psicólogos, 
                terapeutas y coaches de nuestra comunidad en Mi Paz Mental.
              </p>
            </div>
            <div className="mb-5">
              <div className="mb-5 font-semibold">Precios y tarifas regulares por minuto</div>
              <p>
                Después de que hayas usado tu oferta de cliente nuevo, la tarifa 
                regular por minuto de los psicólogos, terapeutas y coaches con 
                los que hablas hará efecto. Estas tarifas van desde $10 pesos 
                hasta $30 pesos por minuto dependiendo del psicólogo, terapeuta 
                o coach que elijas y la forma en que hagas tu consulta, ya sea 
                por teléfono o por videollamada. Las tarifas por minuto de los 
                psicólogos, terapeutas y coaches en nuestra comunidad están claramente 
                publicadas en nuestro sitio web de Mi Paz Mental.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Siempre hay oportunidades de ahorrar en tus consultas. Suscríbete 
                para recibir ofertas promocionales y las novedades de Mi Paz Mental 
                por email.
              </p>
            </div>
          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Prices