import React from "react"
import { IconClose } from "../Icons"

const ModalDialog = ({ visible, close, ...props }) => {
    return (visible && (
        <div className="fixed w-full cursor-auto top-0 left-0 z-10 overflow-y-auto py-10"
            style={{backgroundColor:'rgba(10,11,12,0.7)', height:'100vh'}}  onClick={() => close()}>
            <div className="mx-auto pt-5 pb-2 bg-white border rounded-2xl shadow-lg w-4/5 md:w-full p-3 md:p-10 relative"
                style={{ maxWidth: 600 }}
                onClick={(e)=>{e.stopPropagation()}}>
                <span className="inline-block rounded-full w-8 h-8 flex items-center justify-center bg-white absolute -top-2 -right-2 cursor-pointer"
                    onClick={() => close()}>
                    <IconClose stroke="black" />
                </span>
                {props.children}
            </div>
        </div>
    ))
}

export default ModalDialog