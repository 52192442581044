import { useEffect, useState, useContext } from "react"
import profileIntegration from "../../../integrations/profile"
import { parseErrorMsg, COUNTRIES_MAP, STATES_MAP } from "../../../utils/misc"
import { phoneRegex } from "../../../utils/regexs";
import { IconLoader, IconPencilEdit } from "../../../Components/Icons";
import { Formik, Field, Form, ErrorMessage, setNestedObjectValues } from "formik"
import * as yup from 'yup'
import { PseudoLink, SecondaryButton } from "../../../Components/Button"
import _ from "lodash"
import GenderField, { castGenderToSelect } from "../../../Components/customInputs/GenderInput"
import PhoneInput from "../../../Components/customInputs/PhoneInput"
import Loader from "../partials/Loader"
import SuccesDialog from "./SuccesDialog"
import { ToastError } from "../../../Components/Toast"
import { Context, StoreActions } from '../../../store/useGlobalState'
import MyLocalStorage, { lsKeys } from "../../../utils/localStorage"
import ImageController from "../Components/ImageController"
import moment from "moment"
import { StatesUniversalApi } from "../../../utils/StatesUniversalApi"
import { Listbox } from "@headlessui/react"

const minimumAge = moment().startOf('year').subtract(18, "years")

const schema = yup.object().shape({
    username: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    name: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    lastname: yup.string().min(2, 'Mínimo dos caracteres').required("Campo requerido"),
    email: yup.string().email('Correo no válido').required("Campo requerido"),
    country_code: yup.string().required("Campo requerido"),
    phone: yup.string().matches(phoneRegex, 'el numero no es valido').required("Campo requerido"),
    birthday: yup.mixed().transform((value, originalValue) => new Date(originalValue)).required("Campo requerido"),
    gender: yup.string().required("Campo requerido"),
    address: yup.object().shape({
        street: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
        ext: yup.string().required("Campo requerido"),
        int: yup.string(),
        neighborhood: yup.string().optional(),
        zip_code: yup.string().min(4, "Mínimo cuatro caracteres").required("Campo requerido"),
        city: yup.string().required("Campo requerido"),
        state: yup.string().required("Campo requerido"),
        country: yup.string().oneOf(Object.values(COUNTRIES_MAP), 'no válido').required("Campo requerido")
    }),
})

const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

const UpperActions = ({ edit, verified, verify }) => (
    <div className="flex flex-row-reverse">
        <PseudoLink className="font-semibold text-sm" onClick={edit} >
            Editar
            <IconPencilEdit className="ml-2" />
        </PseudoLink>
        {!verified && <button
            className="py-2 px-2 mx-2 bg-primary rounded-md text-white text-sm"
            onClick={verify}>
                Verificar número
            </button>}
    </div>
)

// export default:
const UserData = () => {
    const { globalState, globalDispatch } = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState(null)
    const [formError, setFormError] = useState(null)
    const [editing, setEditing] = useState(false)
    const [succes, setSucces] = useState(false)
    const [birthdayCustomError, setBirthdayCustomError] = useState(false)
    const [countryStates, setCountryStates] = useState([])
    const [stateCities, setStateCities] = useState([])
    const [gettingCountries, setGettingCountries] = useState(false)
    const [gettingCities, setGettingCities] = useState(false)
    const [currentCountry, setCurrentCountry] = useState(undefined)
    const [currentState, setCurrentState] = useState('')
    const [currentCity, setCurrentCity] = useState('Otra')

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        setStateCities(['Otra'])
        setCountryStates([])
        setCurrentCity('Otra')

        try {
            const { data } = await profileIntegration.getInfo()
            const profile = curateData({
                ...data, ...(!(data.phone || data.coutry_code)) ? {
                    country_code: '', phone: ''
                } : {}
            })

            if (profile.address && profile.address.country) {
                const key = Object.keys(COUNTRIES_MAP).find((item) => {
                    return COUNTRIES_MAP[item] === profile.address.country
                })

                if (key) {
                    getStatesByCountry(key)
                    setCurrentCountry(key)
                }
            } if (profile.address && profile.address.city) {
                setCurrentCity(profile.address.city)
            } if (profile.address && profile.address.state) {
                setCurrentState(profile.address.state)
                getCitiesByState(profile.address.state, profile.address.city)
            }

            setFormData(profile)
            updateGlobalState({ ...profile, image: data.image })
            setLoading(false)
        } catch (error) {
            setFormError(parseErrorMsg(error))
            ToastError(parseErrorMsg(error))
        }

        setLoading(false)
    }

    const updateGlobalState = (userData) => {
        globalDispatch({ type: StoreActions.SET_USER, payload: userData })
        if (MyLocalStorage.read(lsKeys.sesion)) {
            MyLocalStorage.save(lsKeys.sesion, { ...globalState, user: userData })
        }
    }

    const isDate = (value) => {
        let temp = new Date(value);
        if (isNaN(temp.getTime()))
            return 'fecha no valida'
    }

    const validateBirthday = (birthday) => {
        const diff = moment(minimumAge).diff(birthday, "years")
        const valid = diff >= 0
        setBirthdayCustomError(!valid)
        return valid
    }

    const curateData = (profile) => ({
        ..._.pick(profile, ['username', 'name', 'lastname', 'email', 'phoneNumber', 'phone_verified', 'birthday', 'address', 'country_code', 'phone', 'credit']),
        address: _.pick(profile.address, ['street', 'ext', 'int', 'neighborhood', 'zip_code', 'city', 'state', 'country']),
        birthday: profile.birthday ? profile.birthday.substring(0, 10) : '',
        ...castGenderToSelect(profile.gender)
    })

    const getStatesByCountry = async (Country) => {
        if (gettingCountries) {
            return
        }

        setGettingCountries(true)
        setStateCities(['Otra'])
        setCurrentCity('Otra')

        try {
            const states = await StatesUniversalApi.getStates(Country)
            setCountryStates(states)
        } catch (err) {
            setCountryStates([])
        }

        setGettingCountries(false)
    }

    const getCitiesByState = async (State, currentSettedCity=undefined) => {
        if (gettingCities) {
            return
        }

        setGettingCities(true)

        try {
            const cities = await StatesUniversalApi.getCities(State)
            cities.splice(0, 0, 'Otra')
            setStateCities(cities)

            if (!cities.includes(currentCity)) setCurrentCity('Otra')
            if (currentSettedCity) {
                if (cities.includes(currentSettedCity)) setCurrentCity(currentSettedCity)
                else setCurrentCity('Otra')
            }
        } catch (err) {
            setStateCities([])
        }

        setGettingCities(false)
    }

    const onChangeCountry = (e) => {
        setCurrentCountry(e)
        if (e && COUNTRIES_MAP[e] !== undefined) getStatesByCountry(e)
    }

    const onChangeState = (e) => {
        setCurrentState(e)
        setCurrentCity('Otra')
        setStateCities(['Otra'])
        if (e) getCitiesByState(e)
    }

    const onChangeCity = (e) => {
        setCurrentCity(e)
        return
    }

    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
        if (validateBirthday(data.birthday)) {
            // data has been validated
            try {
                setFormError(null)
                const cleanedData = _.omit(data, ['genderSelect', 'phone_verified', "image", "credit"])
                const formData = { ...cleanedData, address: { ...cleanedData.address, zip_code: cleanedData.address.zip_code.toString() } }
                //formData.address.country = COUNTRIES_MAP[formData.address.country];
                const res = await profileIntegration.updateInfo(formData)
                globalDispatch({ type: StoreActions.SET_USER, payload: res.data })
                if (MyLocalStorage.read(lsKeys.sesion)) {
                    MyLocalStorage.save(lsKeys.sesion, { ...globalState, user: res.data })
                }
                setEditing(false)
                setSucces(true)
            } catch (error) {
                if (error.response?.status === 409) {
                    const response = error.response.data
                    if (response.fields?.email)
                        setFieldError('email', 'Este correo ya existe')
                    if (response.fields?.username)
                        setFieldError('username', 'Este nombre de usuario ya existe')
                }
                else if (error.response?.data?.error.match('duplicate key')) {
                    setFormError("Compruebe que el nombre de usuario o correo no esté siendo usado por otro usuario")
                }
                else setFormError(parseErrorMsg(error))
            }
            setSubmitting(false)
        }
    }

    return (
        <div className="relative p-6 border-2 border-secondary-lightest rounded-md m-2">
            <div className="text-lg font-semibold">Perfil de usuario</div>
            <div className="text-sm mt-3">Configura tu información personal</div>

            {/* Edit */}
            {!loading && !editing &&
                <UpperActions
                    edit={() => { setEditing(true) }}
                    verify={() => { setSucces(true) }}
                    verified={formData.phone_verified}
                />
            }

            <div className="md:px-32">
                {loading
                    ? <Loader />
                    : <>
                        <Formik
                            initialValues={formData}
                            validationSchema={schema}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            {({ isSubmitting, errors, touched, resetForm, dirty, values, setFieldValue, setTouched }) => (
                                <Form className="grid grid-cols-4 mt-10 mb-8 text-black gap-x-5 gap-y-10">
                                    { /* avatar */}
                                    <div className="col-span-4 grid grid-cols-2 flex items-center">
                                        <div className="col-span-2 md:col-span-1 flex justify-center">
                                            <ImageController profileData={formData} reLoadData={fetchData} editable={editing} />
                                        </div>
                                        <div className="col-span-2 md:col-span-1">
                                            <label
                                                className="block mb-1 text-xs"
                                                htmlFor="username">
                                                Nombre de usuario
                                            </label>
                                            <Field
                                                name="username"
                                                className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.username && touched.username && 'border-red-500'} h-10 focus:outline-none focus:border-secondary`}
                                                disabled={isSubmitting || !editing}
                                            />
                                            <ErrorMessage name="username" render={MyErrMsg} />
                                        </div>
                                    </div>
                                    { /* personal inputs */}
                                    <div className="col-span-4 font-semibold text-xl">Datos personales</div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="name">
                                            Nombre(s)
                                        </label>
                                        <Field
                                            name="name"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.name && touched.name ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="name" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="lastname">
                                            Apellido(s)
                                        </label>
                                        <Field
                                            name="lastname"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.lastname && touched.lastname ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="lastname" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="email">
                                            Correo
                                        </label>
                                        <Field
                                            name="email"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.email && touched.email ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                        />
                                        <ErrorMessage name="email" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="phone">
                                            Número telefónico
                                        </label>
                                        <PhoneInput disabled={isSubmitting || !editing} />
                                        <div style={{ color: "red" }}>
                                            {(errors.phone || (errors.phone && touched.phone))
                                                ? <ErrorMessage key="ph" name="phone" render={MyErrMsg} />
                                                : ((errors.country_code || (errors.country_code && touched.country_code)) ? <ErrorMessage key="cc" name="country_code" render={MyErrMsg} /> : '')
                                            }
                                        </div>

                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="birthday">
                                            Fecha de nacimiento
                                        </label>
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${(errors.birthday && touched.birthday) || birthdayCustomError ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            type='date'
                                            placeholder="yyyy-mm-dd"
                                            name="birthday"
                                            max={new Date().toISOString().substring(0, 10)}
                                            disabled={isSubmitting || !editing}
                                            validate={isDate}
                                        />
                                        <ErrorMessage name="birthday" render={MyErrMsg} />
                                        {
                                            birthdayCustomError && <span className="h-px text-xs text-red-500">Debes ser mayor de edad</span>
                                        }
                                    </div>
                                    <div className="col-span-4 md:col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="gender">
                                            Sexo
                                        </label>
                                        <GenderField disabled={isSubmitting || !editing} />
                                        <ErrorMessage name="gender" render={MyErrMsg} />
                                    </div>

                                    { /* address inputs */}
                                    <div className="col-span-4 font-semibold text-xl">Datos domiciliarios</div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.street">
                                            Avenida / Calle
                                        </label>
                                        <Field
                                            name="address.street"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.street && touched.address?.street ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="address.street" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.ext">
                                            Exterior
                                        </label>
                                        <Field
                                            name="address.ext"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.ext && touched.address?.ext ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="address.ext" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-1">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.int">
                                            Interior
                                        </label>
                                        <Field
                                            name="address.int"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.int && touched.address?.int ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="address.int" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.neighborhood">
                                            Colonia
                                        </label>
                                        <Field
                                            name="address.neighborhood"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.neighborhood && touched.address?.neighborhood ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="address.neighborhood" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.zip_code">
                                            Código Postal
                                        </label>
                                        <Field
                                            name="address.zip_code"
                                            type="number"
                                            className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.zip_code && touched.address?.zip_code ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            disabled={isSubmitting || !editing}
                                        />
                                        <ErrorMessage name="address.zip_code" render={MyErrMsg} />
                                    </div>

                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.country">
                                            País
                                        </label>
                                        <fieldset disabled={isSubmitting || !editing}>
                                            <Field name="address.country">
                                                {({ field, form: { touched, errors } }) => (
                                                    <input readOnly {...field} className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.country && touched.address?.country ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`} />
                                                )
                                                }
                                            </Field>
                                            <Listbox onChange={(e) => {
                                                setFieldValue('address.country', COUNTRIES_MAP[e], true)
                                                setFieldValue('address.state', '', true)
                                                setFieldValue('address.city', '', true)
                                                onChangeCountry(e)
                                            }}>
                                                <div className="relative mt-1" style={{
                                                    marginTop: '-40px'
                                                }}>
                                                    <Listbox.Button className={`${editing ? '' : 'bg-gray-200'} bg-gray-300 py-2`}
                                                        style={{
                                                            width: '100%',
                                                            opacity: '0'
                                                        }}>
                                                        <small className="text-secondary"><b>Seleccionar</b></small>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                        style={{zIndex:'10'}}>
                                                        {Object.keys(COUNTRIES_MAP).map((key, idx) => (
                                                            <Listbox.Option
                                                                key={idx}
                                                                className={({ active }) =>
                                                                    `${active ? 'bg-gray-200' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                                                }
                                                                value={key}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`${selected ? 'font-medium' : 'font-normal'
                                                                                } block truncate`}
                                                                        >
                                                                            {COUNTRIES_MAP[key]}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </fieldset>
                                        <ErrorMessage name="address.country" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4 md:col-span-2">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.state">
                                            Estado
                                        </label>
                                        <fieldset disabled={isSubmitting || !editing || gettingCountries}>
                                            <Field name="address.state" value={currentState}>
                                                {({ field, form: { touched, errors } }) => (
                                                    <input readOnly {...field} 
                                                    className={`${gettingCountries ? 'bg-gray-200' : ''} text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.state && touched.address?.state ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`} />
                                                )
                                                }
                                            </Field>
                                            <Listbox onChange={(e) => {
                                                setFieldValue('address.state', e, true)
                                                setFieldValue('address.city', '', true)
                                                onChangeState(e)
                                            }}>
                                                <div className="relative mt-1" style={{
                                                    marginTop: '-40px'
                                                }}>
                                                    <Listbox.Button className={`${editing ? 'bg-gray-200' : ''} py-2`}
                                                        style={{
                                                            width: '100%',
                                                            opacity: '0'
                                                        }}>
                                                        <small className="text-secondary"><b>Seleccionar</b></small>
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                        style={{zIndex:'10'}}>
                                                        {countryStates.map((state, idx) => (
                                                            <Listbox.Option
                                                                key={idx}
                                                                className={({ active }) =>
                                                                    `${active ? 'bg-gray-200' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                                                }
                                                                value={state}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`${selected ? 'font-medium' : 'font-normal'
                                                                                } block truncate`}
                                                                        >
                                                                            {state}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                        </fieldset>
                                        <ErrorMessage name="address.state" render={MyErrMsg} />
                                    </div>
                                    <div className="col-span-4">
                                        <label
                                            className="block mb-1 text-xs"
                                            htmlFor="address.city">
                                            Ciudad
                                        </label>
                                        <fieldset disabled={isSubmitting || !editing || gettingCities}
                                            className="grid grid-cols-1 md:grid-cols-2 gap-x-2">
                                            <Listbox onChange={(e) => {
                                                if(e!=='Otra') setFieldValue('address.city', e, true)
                                                else setFieldValue('address.city', '', true)
                                                onChangeCity(e)
                                            }}>
                                                <div className="w-full mt-1 inline-block relative">
                                                    <Listbox.Button className={`text-sm h-10 rounded-md text-left w-full px-3 py-2 border border-primary ${errors.address?.city && touched.address?.city ? 'border-red-500' : ''} 
                                                        ${gettingCities?'bg-gray-200':''}`} >
                                                        {currentCity}
                                                    </Listbox.Button>
                                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {stateCities.map((city, idx) => (
                                                            <Listbox.Option
                                                                key={idx}
                                                                className={({ active }) =>
                                                                    `${active ? 'bg-gray-200' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                                                }
                                                                value={city} >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span className={`${selected ? 'font-medium' : 'font-normal' } block truncate`} >
                                                                            {city}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </div>
                                            </Listbox>
                                            <Field
                                                name="address.city"
                                                className={`${currentCity==='Otra'?'':'hidden'} mt-1 text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.address?.city && touched.address?.city ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                                disabled={isSubmitting || !editing}
                                            />
                                        </fieldset>
                                        <ErrorMessage name="address.city" render={MyErrMsg} />
                                    </div>
                                    {/* actions */}
                                    {editing &&
                                        <div className="flex flex-col items-end w-full col-span-4 font-medium">
                                            <div className="flex flex-row">
                                                <button
                                                    className="px-5 py-3 mr-4 text-sm text-primary bg-gray-300 rounded-md"
                                                    onClick={() => {
                                                        resetForm()
                                                        fetchData()
                                                        setBirthdayCustomError(false)
                                                        setEditing(false)
                                                    }}
                                                    type="reset"
                                                    disabled={isSubmitting}
                                                >Cancelar</button>
                                                {isSubmitting
                                                    ? <button
                                                        type="button"
                                                        className="px-5 py-3 text-sm w-26 bg-primary rounded-md flex justify-center">
                                                        <IconLoader
                                                            className="text-white fill-current animate-spin"
                                                            style={{ height: 20 }}
                                                        />
                                                    </button>
                                                    : <button
                                                        className={`px-5 py-3 text-sm rounded-md text-white ${!dirty?'bg-primary-lightest':'bg-primary'}`}
                                                        disabled={!dirty}
                                                    >Guardar cambios</button>
                                                }
                                            </div>
                                            {formError &&
                                                <div className="flex justify-end w-full col-span-4">
                                                    <h2 className="text-red-500">{formError}</h2>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {succes && <SuccesDialog
                                        onClose={() => { setSucces(false); fetchData(); }}
                                        phone={{
                                            phone: values.phone,
                                            country_code: values.country_code
                                        }}
                                    />}
                                </Form>
                            )}
                        </Formik>
                    </>
                }
            </div>
        </div>
    )
}
export default UserData
