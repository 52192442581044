import { Link } from "react-router-dom"
import { mergeClassName } from "../../utils/misc";

const SecondaryLinkButton = ({ url = '', justify = "center", color="text-primary", ...props }) => {
    props = mergeClassName(`focus:outline-none flex items-center justify-${justify} ${color}`, props)
    return (
        <Link to={url} {...props}>
            {props.label ? props.label : props.children}
        </Link>
    )
}

export default SecondaryLinkButton