import { Link } from "react-router-dom";
import Themes_Adicciones from '../../Assets/Images/themes/adicciones.svg';
import Themes_Ansiedad from '../../Assets/Images/themes/ansiedad.svg';
import Themes_Autoconocimiento from '../../Assets/Images/themes/autoconocimiento.svg';
import Themes_ConflictosFamiliares from '../../Assets/Images/themes/conflictos-familiares.svg';
import Themes_Depresion from '../../Assets/Images/themes/depresion.svg';
import Themes_Duelo from '../../Assets/Images/themes/duelo.svg';
import Themes_Estres from '../../Assets/Images/themes/estres.svg';
import Themes_Insomnio from '../../Assets/Images/themes/insomnio.svg';
import Themes_MotivacionYAutoestima from '../../Assets/Images/themes/motivacion-y-autoestima.svg';
import Themes_TraumaYAbuso from '../../Assets/Images/themes/trauma-y-abuso.svg';

import Themes_Adicciones_selected from '../../Assets/Images/themes_selected/adicciones.svg';
import Themes_Ansiedad_selected from '../../Assets/Images/themes_selected/ansiedad.svg';
import Themes_Autoconocimiento_selected from '../../Assets/Images/themes_selected/autoconocimiento.svg';
import Themes_ConflictosFamiliares_selected from '../../Assets/Images/themes_selected/conflictos-familiares.svg';
import Themes_Depresion_selected from '../../Assets/Images/themes_selected/depresion.svg';
import Themes_Duelo_selected from '../../Assets/Images/themes_selected/duelo.svg';
import Themes_Estres_selected from '../../Assets/Images/themes_selected/estres.svg';
import Themes_Insomnio_selected from '../../Assets/Images/themes_selected/insomnio.svg';
import Themes_MotivacionYAutoestima_selected from '../../Assets/Images/themes_selected/motivacion-y-autoestima.svg';
import Themes_TraumaYAbuso_selected from '../../Assets/Images/themes_selected/trauma-y-abuso.svg';

export const ServicesThemesList = {
    "Estrés": {link:"estres", text:'Estrés', icon:Themes_Estres, selected_icon:Themes_Estres_selected},
    "Ansiedad": {link:"ansiedad", text:'Ansiedad', icon:Themes_Ansiedad, selected_icon:Themes_Ansiedad_selected},
    "Depresión": {link:"depresion", text:'Depresión', icon:Themes_Depresion, selected_icon:Themes_Depresion_selected},
    "Motivación y autoestima": {link:"motivacion", text:'Motivación y autoestima', icon:Themes_MotivacionYAutoestima, selected_icon:Themes_MotivacionYAutoestima_selected},
    "Conflictos familiares": {link:"conflictos-familiares", text:'Conflictos familiares', icon:Themes_ConflictosFamiliares, selected_icon:Themes_ConflictosFamiliares_selected},
    "Autoconocimiento": {link:"autoconocimiento", text:'Autoconocimiento', icon:Themes_Autoconocimiento, selected_icon:Themes_Autoconocimiento_selected},
    "Trauma y abuso": {link:"trauma-y-abuso", text:'Trauma y abuso', icon:Themes_TraumaYAbuso, selected_icon:Themes_TraumaYAbuso_selected},
    "Insomnio": {link:"insomnio", text:'Insomnio', icon:Themes_Insomnio, selected_icon:Themes_Insomnio_selected},
    "Duelo": {link:"duelo", text:'Duelo', icon:Themes_Duelo, selected_icon:Themes_Duelo_selected},
    "Adicciones": {link:"adicciones", text:'Adicciones', icon:Themes_Adicciones, selected_icon:Themes_Adicciones_selected}
};

const HoroscopeOfDay = () => {
    const styles = {
        background: "linear-gradient(235.33deg, rgba(255, 255, 255, 0.7) -14.65%, rgba(255, 255, 255, 0) 81.4%), #456DAC"
    }
    
    return (
        <div style={styles} className="mt-24 xl:-mx-fullScreen">
            <section className="flex flex-col w-full max-w-6xl gap-4 px-10 mx-auto text-white">
                <div className="flex flex-row text-3xl justify-center text-center nunito-font-bold mt-10">
                    Soluciones personalizadas para tus necesidades de paz mental
                </div>
                <div className="grid gap-y-14 mb-20 mt-11 grid-cols-2 xl:grid-cols-5">
                    {
                        Object.keys(ServicesThemesList).map((key, idx) => (
                            <Link to={`/servicios/${ServicesThemesList[key].link}`} key={`service-idx-${idx}`}
                                className="flex flex-col items-center">
                                <span className="flex items-center justify-center p-10 bg-white rounded-full border home-service-item border-primary">
                                    <img src={ServicesThemesList[key].icon} className="h-16 w-16" />
                                </span>
                                <div className="mt-4 text-center">{key}</div>
                            </Link>
                        ))
                    }
                </div>
            </section>
        </div>
    )
}

export default HoroscopeOfDay