import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const Testimonials = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Testimonios</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Testimonials} alt="Testimonials" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                Los clientes muchas veces confían en la retroalimentación y evaluación 
                de otros cuando toman decisiones de compra de un producto o servicio 
                en línea. La comunidad de psicólogos, terapeutas y coaches en Mi Paz 
                Mental no es diferente. La retroalimentación de los psicólogos, 
                terapeutas y coaches provee una perspectiva honesta de la experiencia 
                de un usuario en base al nivel de satisfacción, lo cual te puede ayudar 
                a encontrar el psicólogo, terapeuta o coach o psicólogos, terapeutas 
                o coaches adecuados para ti. Te darás cuenta de que las calificaciones 
                son en tiempo real, así que no es raro que puedas leer retroalimentación 
                de consultas que alguien tuvo hace pocos días.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Al ser una buena forma para los miembros de Mi Paz Mental de ayudarse 
                entre sí a encontrar y conectar con los mejores psicólogos, terapeutas 
                y coaches, te pedimos muy atentamente que compartas tus experiencias. 
                Por favor no incluyas detalles personales de tu consulta, como nombres, 
                direcciones, números de teléfono, o menciones a cualquier otro 
                psicólogo, terapeuta y coach que no sea el que te hizo la consulta. 
                Así como abiertamente solicitamos, agradecemos y publicamos tanto 
                retroalimentación positiva como negativa, también te pedimos que mantengas 
                los comentarios de forma profesional; no publicaremos retroalimentaciones 
                que contengan lenguaje o material inapropiado, o que sea difamatorio 
                e irrespetuoso.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Trata de ser lo más objetivo posible, sabemos que los miembros de Mi Paz 
                Mental lo valoran. Entre más retroalimentación y evaluaciones los 
                psicólogos, terapeutas y coaches tienen, más información tienes tu y 
                los demás miembros para ayudarte a decidir con cual psicólogo, terapeuta 
                o coach hablar.
              </p>
            </div>

          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} /> 
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default Testimonials