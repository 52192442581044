import React, { useState, useContext, useEffect } from "react"
import authIntegration from "../../integrations/auth"
import { IconEyeOff, IconArrowLeft, IconEye, IconLoader } from "../../Components/Icons"
import { PseudoLink, SecondaryButton } from "../../Components/Button"
import * as yup from 'yup'
import { Formik, Field, Form, ErrorMessage } from "formik"
import { parseErrorMsg } from "../../utils/misc";
import GenderField from "../../Components/customInputs/GenderInput"
import { Context, StoreActions } from '../../store/useGlobalState'
import history from "../History"
import moment from "moment";


const INITIAL_FORM_DATA = {
    name: '',
    lastname: '',
    username: '',
    email: '',
    password: '',
    birthday: '',
    gender: '',
    genderSelect: '',
    receive_emails: false
}

const minimumAge = moment().startOf('year').subtract(18, "years")

const schema = yup.object().shape({
    name: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    username: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    lastname: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    email: yup.string().email("Debe agregar un correo válido").required("Campo requerido"),
    password: yup.string().min(6, "Mínimo 6 caracteres").required("Campo requerido"),
    birthday: yup.mixed().transform((value, originalValue) => new Date(originalValue)).required("Campo requerido"),
    gender: yup.string().required("Campo requerido"),
    receive_emails: yup.boolean().required("Campo requerido"),
})

const MyErrMsg = msg => <span className="text-xs text-red-500 h-px">{msg}</span>


const PersonalData = ({ toPrevious, toNext, saveData, hidden }) => {

    const { globalState } = useContext(Context)

    const [visiblePass, setVisiblePass] = useState(false)
    const [formError, setFormError] = useState(null)
    const [birthdayCustomError, setBirthdayCustomError] = useState(false)

    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {

        if (validateBirthday(data.birthday)) {
            // data has been validated
            try {
                // throws error if data is duplicated 
                await authIntegration.isSignedUp(data)
                saveData(data)
                toNext()
            } catch (error) {
                console.log(parseErrorMsg(error));

                if (error.response?.status === 409) {
                    const response = error.response.data
                    if (response.fields?.email)
                        setFieldError('email', 'Este correo ya existe')
                    if (response.fields?.username)
                        setFieldError('username', 'Este nombre de usuario ya existe')
                }
                else setFormError(parseErrorMsg(error))
            }
            setSubmitting(false)
        }
    }

    const isDate = (value) => {
        let temp = new Date(value);
        if (isNaN(temp.getTime()))
            return 'fecha no valida'
    }

    const validateBirthday = (birthday) => {
        const diff = moment(minimumAge).diff(birthday, "years")
        const valid = diff >= 0
        setBirthdayCustomError(!valid)
        return valid
    }

    return <div className={`${hidden && 'hidden'} relative flex-1`}>
        <div className="text-xl mb-2 font-semibold">Datos personales</div>
        <div>Ingresa la información solicitada a continuación</div>

        <Formik
            initialValues={INITIAL_FORM_DATA}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors, touched }) => (
                <Form className="mt-10 grid grid-cols-2 gap-x-5 gap-y-10 text-black">
                    { /* inputs */}
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="name">
                            Nombre(s)
                        </label>
                        <Field name="name">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="name" render={MyErrMsg} />
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="lastname">
                            Apellido(s)
                        </label>
                        <Field name="lastname">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="lastname" render={MyErrMsg} />
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="username">
                            Nombre de usuario
                        </label>
                        <Field name="username">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="username" render={MyErrMsg} />
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="email">
                            Correo
                        </label>
                        <Field name="email">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="email" render={MyErrMsg} />
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="pasword">
                            Contraseña
                        </label>
                        <Field name="password">
                            {({ field, meta: { error, touched } }) => (
                                <div className="relative">
                                    <input
                                        {...field}
                                        className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                        type={visiblePass ? 'text' : 'password'}
                                        value={field.value}
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-warmGray-700">
                                        {visiblePass
                                            ? <IconEyeOff
                                                onClick={() => { setVisiblePass(false) }}
                                                style={{ height: 20 }}
                                            />
                                            : <IconEye
                                                onClick={() => { setVisiblePass(true) }}
                                                style={{ height: 20 }}
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                        </Field>
                        <ErrorMessage name="password" render={MyErrMsg} />
                    </div>
                    <div className="w-full">
                        <label
                            className="block text-xs mb-1"
                            htmlFor="birthday">
                            Fecha de nacimiento
                        </label>
                        <Field
                            className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${(errors.birthday && touched.birthday) || birthdayCustomError ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                            type='date'
                            placeholder="yyyy-mm-dd"
                            name="birthday"
                            max={new Date().toISOString().substring(0, 10)}
                            validate={isDate}
                        />
                        <ErrorMessage name="birthday" render={MyErrMsg} />
                        {
                            birthdayCustomError && <span className="h-px text-xs text-red-500">Debes ser mayor de edad</span>
                        }
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="gender">
                            Sexo
                        </label>
                        <GenderField />
                        <ErrorMessage name="gender" render={MyErrMsg} />
                    </div>
                    <div className="col-span-2 flex flex-row items-center mb-12">
                        <Field name="receive_emails" type="checkbox" className="ml-1 mr-4 focus:outline-secondary focus:border-secondary" style={{ height: "20px", width: "20px" }} />
                        <div className="flex flex-col justify-center">
                            <label
                                className="block text-xs"
                                htmlFor="gender">
                                Deseo obtener recompensas de membresía gratuitas.
                            </label>
                            <label
                                className="block text-xs text-warmGray-700"
                                htmlFor="gender">
                                Regístrarme para recibir noticias y promociones.
                            </label>
                        </div>
                    </div>

                    {/* actions */}
                    <div className="col-span-2 w-full" >
                        <div className="flex flex-row justify-between">
                            <PseudoLink className="text-xs font-bold" onClick={toPrevious}>

                            </PseudoLink>
                            {(isSubmitting)
                                ? <button
                                    type="button"
                                    className="w-26 px-5 py-3 text-sm rounded-md bg-primary text-white flex justify-center">
                                    <IconLoader
                                        className="text-white fill-current animate-spin"
                                        style={{ height: 20 }}
                                    />
                                </button>
                                : <button className="w-26 px-5 py-3 text-sm rounded-md bg-primary text-white" >
                                    Continuar
                                </button>
                            }
                        </div>
                        {formError &&
                            <div className="col-span-2 w-full flex justify-end">
                                <div className="text-red-500">{formError}</div>
                            </div>
                        }
                    </div>
                </Form>
            )}
        </Formik>
    </div>
}
export default PersonalData
