import axios from 'axios'

const article = {
    getArticles: async (limit=12, page=1, search, filter="", order = "") => {
        const response = await axios.get(`/articles/list?perPage=${limit}&desc=1&page=${page}${search ? `&search=${search}` : ''}&filterBy=${filter}`)
        return response?.data?.payload
    },
    pagination: async (url) => {
        const response = await axios.get(`/articles/list${url}`)
        return response
    },
    getById: async (id) => {
        const response = await axios.get(`/articles/show/${id}`)
        return response?.data?.payload
    },

}

export default article