import { Popover } from "@headlessui/react";
import payment from "../../integrations/payment";
import { IconDelete } from "../Icons";
import { ToastError, ToastSuccess } from "../Toast";
import { useRef, useState } from 'react'
const PromptDelete = ({ onDelete, id }) => {
   const [open, setOpen] = useState(false)
   const handleConfirm = () => {
      payment.removeMethod(id)
         .then((response) => {
            ToastSuccess('Se eliminó el método de pago.')
            if (typeof onDelete === 'function') {
               onDelete(id)
            }
         })
         .catch((re) => {
            ToastError('No se pudo eliminar el método de pago.')
         })
   }

   return (
      <Popover className="relative grid grid-cols-2">
         <span onClick={() => setOpen(true)} className={"px-2 h-5 focus:outline-none cursor-pointer"}>
            <IconDelete />
         </span>
         
         { open && <Popover.Panel static className="absolute z-10 outline-none right-0 -top-6 w-20">
            <div className="grid grid-cols-2 p-2 border-2 rounded-md outline-none bg-secondary-light">
               <span className="col-span-2 pb-2 text-xs">¿Eliminar?</span>
               <span className="col-span-1 font-bold text-red-800 cursor-pointer" onClick={handleConfirm}>Sí</span>
               <span className="col-span-1 cursor-pointer" onClick={() => { setOpen(false) }} >No</span>
            </div>
         </Popover.Panel>}
      </Popover>
   );
}

export default PromptDelete