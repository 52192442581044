import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'

export const PublicLayoutBox = ({ children }) => (<>
    <Navbar />
    <div className="px-4 pb-9">
        {children}
    </div>
    <Footer />
</>)

export default PublicLayoutBox