import React, { useContext } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import { EnvelopeBGIcon, PhoneBGIcon, IconLoader, ShieldCheck } from "../../Components/Icons"
import * as yup from 'yup'
import { SecondaryButton } from "../../Components/Button";
import contact from "../../integrations/contact"
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { Context } from '../../store/useGlobalState'
import ContactMessageImage from '../../Assets/Images/ContactMessageImage.jpg'
import assets from "../../Assets";

const schema = yup.object().shape({
    firstName: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    lastName: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    email: yup.string().email('Correo no válido').required("Campo requerido"),
    comments: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
})
const Contact = () => {
    const { globalState } = useContext(Context)
    const INITIAL_FORM_DATA = {
        firstName: '',
        lastName: '',
        email: '',
        comments: ""
    }
    const handleSubmit = async (values, actions) => {
        try {
            const res = await contact.send(values)
            console.log("res", res)
            actions.resetForm({ values: INITIAL_FORM_DATA })
            ToastSuccess('Solicitud enviada.')
        } catch (error) {
            ToastError('Se produjo un error, intente más tarde.')
        }
        actions.setSubmitting(false);
    }
    const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

    return <div className="w-full pt-10 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="col-span-1">
                <div className="hidden xl:block absolute left-64 md:z-0  md:top-32 rounded" 
                    style={{ width: 325, height: 151, backgroundColor: "#AEECE2", opacity:0.2 }} />
                <FloatingSqare width={14} height={14} left={38} top={10} color="#AEECE2" />
                <FloatingSqare width={18} height={18} left={37} top={13} color="#6E92C9" />
                <FloatingSqare width={14} height={14} left={34} top={14} color="#AEECE2" />
                <FloatingSqare width={12} height={12} left={36} top={18} color="#6E92C9" />
                <img src={ContactMessageImage} className="w-full md:left-0 md:z-0 md:max-w-xs lg:max-w-lg" />
                <div className="lg:ml-20 px-2">
                    <div className="text-2xl font-semibold mb-10 mt-8">Información de contacto </div>
                    <div className="flex flex-row items-center mb-8">
                        <PhoneBGIcon className="mr-5" style={{ width: 32, height: 31 }} />
                        <div>
                            <p className="mb-1 font-semibold">Teléfono</p>
                            <a href={`tel:${globalState.contactNumber}`}>{globalState.contactNumber}</a>
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <EnvelopeBGIcon className="mr-5" style={{ width: 32, height: 31 }} />
                        <div>
                            <p className="mb-1 font-semibold">Correo de servicio al cliente</p>
                            <a href={`mailto:${globalState.contactEmail}`}>{globalState.contactEmail}</a>
                        </div>
                    </div>
                    <div className="mt-4">
                        <img className="h-30" src={assets.SatisfactionGuaranteed} />
                    </div>
                </div>
            </div>
            <div className="col-span-1 p-2 lg:px-10">
                <div className="text-2xl font-semibold mb-5">Centro de servicio al cliente</div>
                <p>¿Tienes alguna queja o comentario para nosotros?</p>
                <p>Llena el siguiente formulario y nuestro equipo se pondrá en contacto contigo.</p>
                <div className="mt-10">
                    <Formik
                        initialValues={INITIAL_FORM_DATA}
                        onSubmit={(values, actions) => { handleSubmit(values, actions) }}
                        validationSchema={schema}
                    >
                        {({ isSubmitting, errors, touched, resetForm, dirty, values }) => (
                            <Form>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <div className="col-span-1">
                                        <label className="block mb-1 text-xs"
                                            htmlFor="firstName">Nombre(s)</label>
                                        <ErrorMessage name="firstName" render={MyErrMsg} />
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.firstName && touched.firstName ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="firstName"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <label className="block mb-1 text-xs"
                                            htmlFor="lastName">Apellido(s)</label>
                                        <ErrorMessage name="lastName" render={MyErrMsg} />
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.lastName && touched.lastName ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="lastName"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <label className="block mb-1 text-xs"
                                            htmlFor="email">Correo</label>
                                        <ErrorMessage name="email" render={MyErrMsg} />
                                        <Field
                                            className={`relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.email && touched.email ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="email"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                    <div className="col-span-1 md:col-span-2">
                                        <label className="block mb-1 text-xs"
                                            htmlFor="comments">Comentarios</label>
                                        <ErrorMessage name="comments" render={MyErrMsg} />
                                        <Field
                                            className={`h-30 relative text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.comments && touched.comments ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                            name="comments"
                                            as="textarea"
                                            disabled={isSubmitting}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row-reverse">
                                    {isSubmitting
                                        ? <button type="button"
                                            className="w-40 px-5 py-3 text-sm text-white flex justify-center bg-primary rounded-md mt-10">
                                            <IconLoader
                                                className="text-white fill-current animate-spin"
                                                style={{ height: 20 }}
                                            />
                                        </button>
                                        : <button className="w-40 px-5 py-3 text-sm text-white text-center bg-primary rounded-md mt-10"
                                        >Enviar comentario</button>
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>
}

const FloatingSqare = ({ width, height, left, top, color, ...props }) => {
    return <div className="rounded hidden xl:block absolute md:z-0" style={{
        width,
        height,
        left: `${left}em`,
        top: `${top}em`,
        backgroundColor: color
    }} {...props} />
}

export default Contact