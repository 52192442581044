import React, { useEffect, useContext } from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'

import { Context, StoreActions } from '../store/useGlobalState'
import configurations from '../integrations/configurations'

export const PublicLayout = ({ children }) => {
    const { globalState, globalDispatch } = useContext(Context)

    useEffect(() => {
        if (!(globalState.mainBanner && globalState.bannerTitle && globalState.bannerCaption
            && globalState.promotionalText)) fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const response = await configurations.generalConfigurations()
            globalDispatch({ type: StoreActions.SET_GENERAL_CONFIGURATION, payload: { ...response } })
        } catch (error) {
            console.log('[PublicLayout error]', error)
        }
    }

    return (
        <>
            <Navbar />
            <div className="mb-9">
                {children}
            </div>
            <Footer />
        </>
    )
}


export default PublicLayout