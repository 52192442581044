import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import RecomendedLectures from "../../../Components/RecomendedLectures"
import PseudoLinkButton from "../../../Components/Button/PseudoLinkButton"
import { IconArrowLeft } from "../../../Components/Icons"
import RecentVideos from "./RecentVideos"
import consultationMethods from "../../../integrations/consultation"
import Loader from "../../../Components/Loader"

const ConsultationIndex = () => {
    const { consultationId } = useParams()
    const [consultations, setConsultations] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (consultationId) {
            window.scrollTo(0, 0);
        }
    }, [consultationId])

    useEffect(async () => {
        try {
            const res = await consultationMethods.getConsultations(4, 1)
            setLoading(false)
            setConsultations(res?.data)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    const subTitle = "Descubre cómo son las consultas psicológicas, cómo trabajan los expertos, que puedes esperar de la consulta y muchas cosas más."
    return (
        <div className="w-full px-4 my-10">
            <section className="w-full bg-consultation bg-cover bg-center text-white rounded overflow-hidden relative xl:h-100"
                style={{minHeight:'360px'}}>
                <div className="absolute top-0 left-0 w-full h-full px-4 py-10" style={{zIndex: 2}}>
                    <div className="max-w-7xl mx-auto">
                        <div className="max-w-xl">
                            <div className="text-4xl font-bold mb-3 text-black">
                                Consultas, sesiones y terapia
                            </div>
                            <div className="text-black font-light lg:text-xl">
                                {subTitle}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute top-0 left-0 w-full h-full" style={{
                    background: 'linear-gradient(269.97deg, #FFFFFF 1.59%, rgba(255, 255, 255, 0) 99.97%)',
                    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
                    zIndex: 1
                }}></div>
            </section>
            <div className="text-2xl font-bold text-center lg:w-3/4 max-w-7xl mx-auto my-20">
                Somos profesionales apasionados y compasivos, impulsados ​​por la misión de ayudar a más
                personas a vivir una vida mejor y más feliz todos los días.
            </div>
            {
                loading ? <Loader /> : <Consultations source={consultations} />
            }
            <section className="grid grid-cols-1 md:grid-cols-5 xl:grid-cols-12 mt-10 max-w-7xl mx-auto">
                <div className="col-span-3 xl:col-span-8">
                    <RecentVideos />
                </div>
                <div className="col-span-2 xl:col-span-4 md:mt-20">
                    <RecomendedLectures />
                </div>
            </section>
        </div>
    )
}

const Consultations = ({ source }) => {
    return <section className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-10 max-w-7xl mx-auto">
        {
            source.map((x, i) => {
                return <ConsultationItem data={x} goToTerms={i === 3} key={`Consultations-${i}`} />
            })
        }
    </section>
}

const ConsultationItem = ({ data, goToTerms = false }) => {
    const link = goToTerms ? '/glosario' : (`/consultas/${data._id}`)
    return <Link to={link} className="mb-5 ml-0 md:ml-3 border-b border-gray-100">
        <div className="flex flex-col xl:flex-row items-start">
            <img src={data.image} className="rounded-md w-full h-72 md:w-40 md:h-30 lg:w-60 lg:h-40" />
            <div className="block w-full md:w-72 xl:ml-3 mt-2 lg:mt-0">
                <span className="text-black-400 text-lg"
                    style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{data.title}</span>
                <p className="text-black-400 text-sm mt-1 formatted-text-container line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: data.description }}></p>
            </div>
        </div>
        <PseudoLinkButton justify="justify-end" color="text-primary" className="font-semibold ml-auto mb-3">Leer más<IconArrowLeft
            color="stroke-black" className="ml-3 cursor-pointer transform -rotate-90 w-2" /></PseudoLinkButton>
    </Link>
}


export default ConsultationIndex