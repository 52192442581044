import { useEffect, useState } from "react"
import Dialog from "../../../Layouts/Dialog"
import { IconRoundCheck, IconLoader } from "../../../Components/Icons"
import { PseudoLink, SecondaryButton } from "../../../Components/Button"
import profile from "../../../integrations/profile"
import { parseErrorMsg } from "../../../utils/misc"

const Succes = ({closeDialog}) => {
    return <div className="flex flex-col z-10 max-w-2xl bg-white py-9 px-20 rounded-2xl">
        {/* title */}
        <div className="flex flex-col w-full text-lg text-center text-black">
            <div className="flex-col text-2xl font-semibold">Cambios guardados</div>
            <p className="flex-col pt-2 text-base whitespace-normal">Los datos de tu perfil de usuario se guardaron correctamente.</p>
        </div>
        
        {/* content */}
        <div className="flex flex-row justify-center mt-6 mb-12">
            <IconRoundCheck className="text-greenSuccess"/>
        </div>
        {/* actions */}
        <div className="flex flex-row justify-center">
            <button
                onClick={closeDialog}
                className="w-48 py-3 tracking-wider focus:outline-none bg-primary text-white rounded-md">
                    Cerrar
            </button>
        </div>
    </div>
}

const VerifyPhone = ({phone, setVerified, setLoading, loading}) => {
    const [formError, setFormError] = useState(null)
    const [code, setCode] = useState('')

    useEffect(() => {
        requestCode()
    }, [])

    const requestCode = async () => {
        try {
            setLoading(true)
            setFormError(null)
            const result = await profile.verifyRequestCode(phone)
        } catch (error) {
            setFormError("Error realizando la verificación. Compruebe su número de teléfono.")
        }
        setLoading(false)
    }

    const verifyCode = async () => {
        setFormError(null)

        if (!code) {
            setFormError("Debe agregar el código de verificación")
            setLoading(false)
            return
        }

        try {
            setLoading(true)
            await profile.verifyCode(code)
            setVerified(true)
        } catch (error) {
            setFormError("Error realizando la verificación. Compruebe su número de teléfono.")
        }
        setLoading(false)
    }

    return (<div className="flex flex-col items-center max-w-3xl bg-white py-9 px-20 rounded-2xl">
        {/* title */}
        <div className="flex flex-col w-full text-black">
            <div className="flex-col text-2xl font-semibold">Código de verificación</div>
            <p className="flex-col pt-2 text-base">
                Te hemos enviado un código de verificación al número teléfonico <span className="text-black">{phone.phone}</span>, para confirmar la autenticidad de tu cuenta.
            </p>
        </div>
        
        {/* content */}
        <div className="mt-12 text-sm">
            <label
                className="block mb-1"
                htmlFor="code">
                Código de verificación
            </label>
            <input
                className={`rounded-md w-80 p-4 h-10 border ${formError ? 'border-red-500' : 'border-primary'} focus:outline-none`}
                id="code"
                type="text"
                placeholder="00000"
                value={code}
                onChange={e => {setCode(e.target.value)}} 
            />
        </div>
        {formError && <div className="flex flex-row-reverse text-red-500 text-base">
            {formError}
        </div>}
        {loading && <IconLoader
            className="text-secondary animate-spin mt-2 -mb-4"
            style={{height: 20}}
        />}

        {/* actions */}
        <div className="flex flex-row justify-between text-sm w-full mt-14">
            <PseudoLink
                onClick={() => {setVerified(true)}}
                label="Omitir por ahora"
                className="px-4 py-3 focus:outline-none"
            />
            <div className="flex">
                <button
                    className="px-4 py-3 mr-3 focus:outline-none text-primary bg-gray-200 rounded-md"
                    onClick={requestCode}
                    disabled={loading}>Reenviar código</button>
                <button
                    onClick={verifyCode}
                    className="px-4 py-3 focus:outline-none bg-primary text-white rounded-md"
                    disabled={loading}>Confirmar</button>
            </div>
        </div>
    </div>)
}

const SuccesDialog = ({onClose, phone}) => {
    const [verified, setVerified] = useState(false)
    
    return (
        <Dialog
            onClose={onClose}
            darkOverlay
            dismisable={verified}
            render={(dialogProps) => (verified
                ? <Succes
                    {...dialogProps}
                />
                : <VerifyPhone
                    phone={phone}
                    setVerified={setVerified}
                    {...dialogProps}
                />
            )}
        />
    )
}
export default SuccesDialog