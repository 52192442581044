import { useHistory } from "react-router-dom";

const CardArticle = ({ id, photo, title, description, topic, main, secondary, total, index, author, date, profile }) => {
    const history = useHistory();

    const index2 = ([1, 5, 8, 11].includes(index)) && total === (index + 1)
    const index3 = ([2, 6, 9].includes(index)) && total === (index + 1)

    let classToFix = ''

    if (index2) {
        classToFix = 'lg:col-span-3 sm:col-span-2'
    } else if (index3) {
        classToFix = 'lg:col-span-2 sm:col-span-1'
    }

    return (
        <div className={`cursor-pointer grid grid-cols-1 sm:grid-cols-2 rounded-2xl content-start mb-4 md:mb-0 ${main ? 'lg:col-span-3 sm:col-span-2' : (secondary ? 'lg:col-span-3 sm:col-span-2' : '')} ${classToFix}`}
            style={{ minWidth: 235 }}
            key={id}
            onClick={() => {
                history.push(`/articulo/${id}`)
            }}>
            <div className={`${main ? 'col-span-1 h-80' : 'col-span-2 h-50'}`}>
                <img className={`w-full ${main ? 'h-80' : 'h-50'} object-cover rounded-md`} src={photo} title={title} alt={title} />
            </div>
            <div className={`text-sm mt-2 ${main ? 'col-span-1 px-0 sm:px-4' : 'col-span-2'}`}>
                <div className="text-left text-base text-primary"
                    style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{title}
                </div>
                <p className="text-left my-2">
                    {topic}
                </p>
                <p className={`text-left text-sm font-normal text-gray-500 formatted-text-container ${main ? 'line-clamp-3 sm:line-clamp-5' : 'line-clamp-3'} `}
                    dangerouslySetInnerHTML={{ __html: description }}>
                </p>
                <div className="flex flex-row items-center mt-2">
                    {profile}
                    <div>
                        <p className="text-left text-black ">
                            Por {author}
                        </p>
                        <p className="text-left text-gray-400 capitalize mt-2">
                            {date}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardArticle