import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import Loader from "../../Components/Loader"
import Subscribers from "../../integrations/subscriber"
import { ToastError } from "../../Components/Toast"
import { IconLoader } from "../../Components/Icons"

const Unsbuscribe = () => {
    const [loading, setLoading] = useState(true)
    const [userId, setUserId] = useState(null)
    const [unsubscribing, setUnsubscribing] = useState(false)
    const [unsubscribed, setUnsubscribed] = useState(false)
    const { search } = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(search)

        if (!params.has("id")) {
            setUserId(null)
            setLoading(false)
            return
        }

        setUserId(params.get("id"))
        setLoading(false)
    }, [])

    const deleteSubscriber = async () => {
        setUnsubscribing(true)

        try {
            const res = await Subscribers.deleteSubscriber(userId)
            setUnsubscribed(true)
        } catch (error) {
            ToastError("Ocurrió un error, por favor inténtelo de nuevo más tarde")
        }

        setUnsubscribing(false)
    }

    return (
        <div className="w-full max-w-md px-6 pt-3 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0 flex place-content-center">
            {
                loading ? <Loader /> : (
                    <div className="m-2 px-60 py-10">
                        {
                            userId ? (
                                <>
                                    {
                                        unsubscribed ? (
                                            <div className="font-semibold text-gray-500">
                                                ¡ Tu subscripción ha sido cancelada !
                                            </div>
                                        ) : (
                                            <div className="text-center">
                                                <div className="text-primary text-2xl font-semibold">
                                                    Cancelar subscripción de Mi Paz Mental
                                                </div>
                                                <div className="mt-4 text-gray-500">
                                                    Presiona el botón si quieres cancelar tu subscripción de
                                                    Mi Paz Mental
                                                </div>
                                                <div className="mt-3">
                                                    <button className="bg-secondary btn text-white font-semibold px-10 py-4 rounded"
                                                        onClick={deleteSubscriber}>
                                                        {
                                                            unsubscribing? (
                                                                <div className="flex items-center">
                                                                    <IconLoader className="animate-spin h-6" />
                                                                </div>
                                                            ): (
                                                                'Cancelar subscripción'
                                                            )
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <div>
                                    <h1 className="text-gray-500 font-semibold">
                                        Esta acción no se puede realizar
                                    </h1>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Unsbuscribe