import { Formik, Field, Form, ErrorMessage } from "formik"
import payment from "../../integrations/payment"
import { ToastError, ToastSuccess } from "../Toast"
import * as yup from 'yup'
import { IconChevronLeft, IconChevronRight, IconLoader } from "../Icons"

const schema = yup.object().shape({
   cardNumber: yup.string().required('Campo requerido').matches(/^[0-9]{16,16}$/, 'No válido: numérico de 16 caracteres'),
   cardHolder: yup.string().required('Campo requerido'),
   expMonth: yup.number().required('Campo requerido').min(1).max(12),
   expYear: yup.number().required('Campo requerido').min(2000).max(2030),
   cvc: yup.string().required('Campo requerido').matches(/^[0-9]{3,3}$/, 'No válido')
})

const MyErrMsg = msg => <span className="h-px text-xs text-red-500">{msg}</span>

const CardForm = ({ onSave, onCancel }) => {
   //#region Methods
   const resetFields = (resetForm) => {
      resetForm({
         values: INITIAL_FORM_DATA
      })
   }

   const handleCancel = (resetForm) => {
      resetFields(resetForm)
      if (typeof onCancel === 'function') {
         onCancel()
      }
   }

   const validate = ({ cardNumber, cardHolder, expMonth, expYear, cvc }) => {
      return cardNumber.length === 16 &&
         cardHolder.length > 0 &&
         (expMonth > 0 && expMonth <= 12) &&
         (expYear >= currentYear && expYear < currentYear + 7) &&
         cvc.length === 3
   };

   const handleSubmit = async (data, actions) => {
      actions.setSubmitting(true)

      let { cardNumber, expMonth, expYear, cvc, cardHolder } = data
      if (validate(data)) {
         try {
            const res = await payment.addMethod(cardNumber, expMonth, expYear, cvc, cardHolder)

            ToastSuccess('Se guardó el método de pago correctamente')
            actions.resetForm({
               values: INITIAL_FORM_DATA
            })
            if (typeof onSave === 'function') {
               onSave()
            }
         } catch (error) {
            ToastError('No se pudo guardar el método de pago, por favor verifica tu información.')
         }
      }

      actions.setSubmitting(false)
   }

   const INITIAL_FORM_DATA = {
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      cardHolder: '',
   }
   const monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

   const currentYear = new Date().getFullYear();

   let selectableYears = []

   for (let i = 0; i < 7; i++) {
      selectableYears.push(currentYear + i)
   }

   //#endregion
   return (<>
      <Formik
         initialValues={INITIAL_FORM_DATA}
         className="flex flex-col mx-auto mb-6 mt-14"
         onSubmit={handleSubmit}
         validationSchema={schema}
      >
         {({ resetForm, errors, touched, isSubmitting }) => {
            return (<Form>
               <div className="text-xl leading-loose font-semibold">Agregar método de pago</div>
               <p className="mt-6 text-sm">Ingresa la información solicitada a continuación</p>
               <div className="flex flex-col gap-y-4 mt-10">
                  <div>
                     <label
                        className="mb-1 text-sm"
                        htmlFor="cardNumber">
                        Número de tarjeta
                     </label>
                     <Field
                        className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.cardNumber && touched.cardNumber && 'border-red-500'} h-10 focus:outline-none focus:border-secondary`}
                        name="cardNumber"
                        maxLength={16}
                        placeholder=""
                     />
                     <ErrorMessage name="cardNumber" render={MyErrMsg} />
                  </div>

                  <div>
                     <label className="mb-1 text-sm">
                        Nombre en la tarjeta
                     </label>
                     <Field
                        className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.cardHolder && touched.cardHolder && 'border-red-500'} h-10 focus:outline-none focus:border-secondary`}
                        name="cardHolder"
                     />
                     <ErrorMessage name="cardHolder" render={MyErrMsg} />
                  </div>

                  <div className="grid grid-cols-2 gap-4 ">
                     <div>
                        <label className="mb-1 text-sm">
                           Fecha de expiración
                        </label>
                        <div className="grid grid-cols-2 gap-2">
                           <div>
                              <Field name="expMonth">
                                 {({ field }) => (
                                    <select
                                       {...field}
                                       className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.expMonth && touched.expMonth && 'border-red-500'} h-10 focus:outline-none focus:border-secondary`}
                                    >
                                       <option value='' />
                                       {monthsList.map(m => <option value={m} key={m}>{m}</option>)}
                                    </select>
                                 )}
                              </Field>
                              <ErrorMessage name="expMonth" render={MyErrMsg} />
                           </div>
                           <div>
                              <Field name="expYear">
                                 {({ field }) => (
                                    <select
                                       {...field}
                                       className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.expYear && touched.expYear && 'border-red-500'} h-10 focus:outline-none focus:border-secondary`}
                                       >
                                       <option value='' />
                                       {selectableYears.map(y => <option value={y} key={y}>{y}</option>)}
                                    </select>
                                 )}
                              </Field>
                              <ErrorMessage name="expYear" render={MyErrMsg} />
                           </div>
                        </div>
                     </div>

                     <div>
                        <label className="mb-1 text-sm">
                           CVV
                        </label>
                        <Field
                           className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${errors.cvc && touched.cvc && 'border-red-500'} h-10 focus:outline-none focus:border-secondary`}
                           name="cvc"
                        />
                        <ErrorMessage name="cvc" render={MyErrMsg} />
                     </div>
                  </div>
               </div>
               <div className="flex flex-row">
                  <div className="flex flex-row mt-10 ml-auto mr-0 gap-x-6 lg:mt-24">
                     <button onClick={() => { handleCancel(resetForm) }} className="px-4 rounded-md text-sm bg-gray-100 text-primary flex items-center justify-evenly">
                        <span>Cancelar</span>
                     </button>
                     <button type="submit" className="px-4 py-3 text-sm text-white rounded bg-primary"
                        disabled={isSubmitting}>
                           {
                              isSubmitting ? <IconLoader className="animate-spin h-4" />: 'Registrar'
                           }
                     </button>
                  </div>
               </div>
            </Form>
            )
         }}
      </Formik>
   </>
   )
}

export default CardForm