import { LinkButton} from "../../Components/Button"
import { Context } from "../../store/useGlobalState";
import Loader from './../../Components/Loader'
import { useContext, useState, useEffect } from 'react'


const HeroBgWithLeft = () => {
    const [headingData, setHeadingData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [textFont, setTextFont] = useState('inherit')
    const { globalState } = useContext(Context)

    useEffect(()=> {
        if (!(globalState.mainBanner && globalState.bannerTitle && globalState.bannerCaption
                && globalState.promotionalText)) return

        const { mainBanner, bannerTitle, bannerCaption, promotionalText } = globalState

        if (bannerTitle?.match(/^(.*)(font-family:)([^']+)(.*)$/)){
            setTextFont(bannerTitle?.replace(/^(.*)(font-family:)([^']+)(.*)$/, '$3'))
        }
        
        setHeadingData({
            mainBanner, bannerTitle, bannerCaption, promotionalText
        })
        
        setIsLoading(false)
    }, [globalState])

    return (<>
        {
            isLoading?<Loader />:<div
            className="pt-10 pb-7 lg:overflow-hidden xl:-mx-fullScreen"
            style={{
                background: `url('${headingData.mainBanner}') no-repeat center/cover`,
                backgroundSize: 'cover',
                backgroundPosition: 'center top'
            }}>
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="lg:grid lg:gap-8">
                    <div className="max-w-md px-4 sm:max-w-2xl sm:px-6 text-center lg:px-0 md:text-left lg:flex lg:items-center">
                        <div className="px-11 lg:py-24">
                            <h1 className="pt-8 text-5xl leading-normal tracking-wide text-white lg:pl-4">
                                <small className="block leading-normal text font-bold" dangerouslySetInnerHTML={{__html:headingData.bannerTitle}}></small>
                            </h1>
                            <h2 className="mt-4 leading-normal tracking-tight text-white lg:pr-20 lg:mt-6 lg:pl-4 lg:text-2xl sm:mt-5"
                                style={{fontWeight:'600'}}>
                                <span className="block" dangerouslySetInnerHTML={{__html:headingData.bannerCaption}}></span>
                            </h2>
                            <h3 className="lg:pl-4 mt-4 font-semibold tracking-normal text-white text-sm lg:text-base">
                                <span className="block" dangerouslySetInnerHTML={{__html:headingData.promotionalText}}></span>
                            </h3>
                            <div className="mt-3">
                                <div className="sm:flex">
                                    <div className="mt-3 sm:mt-0 lg:ml-3">
                                        <LinkButton label="Comienza a explorar" url="/expertos" color="text-primary" className="px-6 py-3 tracking-wider bg-secondary rounded-md"
                                            style={{fontFamily:textFont}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }</>
    )
}

export default HeroBgWithLeft