import React, { useState } from 'react'
import { Link } from "react-router-dom"


const NavItem = ({ mobile, label, url, children, open, className }) => {
    const [isOpen, setIsOpen] = useState(open)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    if (children) {
        return (
            <div className="relative flex flex-col mx-3" onMouseLeave={() => setIsOpen(false)}>
                <button onClick={toggleOpen}
                    onMouseEnter={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    type="button" className="inline-flex items-center text-sm font-medium bg-white rounded-md group" aria-expanded="false">
                    {url
                        ? <Link to={url} className="hover:text-primary">{label}</Link>
                        : <span className="hover:text-primary">{label}</span>
                    }
                </button>
                {isOpen ?
                    <div className="absolute z-10 max-w-md px-2 mt-5 -ml-4 transform w-max sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid pt-2 pb-2 bg-white sm:gap-4 sm:px-4">
                                {children}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )

    }

    // Elemento de menú para móviles
    if (mobile === true) {
        return (
            <Link to={url} className="flex items-center p-3 -m-3 rounded-md hover:text-primary">
                <span className={"ml-3 text-sm font-medium" + (className || '')}>{label}</span>
            </Link>
        )
    }

    // Tamaño normal
    return <Link to={url} className={"text-sm font-medium mx-3 hover:text-primary hover:border-primary border-white border-b-2"}
        style={{padding:'8px 0px', margin:'0px 0px -10px 0px'}}>{label}</Link>
}

export default NavItem