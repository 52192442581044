import { misticoConfigOptions } from "../../../../utils/expertConfigOptions"

const ExpertConfigOptions = ({ config, type, hideTitle = false, textColor = "text-gray-500", textSize="text-lg" }) => {
    const expertConfig = misticoConfigOptions[`${type}Options`]
    let title = ""
    let configContent = <div />
    if (!config || config.length === 0) {
        title = expertConfig.label
        configContent = <div className="text-left text-base font-semibold text-base md:pl-4"></div>
    }
    else if (type !== "themes") {
        title = expertConfig.label
        configContent = <ConfigItem items={config} type={expertConfig} textColor={textColor} textSize={textSize} />
    }
    else {

    }
    return <div className="flex items-center flex-wrap my-2">
        {!hideTitle && <div className="text-left pr-4 font-semibold -mt-2">{title}</div>}
        {configContent}
    </div>
}

const ConfigItem = ({ items, type, textColor, textSize }) => {
    if (!items) {
        return <div className={`text-left font-normal ${textColor} md:pr-4`}>N/A</div>
    }
    return items.map((item, i) => {
        if (item in type.options) {
            const mapItem = type.options[item]
            let itemText = ''
            if (typeof mapItem === 'string') {
                itemText = mapItem
            } else if (mapItem?.label) {
                itemText = mapItem?.label
            }

            return <div key={`config-item-${item}-key-${i}`}
                className={`text-left ${textSize} ${textColor} md:pr-4`}>{itemText}</div>
        }
    })
}



export default ExpertConfigOptions