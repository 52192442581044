import NavItem from "../NavItem"
import { CustomOfferItemRightRow } from './OfferItemRightRow'
import { Context } from "../../../store/useGlobalState"
import { useContext, useEffect, useState } from "react"
import Loader from "../../Loader"

const rightPanelIcons = ["phone-o", "videocall-o"]

const MenuConsultations = ({ mobile }) => {
    const { globalState } = useContext(Context)
    const [requestNav, setRequestNav] = useState([])
    const [loadingRequestNav, setLoadingRequestNav] = useState(true)

    useEffect(() => {
        if (globalState.requestsNav) {
            const req = globalState.requestsNav
            if (Array.isArray(req) && req.length >= 2 && Array.isArray(req[0].subrequests) &&
                Array.isArray(req[1].subrequests) && req[0].subrequests.length > 0 && req[1].subrequests.length > 0) {
                if (req[1].subrequests.length > 2) {
                    req[1].subrequests.splice(1, req[1].subrequests.length)
                }
                setRequestNav(req)
            }
        } else {
            setRequestNav([])
        }

        setLoadingRequestNav(false)
    }, [globalState])

    if (mobile === true) {
        return <NavItem mobile={true} url="/consultas" label="Consultas" />
    }

    return (
        <NavItem className="flex-grow" url="/consultas" label="Consultas">
            <section className="flex flex-row py-4 text-center">
                <aside className="flex flex-col w-9/12 text-left">
                    <div className="flex flex-col gap-y-2 text-md text-black">
                        {
                            loadingRequestNav ? <Loader /> : (
                                <>
                                    {
                                        requestNav.length > 1 ?
                                            <>
                                                {
                                                    requestNav[0].subrequests.map(item => (
                                                        <NavItem url={`/consultas/${requestNav[0]._id}/${item._id}`}
                                                            label={item.title} key={`consultaion-${item._id}`} />
                                                    ))
                                                }
                                                <NavItem url={`/consultas/${requestNav[0]._id}`} label="Más…." />
                                            </> : null
                                    }
                                </>
                            )
                        }
                    </div>
                </aside>
                <aside className="flex flex-col w-full border-l gap-y-6">
                    {
                        loadingRequestNav ? <Loader /> : (
                            <>
                                {
                                    requestNav.length>=2 ? 
                                    <>
                                        {
                                            requestNav[1].subrequests.map((item, idx) => (
                                                <CustomOfferItemRightRow title={item.title}
                                                    href={`/consultas/${requestNav[1]._id}/${item._id}`}
                                                    description={item.description}
                                                    icon={rightPanelIcons[idx]} />
                                            ))
                                        }
                                    </>: null
                                }
                            </>
                        )
                    }
                </aside>
            </section>
            <section className="flex flex-row pt-2 text-xs border-t">
                <NavItem url="/glosario" label="Glosario de términos" className="flex-col ml-auto mr-0 text-sm font-bold text-gray-500" />
            </section>
        </NavItem>
    )
}
export default MenuConsultations