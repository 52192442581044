import { useContext } from "react"
import { Context } from '../../store/useGlobalState'
import { UserProfileIcon } from "../Icons";


const UserAvatar = ({ className = "w-30 h-30", src = null, isUserClient = false }) => {
    const { globalState } = useContext(Context)
    const avatar = isUserClient ? src : globalState?.user?.image

    if (!avatar && !globalState?.user?.image) {
        return (
            <span className={className}>
                <UserProfileIcon className={`cursor-pointer ${className}`} style={{ maxWidth: 96 }} />
            </span>
        )
    }
    return <div className={`bg-no-repeat bg-cover bg-center rounded-full ${className}`} style={{ backgroundImage: `url(${avatar})` }} />
}

export default UserAvatar