import React, { useEffect, useState, useContext } from "react"
import { useParams, Link } from "react-router-dom"
import { IconThophy, IconReading, IconHeartFilled, IconHeartEmpty2 } from "../../../Components/Icons"
import { Calendar, Summary } from "../../../Components/Schedule"
import experts from "../../../integrations/expert"
import Recommended from "../../../Components/Recommended"
import { ToastWarn } from "../../../Components/Toast"
import moment from 'moment-timezone'
import ModalScheduleSuccess from "../../../Components/ExpertModal/ModalScheduleSuccess"
import ModalCall from "../../../Components/ExpertModal/ModalCall"
import ExpertConfigOptions from "./Components/ExpertConfiguration"
import Loader from "../../../Components/Loader"
import { Context } from '../../../store/useGlobalState'
import { ToastError } from "../../../Components/Toast"
import appointments from "../../../integrations/appointments"
import reviewsApi from "../../../integrations/reviews"
import { calculateExpertDisplayName } from "../../../utils/misc"
import Pagination from "../../../Components/Pagination";
import timezoneList from "../../../utils/timezone"
import ModalDialog from "../../../Components/ModalDialog"
import ModalLoginForm from "../ModalLoginForm"
import { UserProfileIcon } from '../../../Components/Icons'

const colors = {
    yellow: "#FFC700",
}

const perPage = 5

const timeFormat = "HH:mm"

const ExpertsPage = () => {
    const { globalState } = useContext(Context)
    const [isLoading, setIsLoading] = useState(true)
    const [openCall, setOpenCall] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(null)
    const [expert, setExpert] = useState(null)
    const [stars, setStarts] = useState([])
    const [selectDate, setSelectedDate] = useState(new Date())
    const [time, setTime] = useState("")
    const [timezone, setTimezone] = useState("")
    const [sessionType, setSessionType] = useState("")
    const [duration, setDuration] = useState(-1)
    const [reviews, setReviews] = useState([])
    const [pagination, setPagination] = useState({})
    const [reviewsIsLoading, setReviewsIsLoading] = useState(false)
    const [calculatingInfo, setCalculatingInfo] = useState(false)
    const [confirmData, setConfirmData] = useState({})
    const [timeToShow, setTimeToShow] = useState(null)
    const [saving, setSaving] = useState(false)

    let { id } = useParams();
    var EXPERT = null;

    useEffect(() => {
        loadData()
    }, [id])

    const loadData = async () => {
        setIsLoading(true)
        window.scrollTo(0, 0);
        try {
            const response = await experts.getById(id)

            setExpert(response)
            EXPERT = response
            await handlePagination()
            const starsIcon = new Array(typeof response?.rating === 'number' ? Math.ceil(response?.rating) : 0).fill('star')
            setStarts(starsIcon)
        } catch (error) {
            console.error("error: ", error)
            ToastError("Se produjo un error. Por favor inténtalo de nuevo más tarde")
        }
        setIsLoading(false)
    }

    const handlePagination = async (page = 1) => {
        if (!isLoading) {
            setReviewsIsLoading(true)
        }

        const resReviews = await reviewsApi.getByExpertId(EXPERT?._id || expert?._id, page, perPage)
        setReviews(resReviews)
        const pagesCount = resReviews?.totalPages
        let pages = []
        for (let i = 0; i < pagesCount; i++) {
            pages.push(i + 1)
        }
        setPagination({
            prev: page - 1,
            next: (page + 1) > pagesCount ? null : (page + 1),
            pages: pages,
            current: page
        })
        setReviewsIsLoading(false)
    }

    const handleConfirm = async () => {
        setSaving(true)

        const { user } = globalState
        if (time && timezone && sessionType && duration > 0) {
            try {
                const isAvailable = await experts?.checkAvailable2(expert?._id ? expert._id: EXPERT?._id)

                if (!isAvailable?.available) {
                    ToastError("El experto no está disponible en este momento")
                    setSaving(false)
                    return;
                }

                if (!isAvailable?.methods[sessionType]) {
                    ToastError("El experto desactivó el tipo de sesión seleccionado")
                    setSaving(false)
                    return;
                }
            } catch (error) {
                ToastError("El experto no está disponible en este momento")
                setSaving(false)
                return;
            }

            // get the time in local timezone format
            let expertTimeZone = timezoneList.find(x => x.value === expert?.schedule?.timezone)

            if (!(expertTimeZone && expertTimeZone.utc && expertTimeZone.utc[0])) {
                ToastError("Ocurrió un error obteniendo la zona horario del experto")
                return
            }

            expertTimeZone = expertTimeZone.utc[0]

            const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            if (!localTimezone) {
                ToastError("Ocurrió un error obteniendo la zona horaria local")
                return
            }

            const localMoment = moment.tz(selectDate, localTimezone)
            const localHourMoment = moment.tz(time, timeFormat, localTimezone)
            localMoment.set("hours", localHourMoment.hour())
                .set("minutes", localHourMoment.minute())
                .set("seconds", 0)
            const expertMoment = localMoment.tz(expertTimeZone)

            setTimeToShow(time)

            const formData = {
                "time": expertMoment.format(timeFormat),
                "timeZone": timezone,
                "date": expertMoment.format("YYYY/MM/DD"),
                "expert": expert._id,
                duration,
                sessionType
            }

            //console.log("expert time: ", time)
            //console.log("local time: ", expertMoment.tz(localTimezone).format(timeFormat))
            formData.timeZone = expertTimeZone
            //console.log("form data: ", formData)
            //return

            try {
                const response = await appointments.store(formData)
                if (!response?.error) {
                    setOpenConfirm(true)
                    // Se vuelve a setear el dia para que vuelva a renderizar los horarios disponibles
                    setSelectedDate(selectDate)
                    setConfirmData(response)
                } else {
                    //console.log("response: ", response)
                    if (response?.message && response.message === 'Schedule blocked') {
                        ToastError('El experto se encuentra en sesión. Por el momento ' +
                            'su agenda no está disponible, por favor inténtalo más tarde.')
                    } else {
                        ToastError("Por favor completa la información")
                    }
                }
            } catch (error) {
                ToastError("Por favor completa la información")
            }


        }
        else if (!user) {
            ToastError("Debe iniciar sesión para agendar")
        }
        else {
            ToastError("Por favor completa la información")
        }

        setSaving(false)
    }

    const comments = (user, image, type, rating, date, title, comment) => {
        const filledStars = new Array(typeof rating === 'number' ? Math.ceil(rating) : 0).fill('star');
        const unfilledStars = new Array(typeof (5 - rating) === 'number' ? Math.ceil(5 - rating) : 0).fill('star');

        return (
            <div className="flex flex-col w-full border-b-2 border-gray-200 pb-4">
                <div className="flex flex-row justify-between mt-4 w-full items-center">
                    <div className="inline-flex items-center">
                        <div>
                            {
                                image ? <img className="w-10 h-10 object-cover rounded-full mr-4" src={image} title={image} alt={image} /> :
                                    <div className="w-10 h-10 object-cover rounded-full mr-4"><UserProfileIcon /></div>
                            }
                        </div>
                        <div className="inline-flex flex-col">
                            <div className="text-left text-base font-semibold text-base text-black-400">{user}</div>
                            <div className="text-left mt-2 font-normal text-xs text-gray-500">{type}</div>
                        </div>
                    </div>
                    <div className="inline-flex flex-col">
                        <div className="flex items-center">
                            {filledStars.map((star, key) => <IconHeartFilled className="w-8 mr-1" />)}
                            {unfilledStars.map((star, key) => <IconHeartEmpty2 className="w-8 mr-1" />)}
                        </div>
                        <div className="text-left font-normal text-xs text-gray-500 capitalize mt-2 ml-2">{moment(date).format("MMMM DD, YYYY")}</div>
                    </div>
                </div>
                {/*<p className="text-left text-base font-normal font-semibold">{title}</p>*/}
                <p className="text-left text-sm mt-4"
                    style={{ wordWrap: 'break-word' }}>
                    {comment}
                </p>
            </div>
        )
    }

    const renderBody = () => {
        let yearsOfExperience = moment().diff(moment(expert?.start_date), 'year')
        let yearsOfExperienceLabel = "Años"
        if (yearsOfExperience === 0) {
            yearsOfExperience = moment().diff(moment(expert?.start_date), 'months')
            yearsOfExperienceLabel = "Meses"
        }
        return <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-6 ">
            <div className={`w-full pt-10 ${(globalState?.user && expert?.available && expert?.accept_appointments) ? 'col-span-4' : 'col-span-6'}`}>
                <div className="rounded-md border-gray-100 border-2 w-full px-5 py-10 md:px-10 md:py-10">
                    <h3 className="text-left text-base font-semibold text-xl text-black-400 mb-4">Acerca de mí</h3>
                    <p className="text-left text-sm mb-4">
                        {expert?.about_me}
                    </p>

                    <ExpertConfigOptions config={expert?.themes?.specialities || []} type="specialities" textColor='text-black' textSize="text-sm" />
                    <ExpertConfigOptions config={expert?.preferences || []} type="preferences" textColor='text-black' textSize="text-sm" />

                    <div className="md:flex md:flex-row justify-evenly md:px-10 mt-10">
                        <div className="flex flex-col items-center">
                            <div className="h-12 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                                <IconThophy className="h-6 w-6 cursor-pointer" />
                            </div>
                            <div className="text-left text-base font-semibold text-xl">{yearsOfExperience}</div>
                            <h3 className="text-left text-sm font-normal text-normal mb-4">{yearsOfExperienceLabel} de experiencia</h3>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="h-12 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                                <IconReading className="h-6 w-6 cursor-pointer" />
                            </div>
                            <div className="text-left text-base font-semibold text-xl text-black-400">{expert?.totalSessions}</div>
                            <h3 className="text-left text-sm font-normal text-normal mb-4">Lecturas realizadas</h3>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="h-12 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                                <IconHeartFilled className="h-6 w-6 cursor-pointer" />
                            </div>
                            <div className="text-left text-base font-semibold text-xl text-black-400">{reviews?.totalCount}</div>
                            <h3 className="text-left text-sm font-normal text-normal mb-4">Reviews de clientes</h3>
                        </div>

                    </div>
                    <div className="text-left text-base text-xl font-semibold text-md text-black-400 mb-4 mt-4">Recomendaciones</div>
                    <p className="text-left text-sm font-normal">
                        {expert?.recommendations}
                    </p>
                    <div className="text-left text-xl text-base font-semibold text-md text-black-400  mt-8">Reseñas y ratings ({reviews?.totalCount} reseñas)</div>
                    {reviewsIsLoading ? <Loader /> : <>
                        {reviews?.data.map(x => {
                            return comments(x.user.username, x.user.image, "Videollamada", x.rating, moment(x.createdAt).toDate(), x.title, x.commentary)
                        })}
                        <Pagination handlePagination={handlePagination} pagination={pagination} />
                    </>}
                </div>
            </div>
            <div className=" col-span-2 w-full pt-10 md:px-10">
                {
                    (globalState?.user && expert?.available && expert?.accept_appointments) &&
                    <>
                        <div className="text-left text-xl font-semibold pb-6">Disponibilidad de horario</div>
                        <Calendar
                            expert={expert}
                            selectDate={selectDate}
                            setSelectedDate={setSelectedDate}
                            idExpert={expert._id}
                        />
                        <div className="mt-10" />
                        <Summary
                            expert={expert}
                            selectDate={selectDate}
                            setTime={setTime}
                            setTimezone={setTimezone}
                            timezone={timezone}
                            time={time}
                            saving={saving}
                            duration={duration}
                            sessionType={sessionType}
                            setSessionType={setSessionType}
                            setDuration={setDuration}
                            onConfirm={handleConfirm}
                            calculatingInfo={calculatingInfo}
                            setCalculatingInfo={setCalculatingInfo}
                        />
                    </>
                }
            </div>
        </div>
    }

    const filledStars = stars
    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <div className="bg-white max-w-6xl mx-auto">
                <div className="px-4 flex items-center my-10">
                    <Link to='/expertos' className="text-primary">Expertos</Link>
                    <p className="mx-2 text-primary">&gt;</p>
                    <span className="font-semibold text-primary" style={{ marginTop: '-5px' }}>
                        {calculateExpertDisplayName(expert)}
                    </span>
                </div>
                <div className="px-4">
                    <div className="w-full min-h-80 rounded-md md:p-12 py-5 bg-secondary-light lg:flex lg:justify-between">
                        <div className="pb-2 md:pb-0 lg:flex-grow lg:flex lg:justify-between lg:gap-x-4">
                            <div className="flex justify-center lg:block">
                                {
                                    expert?.image ? <img className="w-40 h-40 object-cover rounded-2xl" src={expert?.image} title={expert?.name} alt={expert?.name} /> :
                                        <div className="w-40 h-40 xl:w-80 xl:h-80 object-cover rounded-2xl flex justify-center lg:justify-start">
                                            <UserProfileIcon width="180" height="180" />
                                        </div>
                                }
                            </div>
                            <div>
                                <div className="lg:flex lg:justify-between lg:items-end lg:gap-x-3">
                                    <div className="text-2xl text-black-400 font-semibold text-center lg:text-left">
                                        {calculateExpertDisplayName(expert)}
                                    </div>
                                    <div className="flex flex-row gap-x-0.5 justify-center lg:justify-end items-center mt-2 lg:mt-0">
                                        <p className="text-md font-semibold mr-3">{expert?.rating?.toFixed(1)}</p>
                                        {filledStars.map((star, key) => <IconHeartFilled className="flex-col inline-block w-8 ml-1" />)}
                                        {(new Array(5-filledStars?.length).fill(0).map((star, key) => (
                                            <span className="text-primary w-8 ml-1">
                                                <IconHeartEmpty2 />
                                            </span>
                                        )))}
                                    </div>
                                </div>
                                <p className="text-center lg:text-left text-xs font-semibold text-black-400 my-5 lg:my-0 lg:mt-10">
                                    {expert?.slogan}
                                </p>
                            </div>
                        </div>
                        <div className="px-2 lg:px-0 md:w-80 lg:w-50 m-auto lg:mx-3">
                            <div className="rounded-md flex flex-col p-8 gap-y-3 bg-white">
                                <div className="text-center text-base text-xl text-primary">${Number(expert?.price_videocall).toFixed(2)} MXN/min</div>
                                <button
                                    onClick={async () => {
                                        const available = await experts.checkAvailable(expert?._id)
                                        if (available) {
                                            if (!globalState?.user) {
                                                setSessionType('video_call')
                                                setOpenCall(true)
                                                return
                                            }

                                            setSessionType('video_call');
                                            setOpenCall(true)
                                        }
                                        else {
                                            ToastWarn("El experto no está disponible por el momento")
                                        }
                                    }}
                                    className="text-white bg-primary focus:outline-none flex items-center justify-center rounded-md 
                                mr-3 mx-2 px-7 py-3.5 text-xs tracking-wider whitespace-nowrap"
                                >
                                    Videollamada
                                </button>
                                <div className="text-center text-base text-xl text-primary">${Number(expert?.price_phonecall).toFixed(2)} MXN/min</div>
                                <button
                                    onClick={async () => {
                                        const available = await experts.checkAvailable(expert?._id)
                                        if (available) {
                                            if (!globalState?.user) {
                                                setSessionType('internet_call')
                                                setOpenCall(true)
                                                return
                                            }

                                            setSessionType('internet_call');
                                            setOpenCall(true)
                                        }
                                        else {
                                            ToastWarn("El experto no está disponible por el momento")
                                        }
                                    }}
                                    className="text-white bg-primary focus:outline-none flex items-center justify-center rounded-md 
                                mr-3 mx-2 px-7 py-3.5 text-xs tracking-wider whitespace-nowrap"
                                >
                                    Llamada
                                </button>
                            </div>
                        </div>
                    </div>
                    {renderBody()}
                </div>
                <Recommended />
                <ModalScheduleSuccess
                    id={id}
                    expert={expert}
                    date={selectDate}
                    time={timeToShow}
                    visible={openConfirm}
                    confirmation={confirmData}
                    hideSelf={() => { setOpenConfirm(false); loadData(); }}
                />
                <>
                    {
                        globalState.user ? <ModalCall
                            id={id}
                            expert={expert}
                            visible={openCall}
                            serviceType={sessionType}
                            hideSelf={() => { setOpenCall(false) }}
                        /> :
                            <ModalDialog visible={openCall} close={() => { setOpenCall(false) }}>
                                <ModalLoginForm handleLoggedIn={() => { }} />
                            </ModalDialog>
                    }
                </>

            </div>

        </>
    )
}

export default ExpertsPage