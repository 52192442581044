import { useHistory } from 'react-router-dom'
import articles from "../../integrations/article"
import Loader from "../../Components/Loader"
import { useEffect, useState } from "react"
import UserAvatar from "../../Components/UserAvatar"
import moment from "moment"
moment.locale('es');

const dateFormat = "MMMM DD, YYYY"

const categories = {
    'psychology': 'Psicología',
    'therapy': 'Terapia',
    'health': 'Salud y bienestar',
    'love': 'Amor',
    'life': 'Vida',
    'depress': 'Depresión',
    'anxiety': 'Ansiedad',
    'tips': 'Tips'
}

const Articles = () => {
    const [isLoading, setLoading] = useState(true)
    const [lastArticles, setlastArticles] = useState([])
    const history = useHistory()

    useEffect(async () => {
        try {
            const res = await articles.getArticles(4, 1)

            if (res.data) {
                res.data.forEach(element => {
                    if (element && element.name) {
                        try {
                            const str = element.name.trim();
                            element.name = str.charAt(0).toLocaleUpperCase() + str.slice(1)
                        } catch (error) { }
                    }

                    if (element && element.content) {
                        if (element.content.match(/(>)([^<]+)(<\/)/)) {
                            let nContent = ''
                            element.content.replace(/(>)([^<]+)(<\/)/g, (match, p1, p2, p3) => {
                                nContent += p2 + ' '
                            })
                            element.content = nContent
                        }
                    }
                });
            }

            setlastArticles(res?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setlastArticles([])
            setLoading(false)
        }
    }, [])

    return (
        <section className="flex flex-col w-full px-4 gap-4 max-w-7xl mx-auto mt-20 text-center lg:text-left">
            <div className="flex-row mt-6 ml-2 text-4xl font-semibold">Artículos</div>
            {
                isLoading ? <Loader /> : <div className="grid items-stretch grid-cols-3 gap-4 xl:gap-x-6">
                    {
                        lastArticles.map((x, i) => {
                            return <ArticleItem
                                isBig={i === 3 || lastArticles?.length===1}
                                heading={x.name}
                                image={x.photo}
                                category={categories[x.category]}
                                text={x.content}
                                author={x.postedBy.name}
                                date={moment(x.createdAt).format(dateFormat)}
                                profile={<UserAvatar src={x.postedBy.image} isUserClient={true} className="w-10 h-10" />}
                                onClick={() => history.push(`/articulo/${x._id}`)}
                            />
                        })
                    }
                </div>
            }
        </section>
    )
}

// #region Subcomponents
const ArticleItem = ({ heading, image, category, text, author, date, profile, isBig, onClick }) => (
    <article className={`flex flex-col justify-between h-full gap-2 py-4 cursor-pointer transition-shadow hover:shadow-md col-span-3 md:col-span-1 ${isBig ? 'md:col-span-3' : ''}`} onClick={onClick}>
        <div>
            <div className="flex-row"><img src={image} className={`w-full mx-auto h-50 object-cover rounded ${isBig ? 'h-80' : ''}`} alt={heading} /></div>
            <div className="flex-row mt-2 text-2xl font-semibold text-primary px-2"
                style={{ wordBreak: 'break-word', hyphens: 'auto' }}>{heading}</div>
            <div className="flex-row flex-shrink col-span-1 px-2">{category}</div>
            <div className="flex-row mt-3 leading-normal text-sm formatted-text-container line-clamp-3 px-2"
                dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
        <div className="flex items-center">
            <div className="flex items-center">
                <div className="inline-grid flex-col col-span-1 mr-4">
                    <span className="grid items-center h-12 col-span-1 py-0 text-xs text-white align-middle rounded-full">
                        {profile}
                    </span>
                </div>
            </div>
            <div className="flex-col flex-grow col-span-11 text-sm text-left">
                <span>Por {author}</span>
                <p className="mt-1 text-gray-400 capitalize">{date}</p>
            </div>
        </div>
    </article>
)
// #endregion

export default Articles