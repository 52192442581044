import axios from 'axios'

const services = {
    getByLabel: async (label) => {
        const response = await axios.get(`/services/show?label=${label}`)
        return response?.data?.payload
    }

}

export default services