import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const EthicCode = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6  pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Código de ética</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.EthicCode} alt="EthicCode" />
          <div className="block mt-5">
            <div className="mb-5">
              <div className="block mb-5 font-semibold">Código de ética de la comunidad de expertos de Mi Paz Mental</div>
              <ul className="pl-4">
                <li className="list-disc">
                  Nuestros expertos aspiran a ser abiertos, honestos y asertivos en sus consultas. Mi Paz Mental no premia a los expertos por alargar las consultas más allá del tiempo que sea del mejor interés para el cliente o de la forma que dicte el contexto de la consulta.
                </li>
                <li className="list-disc">
                  Los expertos evitan imponer sus valores personales, actitudes, creencias y comportamientos, compartiendo estos solo como una forma de apoyo para que el cliente entienda el mensaje de la consulta psicológica.
                </li>
                <li className="list-disc">
                  Los clientes tienen la libertad de elegir si desean conectar solo una vez o regularmente con los expertos a través de nuestro servicio, y de terminar la conversación en cualquier momento. La cantidad de tiempo y dinero que un cliente gasta con un experto es solo decisión del cliente.
                </li>
                <li className="list-disc">
                  Nuestros expertos entienden que la confianza es la base de la relación de servicio, y trabajan para ganarse la confianza de nuestros clientes a través de la honestidad, profesionalismo y confidencialidad. Mi Paz Mental no compartirá, bajo su conocimiento, el contenido de las interacciones entre los clientes y expertos.  Al hacer uso de nuestros servicios en línea o por teléfono tu estás dando tu consentimiento de que sean grabados los contenidos de estas comunicaciones de forma privada para fines de calidad y protección.
                </li>
                <li className="list-disc">
                  Mi Paz Mental comprende la gran responsabilidad que llevan los expertos. Es responsabilidad de nuestros expertos respetar la dignidad y promover el bienestar de nuestros clientes. Les pedimos a nuestros expertos que traten a los clientes con el mayor respeto, compasión y profesionalismo, lo cual incluye estar completamente enfocados en ti y tus necesidades.
                </li>
                <li className="list-disc">
                  Para asegurar la seguridad y protección, todos los servicios son a través de Mi Paz Mental.
                </li>
                <li className="list-disc">
                  Por la seguridad y confidencialidad del servicio y esta comunidad, nuestros expertos usan seudónimos para representarse. Mi Paz Mental nunca autorizará que un experto psicológico comparta su información personal o de negocios con un cliente por ninguna razón, tienen prohibido hacerlo.
                </li>
                <li className="list-disc">
                  Para su protección, está prohibido que un experto le pida a un cliente información personal de cualquier tipo para identificarlo, por ninguna razón.
                </li>
                <li className="list-disc">
                  Para proteger la naturaleza única del servicio, Mi Paz Mental no proporciona a nuestros expertos ninguna forma o herramienta para compartir información del cliente.
                </li>
                <li className="list-disc">
                  Está prohibido para todos los expertos pedir directamente a los clientes dinero u ofrecer cualquier negocio externo. Las relaciones románticas o personales con los clientes fuera del contexto de los servicios están prohibidas.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <img src={assets.SatisfactionGuaranteed} className="h-40" />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default EthicCode