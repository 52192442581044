import { PortalLogo } from "../../Components/Icons"

const MaintenancePage = () => {
   return (
      <section className="grid items-center content-center w-screen h-screen">
         <PortalLogo className="mx-auto max-w-max" />
         <p className="mt-8 text-4xl text-center text-pink-500">Comming soon</p>
      </section>
   )
}

export default MaintenancePage