import { default as React, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PseudoLink, SecondaryButton } from "../../../../Components/Button"
import authIntegration from "../../../../integrations/auth"
import { Context, StoreActions } from '../../../../store/useGlobalState'
import { parseErrorMsg } from "../../../../utils/misc"
import MyLocalStorage, { lsKeys } from "../../../../utils/localStorage"
import { IconEye, IconEyeOff, IconFacebook, IconLoader } from "../../../Icons"
import { setAxiosToken } from "../../../../utils/axiosConfig"
import ReactFacebookLogin from 'react-facebook-login'
import { ToastError } from "../../../../Components/Toast"

export const Login = ({ forgotPass, onLoggedIn, redirectUrl = '/' }) => {
    let [visiblePass, setvisiblePass] = useState(false)
    let [userName, setUserName] = useState("")
    let [passWord, setPassWord] = useState("")
    let [keepSignedIn, setKeepSignedIn] = useState(true)
    let [logingIn, setLogingIn] = useState(false)
    let [loginError, setLoginError] = useState(null)
    const [iconIndex, setIconIdex] = useState(0)
    const { globalDispatch } = useContext(Context)
    const history = useHistory()
    
    const facebookIcons = [
        <IconFacebook className="mr-4 text-white fill-current" style={{ height: 20 }} />,
        <IconLoader className="text-white fill-current animate-spin" style={{ height: 20 }} />
    ]

    const loginUser = async () => {
        setLogingIn(true)
        setLoginError(null)
        try {
            if (!userName?.trim() || !passWord?.trim())
                throw new Error('Usuario y contraseña son requeridos')
            const response = await authIntegration.login(userName, passWord, keepSignedIn)
            setAxiosToken(response.token)
            if (keepSignedIn)
                MyLocalStorage.save(lsKeys.sesion, response)

            globalDispatch({ type: StoreActions.LOGIN, payload: response })

            if (onLoggedIn && "function" === typeof onLoggedIn) onLoggedIn()

            history.push(redirectUrl)
        } catch (err) {
            let errorMsg = parseErrorMsg(err)
            console.log('error at login:', errorMsg)
            if (err.response?.status === 401)
                setLoginError('Usuario o contraseña incorrecta')
            else
                setLoginError(errorMsg)
        }
        setLogingIn(false)
    }

    const responseFacebook = async (response) => {
        const { id, name } = response

        if (!(id && name)) {
            ToastError("Ocurrió un error realizando la autenticación, por favor intente más tarde")
            setIconIdex(0)
            return
        }

        try {
            const response = await authIntegration.login(id, id, keepSignedIn)
            
            setAxiosToken(response.token)

            if (keepSignedIn)
                MyLocalStorage.save(lsKeys.sesion, response)

            globalDispatch({ type: StoreActions.LOGIN, payload: response })

            if (onLoggedIn && "function" === typeof onLoggedIn) onLoggedIn()

            history.push(redirectUrl)
        } catch (err) {
            setLoginError("Error de autenticación, por favor intenta registrarte antes de iniciar sesión")
        }

        setIconIdex(0)
    }

    return (
        <div>
            {/* title */}
            <div className="text-center">
                {loginError &&
                    <div className="text-red-500" style={{whiteSpace:'normal'}}>
                        {loginError}
                    </div>
                }
                <h3 className="font-semibold text-xl text-black">Inicia sesión</h3>
            </div>

            {/* content */}
            <div className="my-3 text-black">
                <div className="flex justify-start">
                    <label
                        className="block mb-1 text-xs"
                        htmlFor="userName">
                        Usuario o correo
                    </label>
                </div>
                <input
                    className={`text-sm rounded-md w-full px-3 py-2 h-10 ${loginError ? 'border-red-500 border-2' : 'border-primary border'} focus:outline-none`}
                    id="userName"
                    type="text"
                    value={userName}
                    onChange={e => { setUserName(e.target.value) }}
                />
            </div>

            <div className="mt-5 text-black">
                <div className="flex justify-start">
                    <label
                        className="block mb-1 text-xs"
                        htmlFor="passWord">
                        Contraseña
                    </label>
                </div>
                <div className="relative">
                    <input
                        className={`text-sm rounded-md w-full px-3 py-2 h-10 ${loginError ? 'border-red-500 border-2' : 'border-primary border'} focus:outline-none`}
                        id="passWord"
                        type={visiblePass ? 'text' : 'password'}
                        value={passWord}
                        onChange={e => { setPassWord(e.target.value) }}
                    />

                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                        {visiblePass
                            ? <IconEyeOff
                                onClick={() => { setvisiblePass(false) }}
                                className="stroke-current text-primary"
                                style={{ height: 20 }}
                            />
                            : <IconEye
                                onClick={() => { setvisiblePass(true) }}
                                className="stroke-current text-primary"
                                style={{ height: 20 }}
                            />
                        }
                    </div>
                </div>
                <div className="flex justify-end mt-2">
                    <PseudoLink
                        label="¿Olvidaste tu contraseña?"
                        className="ml-auto"
                        onClick={forgotPass}
                    />
                </div>
            </div>
            <div className="flex items-center mt-6 mb-6">
                <input
                    id="keepSignedIn"
                    type="checkbox"
                    style={{ height: "20px", width: "20px" }}
                    defaultChecked={keepSignedIn}
                    onChange={e => { setKeepSignedIn(e.target.checked) }}
                />
                <label
                    className="ml-4 text-black"
                    htmlFor="keepSignedIn">
                    Mantener mi sesión activa
                </label>
            </div>

            {/* actions */}
            {logingIn
                ? <button className="w-full px-6 py-2.5 tracking-wider focus:outline-none flex items-center justify-center font-semibold rounded-md bg-primary-light2 text-white">
                    <IconLoader
                        className="text-white fill-current animate-spin"
                        style={{ height: 20 }}
                    />
                </button>
                : <button className="text-sm md:text-normal w-full px-6 py-2.5 tracking-wider focus:outline-none flex items-center justify-center font-semibold rounded-md bg-primary-light2 text-white"
                    onClick={loginUser}>
                    Iniciar sesión
                </button>
            }
            <div className="flex flex-row justify-center my-4">
                <span className="mx-2 text-black">ó</span>
            </div>
            <ReactFacebookLogin
                appId={process.env.REACT_APP_FB_APP_ID}
                autoLoad={false}
                fields="id,name,first_name,last_name,email,picture"
                scope="public_profile"
                callback={responseFacebook}
                icon={facebookIcons[iconIndex]}
                onClick={()=>{setIconIdex(1)}}
                isDisabled={iconIndex==1}
                textButton="Iniciar sesión con Facebook"
                cssClass="text-sm md:text-normal flex items-center justify-center w-full px-6 py-2.5 font-semibold tracking-wider text-white rounded-md bg-primary-dark focus:outline-none"
                />

        </div>
    )
}

export default Login
