import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { PseudoLink } from "../../Components/Button"
//Components
import CardArticle from "../../Components/CardArticle"
import { Formik,  Form } from "formik"
import { IconChevronLeft, IconChevronRight, IconClose, IconSearch } from "../../Components/Icons"
import article from "../../integrations/article"
import Loader from "../../Components/Loader"
import UserAvatar from "../../Components/UserAvatar"
import moment from "moment";
moment.locale('es');

const categories = {
    'psychology': 'Psicología',
    'therapy': 'Terapia',
    'health': 'Salud y bienestar',
    'love': 'Amor',
    'life': 'Vida',
    'depress': 'Depresión',
    'anxiety': 'Ansiedad',
    'tips': 'Tips'
}

const categoriesTexts = {
    'psychology': 'psicologia',
    'therapy': 'terapia',
    'health': 'Salud-y-bienestar',
    'love': 'amor',
    'life': 'vida',
    'depress': 'depresion',
    'anxiety': 'ansiedad',
    'tips': 'tips'
}

const dateFormat = "MMMM DD, YYYY"
const ArticlesPage = () => {
    const [searchText, setSearchText] = useState('')
    const [temporarySearchText, setTemporarySearchText] = useState('')
    const [order, setOrder] = useState('')
    const [pagination, setPagination] = useState({prev:false,next:false,pages:[],current:1})
    const [loading, setLoading] = useState(true)
    const [experts, setExpertsData] = useState([])

    let { id, finalArticleCategory } = useParams()
    let articleCategory = null;
    let history = useHistory();

    if (finalArticleCategory) {
        articleCategory = Object.keys(categoriesTexts).find(item => categoriesTexts[item] === finalArticleCategory)
    } else {
        articleCategory = ''
    }

    useEffect(() => {
        filtersSearch()
    }, [finalArticleCategory, pagination.current, searchText])

    const setExperts = (data) => {
        data.forEach((element) => {
            if (element && element.content && element.content.match(/(>)([^<]+)(<\/)/)) {
                let nContent = ''
                element.content.replace(/(>)([^<]+)(<\/)/g, (match, p1, p2, p3) => {
                    nContent += p2+' '
                })
                element.content = nContent
            }
        })
        setExpertsData(data)
    }

    const getPagination = (experts, page) => {
        const pagesCount = experts.totalPages
        const currentPage = experts.currentPage?experts.currentPage:1
        let pages = []

        for (let i = 0; i < pagesCount; i++) {
            pages.push(i + 1)
        }
        
        setPagination({
            prev: currentPage>1,
            next: currentPage<pagesCount,
            pages: pages,
            current: currentPage
        })
    }

    const filtersSearch = async () => {
        setLoading(true)
        try {
            const experts = await article.getArticles(12, pagination.current, searchText, articleCategory)
            setExperts(experts?.data)
            getPagination(experts)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }

    }

    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
    }

    const handlePagination = async (page, next) => {
        if (page===pagination.current) return

        setPagination(prev => ({
            ...prev,
            current: page
        }))
    }

    const handleSearch = async () => {
        filtersSearch()
    }

    const onChangeSearch = ({ target }) => {
        const newValue = target?.value
        if (temporarySearchText && !newValue) {
            setTemporarySearchText('')
            setSearchText('')
        } else {
            setTemporarySearchText(target?.value)
        }
    }

    return (
        <>
            <div className="bg-white pt-10">
                <div className="max-w-md px-6 mx-auto text-center lg:max-w-7xl sm:max-w-3xl sm:px-0">
                    <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Artículos
                    </div>
                    <p className="max-w-prose mt-3.5 mx-auto text-lg tracking-normal pb-3">
                        Los mejores artículos de nuestros expertos
                    </p>
                    <div>
                        <div>
                            <Formik
                                initialValues={{}}
                                onSubmit={handleSubmit}
                            >
                                <Form name="searchBar">

                                    <div className="relative flex-row flex  align-center justify-center mb-10">
                                        <div className="relative" style={{ width: "100%", maxWidth: "25rem" }}>
                                            <input
                                                value={temporarySearchText}
                                                onInput={onChangeSearch}
                                                style={{ width: "100%", maxWidth: "25rem" }}
                                                placeholder="Ingresa un título o tema de interés"
                                                className={`text-sm rounded-md  px-3 pl-2 pr-5 border border-gray-100 h-10 focus:outline-none focus:border-secondary rounded-r-none`}
                                            />
                                            {searchText && <div className="absolute right-1 top-3"
                                                onClick={() => {
                                                    setTemporarySearchText('')
                                                    setSearchText('');
                                                }}>
                                                <IconClose
                                                    stroke="rgba(113, 113, 122,0.5)" />
                                            </div>
                                            }
                                        </div>
                                        <PseudoLink onClick={()=>{setSearchText(temporarySearchText)}} className="px-3 py-2 bg-primary border-primary rounded rounded-l-none">
                                            <IconSearch className="h-5 " />
                                        </PseudoLink>
                                    </div>
                                </Form>
                            </Formik>
                        </div>

                    </div>
                    <div className="flex flex-row justify-between md:justify-end align-center self-end">
                        <label
                            className="block text-sm m-auto mx-0"
                            htmlFor="sort">
                            Filtrar por:
                        </label>
                        <select
                            onChange={e => {
                                history.push(`/articulos/${e.target.value}`);
                            }}
                            className={`h-9 bg-white lg:mx-2 rounded-md text-sm w-auto pl-2 text-primary`}
                        >
                            <option value={""} key={"Todos"} selected={finalArticleCategory==undefined}>{"Todos"}</option>
                            {
                                Object.keys(categories).map(item => 
                                    <option value={categoriesTexts[item]} key={item} selected={articleCategory==item}>
                                        {categories[item]}</option>)
                            }
                        </select>
                    </div>
                    {
                        loading ? <div className="mt-4">
                            <Loader />
                        </div> : <>
                            <div className="mt-4" >
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 md:gap-8 xl:gap-x-14 xl:gap-y-10">
                                    {/*MAIN*/}
                                    {experts.map((expert, index) => (
                                        <CardArticle
                                            main={index == 0}
                                            secondary={index == 4}
                                            total={experts.length}
                                            index={index}
                                            id={expert._id}
                                            expert={expert}
                                            available={expert.available}
                                            photo={expert.photo}
                                            topic={categories[expert.category]}
                                            description={expert.content}
                                            title={expert.name}
                                            key={`article-key-${expert._id + index}`}
                                            author={expert.postedBy.name}
                                            date={moment(expert.updatedAt).format(dateFormat)}
                                            profile={<UserAvatar src={expert.postedBy.image} isUserClient={true} className="w-10 h-10 mr-2" />}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="flex text-gray-700 justify-end self-end mt-10">
                                {pagination?.prev && <div onClick={() => handlePagination(pagination.current-1, false)}
                                    className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
                                    <IconChevronLeft className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />

                                </div>}
                                <div className="flex flex-row justify-center items-center ">
                                    {(pagination?.pages?.length > 1) && pagination?.pages?.map(page => {
                                        return <div
                                            className="m-1 text-gray-500 cursor-pointer font-semibold mx-4"
                                            style={{ color: pagination?.current == page ? "#505DD5" : "rgba(113, 113, 122, var(--tw-text-opacity))" }}
                                            onClick={() => {
                                                handlePagination(page, false)
                                            }
                                            }
                                        >
                                            {page}
                                        </div>
                                    })}
                                </div>
                                {pagination?.next && <div onClick={() => handlePagination(pagination.current+1, true)}
                                    className="h-12 w-12 mr-1 flex justify-center items-center cursor-pointer">
                                    <IconChevronRight className="h-5 text-white ml-2 " style={{ width: 8, height: 12 }} />
                                </div>}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default ArticlesPage