const DevicesPromt = ({camera, mic, onSuccess, onError}) => {
    const promtWindow = async () => {
        try {
            let constraints = {}

            if (camera) {
                constraints.video = true
            } if (mic) {
                constraints.audio = true
            }

            const res = await navigator.mediaDevices.getUserMedia(constraints)

            if (onSuccess && typeof onSuccess === "function") {
                localStorage.setItem('_prdgm_devices_enabled', '1')
                onSuccess(res)
            }
        } catch (error) {
            if (onError && typeof onError === "function") {
                onError(error)
            }
        }
    }

    const allowClicked = () => {
        promtWindow()
    }

    return (
        <div style={{
            position:'fixed',
            top:'0px',
            left: '0px',
            overflow: 'hidden',
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(10,11,12,0.8)',
            paddingTop: '10vh'
        }}>
            <h2 className="text-white text-center text-3xl">
                Presiona <span className="underline cursor-pointer" onClick={allowClicked}>permitir</span> para que 
                Mi Paz Mental pueda usar la cámara y micrófono de este computador
                para realizar llamadas y videollamadas a través del navegador
            </h2>
        </div>
    )
}

export default DevicesPromt