import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Loader from "../Loader";
import articles from "../../integrations/article"

const categoriesClassMap = {
    'psychology': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'therapy': 'py-1 px-2 rounded text-sm bg-secondary text-primary',
    'health': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'love': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'life': 'py-1 px-2 rounded text-sm bg-secondary text-primary',
    'depress': 'py-1 px-2 rounded text-sm bg-primary text-white',
    'anxiety': 'py-1 px-2 rounded text-sm bg-secondary text-primary',
    'tips': 'py-1 px-2 rounded text-sm bg-primary text-white'
}

const categories = {
    'psychology': 'Psicología',
    'therapy': 'Terapia',
    'health': 'Salud y bienestar',
    'love': 'Amor',
    'life': 'Vida',
    'depress': 'Depresión',
    'anxiety': 'Ansiedad',
    'tips': 'Tips'
}

const RecomendedLectures = ({ template = "vertical", ...props }) => {
    const [lastArticles, setlastArticles] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        try {
            const res = await articles.getArticles(4, 1)

            if (res.data) {
                res.data.forEach(element => {
                    if (element && element.name) {
                        try {
                            const str = element.name.trim();
                            element.name = str.charAt(0).toLocaleUpperCase() + str.slice(1)
                        } catch (error) { }
                    }
                });
            }

            setlastArticles(res?.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setlastArticles([])
            setLoading(false)
        }
    }, [])

    if (loading) {
        return <Loader />
    }
    return <>
        {
            template === "vertical" ? <Vertical src={lastArticles} /> : <Horizontal src={lastArticles} />
        }
    </>
}

const Vertical = ({ src }) => {
    return <>
        <div className=" font-semibold text-xl text-black-400 mb-3 mt-6 md:mt-0 mb-5">Lecturas recomendadas</div>
        {
            src.map((x, i) => {
                return (
                    <Link to={`/articulo/${x._id}`} key={`recomended-lectures-vertical-${i}`} className="grid grid-cols-1 md:grid-cols-3 mb-5 items-center">
                        <div className="col-span-1">
                            <img src={x.photo} className="rounded-md w-full md:w-30 md:h-20 mb-2 md:mb-0" />
                        </div>
                        <div className="col-span-1 md:col-span-2 ml-3">
                            <div className="text-gray-500 flex flex-wrap items-center">
                                <span className={categoriesClassMap[x.category]}>
                                    <small>
                                        {categories[x.category]}
                                    </small>
                                </span>
                            </div>
                            <div>
                                <small className="text-primary">{x.postedBy.name}</small>
                            </div>
                            <p className="text-black-400 text-sm mt-1"
                                style={{ wordBreak: 'break-word', hyphens: 'auto' }}
                                dangerouslySetInnerHTML={{ __html: x.name }}></p>
                        </div>
                    </Link>
                )
            })
        }
    </>
}

const Horizontal = ({ src }) => {
    return <div className="lg:overflow-hidden py-10 bg-secondary-light px-3">
        <div className="mb-9">
            <div className="max-w-7xl mx-auto">
                <div>
                    <h3 className="text-base font-semibold text-2xl text-primary px-3">
                        Nuestras lecturas recomendadas
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {
                            src.map((x, i) => {
                                return <Link to={`/articulo/${x._id}`} key={`recomended-lectures-vertical-${i}`} className="ml-0 md:ml-3">
                                    <div className="col-span-1 md:col-span-2">
                                        <div className="flex flex-col justify-between" style={{ height: '240px' }}>
                                            <div>
                                                <p className="text-black-400 text-xl font-semibold mt-1"
                                                    style={{ wordBreak: 'break-word', hyphens: 'auto' }}
                                                    dangerouslySetInnerHTML={{ __html: x.name }}>
                                                </p>

                                            </div>
                                            <div>
                                                <span className="text-md flex flex-wrap items-center my-2 truncate">
                                                    Por {x.postedBy.name}
                                                </span>
                                                <img src={x.photo} className="rounded w-full h-40 mb-2 md:mb-0" />
                                            </div>

                                        </div>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default RecomendedLectures