import { Link } from "react-router-dom"
import { ServicesThemesList } from "../Home/HoroscopeOfDay"

const SitemapComponent = () => {
    return (
        <div className="p-4 max-w-7xl mx-auto">
            <div className="text-xl font-semibold">Mapa del sitio</div>
            <div className="mt-10 grid grid-cols-3">
                <div>
                    <div>
                        <div>Inicio</div>
                        <div className="flex flex-col">
                            <Link to="" className="text-blue-500">Página inicial</Link>
                            <Link to="/registrarse" className="text-blue-500">Regístrate</Link>
                            <Link to="/peticion-de-expertos" className="text-blue-500">Solicitudes de expertos</Link>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>Páginas</div>
                        <div className="flex flex-col">
                            <Link to="/articulos" className="text-blue-500">Artículos</Link>
                            <Link to="/consultas" className="text-blue-500">Consultas</Link>
                            <Link to="/expertos" className="text-blue-500">Expertos</Link>
                            <Link to="/ofertas" className="text-blue-500">Ofertas</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>Servicios</div>
                        <div className="flex flex-col">
                            <Link to="/servicios" className="text-blue-500">Ver todos</Link>
                            {
                                Object.keys(ServicesThemesList).map((key, idx) => (
                                    <Link key={`service-${idx}`} 
                                    to={`/servicios/${ServicesThemesList[key]['link']}`} 
                                    className="text-blue-500">{key}</Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>Otros</div>
                        <div className="flex flex-col">
                            <Link to="/como-funciona" className="text-blue-500">¿Cómo funciona?</Link>
                            <Link to="/codigo-de-etica" className="text-blue-500">Código de ética</Link>
                            <Link to="/contacto" className="text-blue-500">Contacto</Link>
                            <Link to="/manifiesto" className="text-blue-500">Manifiesto</Link>
                            <Link to="/politicas-de-privacidad" className="text-blue-500">Políticas de privacidad</Link>
                            <Link to="/precios" className="text-blue-500">Precios</Link>
                            <Link to="/preguntas-frecuentes" className="text-blue-500">Preguntas frecuentes</Link>
                            <Link to="/recomendaciones-tecnicas" className="text-blue-500">Recomendaciones técnicas</Link>
                            <Link to="/servicio-al-cliente" className="text-blue-500">Servicio al cliente</Link>
                            <Link to="/satisfaccion-garantizada" className="text-blue-500">Satisfacción garantizada</Link>
                            <Link to="/terminos-y-condiciones" className="text-blue-500">Términos y condiciones</Link>
                            <Link to="/testimonios" className="text-blue-500">Testimonios</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitemapComponent