import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconCalendar, IconLockClosedWithKey, IconMessage, IconMoney, IconParty, IconUser, IconUserCheck } from "../../Components/Icons";
import { Heading, Sub } from "../../Components/Typography";
import SlipitCard from "../../Layouts/SlipitCard";
import offers from "../../integrations/offers";
import Loader from "../../Components/Loader"

const OffersPage = () => {
    const [offersList, setOffersList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(async () => {
        const res = await offers.getOffers()
        setOffersList(res?.data)
        setIsLoading(false)
    }, [])

    return (
        <section className="pt-10 max-w-md px-6 mx-auto lg:max-w-5xl sm:max-w-3xl sm:px-0">
            <div className="text-3xl nunito-font-bold text-center mb-10 mt-1">Nuestras ofertas exclusivas</div>
            <SlipitCard>
                <div className="flex flex-col">
                    <div className="mb-2 text-xl font-semibold">Oferta de lanzamiento desde $5.00 MXN el minuto.</div>
                    <span className="text-sm">Disfruta de nuestra oferta de lanzamiento, única para clientes nuevos.</span>
                    <span className="my-4 text-xs text-gray-400">Selecciona una de nuestras ofertas</span>
                    {
                        isLoading ? <Loader /> : <section className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                            {
                                offersList && offersList.map((x, i) => (
                                    <OfferCard selected={!x.extraInfo}
                                        offer={x}
                                        tooltip={x.extraInfo}
                                        icon={x.extraInfo ? <IconParty className="mx-auto w-7" /> : <IconMoney className="mx-auto w-7" />}
                                        title={`$${x.price.toFixed(2)} MXN`}
                                        caption={x.info}
                                        key={`offers-key-${i}`}
                                    />
                                ))
                            }
                        </section>
                    }
                    <section className="mt-12">
                        <div className="mb-6 font-semibold tracking-wider text-center text-xl">Te mereces ser feliz</div>
                        <div className="flex flex-col px-8 py-4 border rounded border-primary gap-y-4">
                            <ServiceFeature icon={<IconUserCheck className="h-6" />} text="Los mejores psicólogos, terapeutas y coaches" />
                            <ServiceFeature icon={<IconLockClosedWithKey className="h-6" />} text="Protegemos tu privacidad" />
                            <ServiceFeature icon={<IconCalendar className="h-6" />} text="Horarios accesibles" />
                        </div>
                    </section>
                </div>
            </SlipitCard>
        </section>
    )
}

//#region Subcomponents
const OfferCard = ({ title, caption, icon, tooltip, selected, offer }) => {
    return (
        <Link className="col-span-1 relative mt-2 border-2 rounded-lg border-primary text-white offers-item" to={{
            pathname: "/registrarse",
            state: {
                offer: offer
            }
        }} >
            <div className="text-xs text-right -mt-3" style={{marginRight:'-2px'}}>
                {!tooltip ? null :
                    <span className="inline-block px-4 py-1" 
                        style={{borderRadius:'3px', backgroundColor:'#456CAC'}}>
                        {tooltip}
                    </span>
                }
            </div>
            <div className="flex flex-col px-4 py-8 text-center bg-white rounded-lg">
                <span className="my-5 mx-auto">
                    <img src={offer.icon} className="rounded-full w-20 h-20 object-cover" />
                </span>
                <div className="flex-row mx-auto text-xl font-bold mb-4 text-primary">{title}</div>
                <span className="flex-row text-xs mx-auto text-black">{caption}</span>
            </div>
        </Link>
    )
}

const ServiceFeature = ({ icon, text }) => (
    <article className="flex flex-row items-center">
        <span className="flex flex-row items-center justify-left flex-shrink w-6 h-8">{icon}</span>
        <span className="flex flex-row items-center content-center flex-grow pl-4"> {text}</span>
    </article>
)
//#endregion

export default OffersPage