import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from 'yup'
import { IconArrowLeft, IconLoader } from "../../Components/Icons"
import { PseudoLink, SecondaryButton } from "../../Components/Button"
import { parseErrorMsg, COUNTRIES_MAP, STATES_MAP } from "../../utils/misc";
import { ToastSuccess, ToastError } from "../../Components/Toast"
import { Listbox } from "@headlessui/react";
import { StatesUniversalApi } from "../../utils/StatesUniversalApi"

const INITIAL_FORM_DATA = {
    street: '',
    ext: '',
    int: '',
    neighborhood: '',
    zip_code: '',
    city: '',
    state: '',
    country: '',
    is_billing_address: false
}

let schema = yup.object().shape({
    street: yup.string().min(2, "Mínimo dos caracteres").required("Campo requerido"),
    ext: yup.string().required("Campo requerido"),
    int: yup.string(),
    neighborhood: yup.string().optional(),
    zip_code: yup.string().min(4, "Mínimo cuatro caracteres").required("Campo requerido"),
    city: yup.string().required("Campo requerido"),
    state: yup.string().required("Campo requerido"),
    country: yup.string().oneOf(Object.values(COUNTRIES_MAP), 'no válido').required('Campo requerido'),
    is_billing_address: yup.boolean().required("Campo requerido"),
})

const MyErrMsg = msg => <span className="text-xs text-red-500 h-px">{msg}</span>

const AdressData = ({ toPrevious, toNext, saveData, hidden, postData }) => {
    const [gettingStates, setGettingStates] = useState(false)
    const [gettingCities, setGettingCities] = useState(false)
    const [countryStates, setCountryStates] = useState([])
    const [stateCities, setStateCities] = useState([])
    const [currentCountry, setCurrentCountry] = useState(undefined)
    const [currentState, setCurrentState] = useState(undefined)
    const [currentCity, setCurrentCity] = useState('Otra')

    const getStatesByCountry = async (Country) => {
        if (gettingStates) return

        setGettingStates(true)
        setStateCities(['Otra'])
        setCurrentCity('Otra')

        try {
            const states = await StatesUniversalApi.getStates(Country)
            setCountryStates(states)
        } catch (err) {
            setCountryStates([])
        }

        setGettingStates(false)
    }

    const getCitiesByState = async (State) => {
        if (gettingCities) {
            return
        }

        setGettingCities(true)

        try {
            const cities = await StatesUniversalApi.getCities(State)
            cities.splice(0, 0, 'Otra')
            setStateCities(cities)

            if (!cities.includes(currentCity)) setCurrentCity('Otra')
        } catch (err) {
            setStateCities([])
        }

        setGettingCities(false)
    }

    const onChangeCountry = (e) => {
        getStatesByCountry(e)
    }

    const onChangeState = (e) => {
        setCurrentState(e)
        setCurrentCity('Otra')
        setStateCities(['Otra'])
        if (e) getCitiesByState(e)
    }

    const onChangeCity = (e) => {
        setCurrentCity(e)
        return
    }

    const handleSubmit = async (data, { setSubmitting, setFieldError }) => {
        /*console.log(data)
        setSubmitting(false)
        return*/
        // data has been validated
        try {
            const formData = { ...data, zip_code: data.zip_code.toString() }
            saveData(formData)
            await postData()
            ToastSuccess('registro de usuario exitoso')
            toNext()
        } catch (error) {
            ToastError("Ocurrió un error, inténtelo de nuevo más tarde")
            console.log(parseErrorMsg(error));

            if (error.response?.status === 409) {
                const response = error.response.data
                if (response.fields?.email)
                    setFieldError('email', 'Este correo ya existe')
                if (response.fields?.username)
                    setFieldError('username', 'Este nombre de usuario ya existe')
            }
        }
        setSubmitting(false)
    }

    return <div className={`${hidden && 'hidden'} relative flex-1`}>
        <div className="text-xl mb-2 font-semibold">Datos domiciliarios</div>
        <div>Ingresa la información solicitada a continuación</div>

        <Formik
            initialValues={INITIAL_FORM_DATA}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, setFieldValue, errors, touched }) => (
                <Form className="mt-10 mb-8 grid grid-cols-4 gap-x-5 gap-y-10 text-black">
                    { /* inputs */}
                    <div className="col-span-2">
                        <label
                            className="block text-xs mb-1"
                            htmlFor="street">
                            Avenida / Calle
                        </label>
                        <Field name="street">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="street" render={MyErrMsg} />
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="ext">
                            Exterior
                        </label>
                        <Field name="ext">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="ext" render={MyErrMsg} />
                    </div>
                    <div>
                        <label
                            className="block text-xs mb-1"
                            htmlFor="int">
                            Interior
                        </label>
                        <Field name="int">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="int" render={MyErrMsg} />
                    </div>
                    <div className="col-span-2">
                        <label
                            className="block text-xs mb-1"
                            htmlFor="neighborhood">
                            Colonia
                        </label>
                        <Field name="neighborhood">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="neighborhood" render={MyErrMsg} />
                    </div>
                    <div className="col-span-2">
                        <label
                            className="block text-xs mb-1"
                            htmlFor="zip_code">
                            Código Postal
                        </label>
                        <Field name="zip_code">
                            {({ field, meta: { error, touched } }) => (
                                <input
                                    type="number"
                                    {...field}
                                    className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="zip_code" render={MyErrMsg} />
                    </div>
                    <div className="col-span-4 md:col-span-2">
                        <label
                            className="block mb-1 text-xs"
                            htmlFor="country">
                            País
                        </label>
                        <fieldset disabled={isSubmitting}>
                            <Field name="country" value={currentCountry}>
                                {({ field, form: { error, touched } }) => (
                                    <input readOnly {...field} className={`text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`} />
                                )
                                }
                            </Field>
                            <Listbox onChange={(e) => {
                                setFieldValue('country', COUNTRIES_MAP[e], true)
                                setFieldValue('state', '', true)
                                setFieldValue('city', '', true)
                                onChangeCountry(e)
                            }}>
                                <div className="relative mt-1" style={{
                                    marginTop: '-40px'
                                }}>
                                    <Listbox.Button className={`${isSubmitting ? '' : 'bg-gray-200'} bg-gray-300 py-2`}
                                        style={{
                                            width: '100%',
                                            opacity: '0'
                                        }}>
                                        <small className="text-secondary"><b>Seleccionar</b></small>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        style={{
                                            opacity: '1',
                                            zIndex: '10'
                                        }}>
                                        {Object.keys(COUNTRIES_MAP).map((key, idx) => (
                                            <Listbox.Option
                                                key={idx}
                                                className={({ active }) =>
                                                    `${active ? 'bg-gray-200' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                                }
                                                value={key}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <span
                                                            className={`${selected ? 'font-medium' : 'font-normal'
                                                                } block truncate`}
                                                        >
                                                            {COUNTRIES_MAP[key]}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                        </fieldset>
                        <ErrorMessage name="country" render={MyErrMsg} />
                    </div>
                    <div className="col-span-4 md:col-span-2">
                        <label
                            className="block mb-1 text-xs"
                            htmlFor="state">
                            Estado
                        </label>
                        <fieldset disabled={isSubmitting || gettingStates}>
                            <Field name="state" value={currentState}>
                                {({ field, form: { error, touched } }) => (
                                    <input readOnly {...field} className={`${gettingStates?'bg-gray-200':''} text-sm rounded-md w-full px-3 py-2 border border-primary ${error && touched ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`} />
                                )
                                }
                            </Field>
                            <Listbox onChange={(e) => {
                                setFieldValue('state', e, true)
                                setFieldValue('city', '', true)
                                onChangeState(e)
                            }}>
                                <div className="relative mt-1" style={{
                                    marginTop: '-40px'
                                }}>
                                    <Listbox.Button className={`${isSubmitting ? '' : 'bg-gray-200'} py-2`}
                                        style={{
                                            width: '100%',
                                            opacity: '0'
                                        }}>
                                        <small className="text-secondary"><b>Seleccionar</b></small>
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        style={{
                                            opacity: '1',
                                            zIndex: '10'
                                        }}>
                                        {countryStates.map((state, idx) => (
                                            <Listbox.Option
                                                key={idx}
                                                className={({ active }) =>
                                                    `${active ? 'bg-gray-200' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                                }
                                                value={state}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <span
                                                            className={`${selected ? 'font-medium' : 'font-normal'
                                                                } block truncate`}
                                                        >
                                                            {state}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                        </fieldset>
                        <ErrorMessage name="state" render={MyErrMsg} />
                    </div>
                    <div className="col-span-4">
                        <label
                            className="block text-xs mb-1"
                            htmlFor="city">
                            Ciudad
                        </label>
                        <fieldset disabled={isSubmitting || gettingCities}>
                            <Listbox onChange={(e) => {
                                if (e !== 'Otra') setFieldValue('city', e, true)
                                else setFieldValue('city', '', true)
                                onChangeCity(e)
                            }}>
                                <div className="w-1/2 mt-1 inline-block relative">
                                    <Listbox.Button className={`text-sm h-10 ${currentCity === 'Otra' ? 'rounded-l-md' : 'rounded-md'} text-left w-full px-3 py-2 border border-primary ${errors?.city && touched?.city ? 'border-red-500' : ''} 
                                                        ${gettingCities ? 'bg-gray-200' : ''}`} >
                                        {currentCity}
                                    </Listbox.Button>
                                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md border max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        style={{zIndex:'10'}}>
                                        {stateCities.map((city, idx) => (
                                            <Listbox.Option
                                                key={idx}
                                                className={({ active }) =>
                                                    `${active ? 'bg-gray-200' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                                }
                                                value={city} >
                                                {({ selected, active }) => (
                                                    <>
                                                        <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`} >
                                                            {city}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </div>
                            </Listbox>
                            <Field
                                name="city"
                                className={`${currentCity === 'Otra' ? '' : 'hidden'} text-sm rounded-r-md w-1/2 px-3 py-2 border border-primary ${errors?.city && touched?.city ? 'border-red-500' : ''} h-10 focus:outline-none focus:border-secondary`}
                                disabled={isSubmitting}
                            />
                        </fieldset>
                        <ErrorMessage name="city" render={MyErrMsg} />
                    </div>
                    <div className="col-span-4 flex flex-row items-center mb-12">
                        <Field name="is_billing_address" type="checkbox" className="ml-1 mr-4 focus:outline-secondary focus:border-secondary" style={{ height: "20px", width: "20px" }} />
                        <label
                            className="h-3 text-xs"
                            htmlFor="is_billing_address">
                            Deseo utilizar esta dirección para facturar.
                        </label>
                    </div>

                    {/* actions */}
                    <div className="absolute bottom-0 w-full flex flex-1 flex-row justify-between">
                        <PseudoLink className="text-xs font-semibold inline-flex items-center" onClick={toPrevious}>
                            <IconArrowLeft
                                className="mr-7"
                                style={{ height: 10 }}
                            />
                            Regresar
                        </PseudoLink>
                        {isSubmitting
                            ? <button className="w-26 px-5 py-3 text-sm rounded-md bg-primary text-white flex justify-center" type="button" >
                                <IconLoader
                                    className="text-white fill-current animate-spin"
                                    style={{ height: 20 }}
                                />
                            </button>
                            : <button className="w-26 px-5 py-3 text-sm rounded-md bg-primary text-white">
                                Continuar
                            </button>
                        }
                    </div>
                </Form>
            )}
        </Formik>
    </div>
}
export default AdressData
