export const misticoConfigOptions = {
    preferencesOptions: {
        label: "Preferencia",
        options: {
            male: {label:'Terapeuta masculino', selected:false}, 
            female: {label:'Terapeuta femenino', selected:false}, 
            christianity: {label:'Terapia basada en cristianidad', selected:false}, 
            lgbt: {label:'Terapeuta de la comunidad LGBTQ+', selected:false}, 
            old: {label:'Terapeuta mayor (+40)', selected:false}, 
            non_religious: {label:'Terapeuta no religioso', selected:false}
        } 
    },
    specialitiesOptions: {
        label: "Especialidad",
        options: {
            stress_anxiety: 'Stress y Ansiedad', 
            depress: 'Depresión', 
            motivation: 'Motivación, autoestima y confianza',
            family_problems: 'Conflictos familiares',
            anger: 'Manejo de la ira',
            loss_grief: 'Hacer frente a la pérdida y duelo',
            addictions: 'Hacer frente a las adicciones',
            relationships: 'Problemas de relaciones', 
            life_changes: 'Hacer frente a los cambios de la vida', 
            trauma_abuses: 'Trauma y abuso', 
            focus: 'Concentración, memoria y enfoque (TDAH)', 
            privacy: 'Problemas relacionados con la imtimidad', 
            professional_difficulties: 'Dificultades profesionales', 
            sleep_disorders: 'Desordenes de sueño', 
            eating_disorders: 'Desordenes alimenticios', 
            raising_problems: 'Problemas de crianza', 
            fatigue: 'Fatiga de la compasión', 
            bipolar_disorder: 'Desorden bipolar', 
            lgbt: 'Problemas relacionados con LGBTQ+', 
            coaching: 'Coaching ejecutivo y profesional'
        }
    },
    themeOptions: {
        love_relation_family: {
            label: "Amor, relaciones y familia",
            options: {
                divorce: "Divorcio / Ruptura",
                infidelity: "Infidelidad / Indecisión",
                find_love: "Encontrar el amor / Almas gemelas",
                lgbt_relationships: "Relaciones LGBT",
                sexual_life: "Vida Sexual / Intimidad",
                family_problems: "Hermanos / Problemas familiares",
                socialmedia_influence: "Influencia de los medios sociales",
                toxic_relationships: "Relaciones tóxicas",
            }
        },
        career_finances: {
            label: "Carrera profesional y finanzas",
            options: {
                professional_changes: "Cambios en la Carrera profesional",
                coworkers_problems: "Problemas con compañeros de trabajo",
                financial_stress: "Tensión financiera",
                bullying: "Acoso",
                wealth_manifestation: "Manifestación de riquezas",
                new_job: "Trabajo nuevo",
                office_romance: "Romance en la oficina",
                work_life_balance: "Balance trabajo / vida",
            }
        },
        life_destiny_meaning: {
            label: "Vida, destino y significado",
            options: {
                auras: "Auras",
                cakras: "Chakras",
                dreams_interpretation: "Interpretación de sueños",
                acceptance_change: "Aceptación del cambio / Salir adelante",
                law_attraction: "Ley de atracción",
                life_coach: "Coach de vida",
                lost_objects: "Objetos perdidos",
                meditation: "Meditación",
                past_life_interpretations: "Interpretación de vidas pasadas",
                spiritual_guide: "Guías espirituales",
                spirituality: "Espiritualidad",
                discovery_psychic_abilities: "Descubrimiento de habilidades psíquicas",
                transcendental_reprogramming: "Reprogramación transcendental",
                biomagnetism_therapy: "Terapia de Biomagnetismo",
                decoding_trapped_emotions: "Descodificación de emociones atrapadas",
            }
        },
        loss_grief: {
            label: "Pérdida y duelo",
            options: {
                face_death: "Enfrentar a la muerte",
                pending_goodbyes: "Despedidas pendientes",
                pet_lost: "Duelo de mascotas",
                overcoming_past: "Superando el pasado",
                connection_signals: "Señales de conexión",
                bond_healing: "Sanación de lazos"
            }
        },
    },
    instrumentsOptions: {
        label: "Instrumentos",
        options: {
            angels: "Ángeles",
            astrology: "Astrología",
            consultation_without_instruments: "Consulta sin instrumentos",
            cartomancy: "Cartomancia o lectura de cartas",
            crystals: "Cristales",
            dowsing: "Radiestesia",
            numerology: "Numerología",
            reiki: "Reiki / Sanación",
            tarot: "Tarot",
            magnets: "Imanes",
        }
    },
    lectureStyleOptions: {
        label: "Estilo de lectura",
        options: {
            compassionate: "Compasivo",
            direct: "Directo",
            expressive: "Expresivo",
            reflexive: "Reflexivo",
            wise: "Sabio",
        }
    }
}

export const themeOptionsData = {
    specialities: {
        showed: true,
        selectedAll: false,
        label: "Especialidad",
        options: {
            stress_anxiety: {label:'Stress y Ansiedad', selected:false}, 
            depress: {label:'Depresión', selected:false}, 
            motivation: {label:'Motivación, autoestima y confianza', selected:false},
            family_problems: {label:'Conflictos familiares', selected: false},
            anger: {label:'Manejo de la ira', selected:false},
            loss_grief: {label: 'Hacer frente a la pérdida y duelo', selected:false},
            addictions: {label: 'Hacer frente a las adicciones', selected: false},
            relationships: {label:'Problemas de relaciones', selected:false}, 
            life_changes: {label:'Hacer frente a los cambios de la vida', selected:false}, 
            trauma_abuses: {label:'Trauma y abuso', selected:false}, 
            focus: {label:'Concentración, memoria y enfoque (TDAH)', selected:false}, 
            privacy: {label:'Problemas relacionados con la imtimidad', selected:false}, 
            professional_difficulties: {label:'Dificultades profesionales', selected:false}, 
            sleep_disorders: {label:'Desordenes de sueño', selected:false}, 
            eating_disorders: {label:'Desordenes alimenticios', selected:false}, 
            raising_problems: {label:'Problemas de crianza', selected:false}, 
            fatigue: {label:'Fatiga de la compasión', selected:false}, 
            bipolar_disorder: {label:'Desorden bipolar', selected:false}, 
            lgbt: {label:'Problemas relacionados con LGBTQ+', selected:false}, 
            coaching: {label:'Coaching ejecutivo y profesional', selected:false}
        }
    },
    reasons: {
        showed: false,
        selectedAll: false,
        label: "Razón",
        options: {
            depress: {label:'Me he estado sintiendo deprimido', selected:false}, 
            anxiety: {label:'Me siento ansioso y abrumado', selected:false}, 
            live_goals: {label:'No puedo encontrar propósito y significado en mi vida', selected:false}, 
            grief: {label:'Entoy en duelo', selected:false}, 
            dramatic_experience: {label:'Tuve una experiencia traumática', selected:false},
            challenge: {label:'Necesito hablar sobre un desafío específico', selected:false}, 
            confidence: {label:'Quiero ganar confianza en mí mismo', selected:false}, 
            improvement: {label:'Quiero mejorar pero no sé por dónde empezar', selected:false}, 
            by_recommendation: {label:'Me lo recomendaron (amigo, familia, doctor)', selected:false}, 
            exploring: {label:'Solo estoy explorando', selected:false}
        }
    },
    habilities: {
        showed: false,
        selectedAll: false,
        label: "Habilidades",
        options: {
            listening: {label:'Escucha', selected:false}, 
            past_exploration: {label:'Explora tu pasado', selected:false}, 
            new_hability: {label:'Te enseña una habilidad nueva', selected:false}, 
            beliefs: {label:'Reta tus creencias', selected:false}, 
            duties: {label:'Te da tarea', selected:false}, 
            challenge: {label:'Te guía a ponerte retos', selected:false}, 
            how_you_are: {label:'Proactivamente checa cómo estás', selected:false}
        }
    },
    interests: {
        showed: false,
        selectedAll: false,
        label: "Intereses",
        options: {
            self_love: {label:'Amor propio', selected:false}, 
            communication_problems: {label:'Problemas de comunicación', selected:false}, 
            life_purpose: {label:'Propósito en la vida', selected:false}, 
            anxiety: {label:'Ansiedad social y fobias', selected:false}, 
            embarrassing: {label:'Culpa y vergüenza', selected:false}, 
            control_problems: {label:'Problemas de control', selected:false}, 
            forgiveness: {label:'Perdón', selected:false}, 
            panic_attacks: {label:'Desórdenes y ataques de pánico', selected:false}, 
            stress: {label:'Estrés post-traumático', selected:false}, 
            body_view: {label:'Imagen corporal', selected:false}, 
            mood_disorders: {label:'Desórdenes de ánimo', selected:false}, 
            abandon: {label:'Abandono', selected:false}, 
            divorce: {label:'Divorcio y separación', selected:false}, 
            financial_problems: {label:'Dinero y problemas financieros', selected:false}, 
            carers_stress: {label:'Problemas y estrés de cuidadores', selected:false}, 
            impulsiveness: {label:'Impulsividad', selected:false}, 
            women_problems: {label:'Problemas de mujeres', selected:false}, 
            sexuality: {label:'Sexualidad', selected:false}, 
            pregnancy: {label:'Embarazo y parto', selected:false},
            work_place: {label:'Problemas en el lugar de trabajo', selected:false}, 
            narcissism: {label:'Narcicismo', selected:false}, 
            pain: {label:'Enfermedades de dolor crónico y discapacidad', selected:false}, 
            men_problems: {label:'Problemas de hombres', selected:false}, 
            crisis_40: {label:'Crisis de los 40', selected:false}
        }
    },
}

export const themeOptionsLabels = {
    specialities: false,
    reasons: false,
    habilities: false,
    interests: false
}