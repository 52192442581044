import { mergeClassName } from "../../utils/misc";

const PseudoLinkButton = ({ color = "text-primary", justify = "justify-center", ...props }) => {
    props = mergeClassName(`${color} focus:outline-none flex items-center ${justify} cursor-pointer`, props)

    return (
        <div {...props}>
            {props.label ? props.label : props.children}
        </div>
    )
}

export default PseudoLinkButton