// Brand Logos
import brandLogo from './Brand/portal-mistico-transparent.png'
import brandLogoBlack from './Brand/portal-mistico-black-transparent.png'
import Home_Physic1 from './Images/physic-1.png'
import Home_Physic2 from './Images/physic-2.png'
import Home_Physic3 from './Images/physic-3.png'
import Home_Physic4 from './Images/physic-4.png'
import Home_WhyOurPlatform from './Images/why-our-platform.png'
import Home_WhyOurPlatform2 from './Images/why-our-platform-2.png'
import Home_ShieldCheck from './Images/shield-check.svg'

import Article_1 from './Images/article-1.png'
import Article_2 from './Images/article-2.png'
import Article_3 from './Images/article-3.png'
import Article_4 from './Images/article-4.png'
import Article_5 from './Images/article-5.png'
import Profile_1 from './Images/profile-1.png'
import Profile_2 from './Images/profile-2.png'
import Profile_3 from './Images/profile-3.png'
import Profile_4 from './Images/profile-4.png'
import Profile_5 from './Images/profile-5.png'
import Oxxo_Logo from './Icon/Oxxo_Logo.svg'
import videoCall from './Images/videoCall.png'
import PayPal_Logo from './Icon/PayPal_Logo.svg'

import Testimonial_1 from './Images/testimonial-1.png'

import Icon_Etica from './Icon/icon-etica.svg'
import Icon_Expertos from './Icon/icon-expertos.svg'
import Icon_Recompensas from './Icon/icon-recompensas.svg'
import Icon_Quote from './Icon/quote icon.png'
import Icon_Quote_Blue from './Icon/quote-icon-blue.png'
import Icon_Call from './Icon/icon-call.png'
import Icon_Facebook from './Icon/icon-facebook.png'
import Icon_Instagram from './Icon/icon-instagram.png'

import Icon_ChevronLeft from './Icon/icon-chevron-left.png'
import Icon_ChevronRight from './Icon/icon-chevron-right.png'

// Site images
import Home_Top from './Images/home-1.png'
import Demo_Consultation from './Images/consultation-header.png'
import ClientServiceHeader from './Images/clientService.jpg'
import RequestHeader from './Images/requestHeader.jpg'
import Manifest from './Images/manifest.jpg'
import EthicCode from './Images/ethicCode.jpg'
import HowWorks from './Images/howWorks.jpg'
import Prices from './Images/prices.jpg'
import Testimonials from './Images/testimonials.jpg'
import Satisfaction from './Images/satisfaction.jpg'
import GlossaryImage from './Images/glossary.jpg'
import SatisfactionGuaranteed from './Images/SatisfactionGuaranteed.svg'

const assets = {
    brandLogo,
    brandLogoBlack,
    Home_Top,
    Home_Physic1,
    Home_Physic2,
    Home_Physic3,
    Home_Physic4,
    Home_WhyOurPlatform,
    Home_WhyOurPlatform2,
    Home_ShieldCheck,
    videoCall,
    Manifest,
    EthicCode,
    HowWorks,
    Prices,
    Testimonials,
    Satisfaction,
    GlossaryImage,
    SatisfactionGuaranteed,
    // Zodiac sign
    Zodiac_Acuario:null,
    Zodiac_Piscis:null,
    Zodiac_Aries:null,
    Zodiac_Tauro:null,
    Zodiac_Geminis:null,
    Zodiac_Cancer:null,
    Zodiac_Leo:null,
    Zodiac_Virgo:null,
    Zodiac_Libra:null,
    Zodiac_Escorpion:null,
    Zodiac_Sagitario:null,
    Zodiac_Capricornio:null,
    // topics themes icons

    // Artículos
    Article_1,
    Article_2,
    Article_3,
    Article_4,
    Article_5,

    // Perfiles
    Profile_1,
    Profile_2,
    Profile_3,
    Profile_4,
    Profile_5,

    // Testimonial
    Testimonial_1,

    // Icons
    Icon_Etica,
    Icon_Expertos,
    Icon_Recompensas,
    Icon_Quote,
    Icon_Quote_Blue,
    Icon_Call,
    Icon_Facebook,
    Icon_Instagram,
    Icon_ChevronLeft,
    Icon_ChevronRight,
    Oxxo_Logo,
    PayPal_Logo,

    // Assets Demo
    Demo_Consultation,
    ClientServiceHeader,
    RequestHeader
}

export default assets