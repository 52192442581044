import { useEffect, useState } from "react"
import { SecondaryButton, LinkButton, PseudoLink } from "../Button"
import { calculateUserDisplayName } from "../../utils/misc"
import ModalDialog from "../ModalDialog"
import { FilledStars, UnfilledStar, IconLoader, IconHeartFilled, IconHeartEmpty } from "../Icons"
import { ToastError, ToastSuccess, ToastDark } from "../Toast";
import Loader from "./../Loader";
import reviews from "../../integrations/reviews"

const initialValues = {
    rating: 0,
    commentary: "",
    title: "",
    session: ""
}

const RateAnExpert = ({ session, expert, call, evaluated, handleClose }) => {
    const [showRating, setShowRating] = useState(call?true:false)
    const [ratingData, setRating] = useState(initialValues)
    const [isLoading, setIsLoading] = useState(false)
    const [mustHide, setmustHide] = useState(evaluated)
    
    useEffect(() => {
        setRating({ ...initialValues, expert: expert._id, session })
    }, [showRating, session])

    const toggle = () => {
        if(call){
            handleClose()
        }
        setShowRating(prev => !prev)
    }
    const handleRate = (rating) => {
        setRating({ ...ratingData, rating })
    }

    const handleCancel = () => {
        if (!isLoading) {
            setShowRating(prev => !prev)
            setRating(initialValues)
            console.log(handleClose);
            handleClose(1)
        }
    }
    
    const handleSubmit = () => {
        if (!ratingData.title.trim()) {
            ToastError("Debe agregar un título")
            return
        } if (ratingData.rating===0) {
            ToastError("Debe calificar al experto")
            return
        } if (!ratingData.commentary.trim()) {
            ToastError("Debe agregar un comentario")
            return
        } if(!session) {
            ToastError("No se ha especificado la sesión")
            return
        }
        
        setIsLoading(prev => !prev)
        try {
            setIsLoading(prev => !prev)

            ratingData.expert = expert.id ? expert.id: expert._id
            
            const res = reviews.store(ratingData)
            if (res) {
                console.log(res)
                toggle()
                setIsLoading(prev => !prev)
                ToastSuccess("Calificación enviada")
                setmustHide(true)
            }
        } catch (error) {
            setIsLoading(prev => !prev)
            ToastError("Se produjo un error. Por favor inténtalo de nuevo más tarde.")
        }
    }

    const handleInputOnChange = (key, value) => {
        if(value && value.length>149) {
            ToastDark("Un máximo de 150 caracteres")
        }
        setRating({ ...ratingData, [key]: value })
    }

    return <>
        {
            !ratingData.session? <Loader /> : <>
            {!call && <button style={{ display: mustHide != null && mustHide ? 'none' : 'block' }} onClick={toggle} 
                className="px-2 py-2 mx-auto text-sm w-25 rounded bg-primary text-white">
                    Escribir opinión {evaluated}
                    </button>
            }
            <ModalDialog close={toggle} visible={showRating}>
                <h4 className="text-base font-semibold text-2xl text-black-400 mb-8">Califica el servicio de {calculateUserDisplayName(expert)}</h4>
                <div className="text-left">
                    <label className="text-xs">Título</label>
                    <input value={ratingData.title} onChange={(e) => handleInputOnChange("title", e.target.value)}
                        className="w-full block border border-primary rounded h-10 mt-1 mb-3 focus:outline-none focus:border-secondary pl-2 pr-5" />
                    <label className="text-xs">Calificación</label>
                    <RatingStarts handleRate={handleRate} rating={ratingData.rating} />
                    <label className="text-xs">Deja tus comentarios</label>
                    <textarea onChange={(e) => handleInputOnChange("commentary", e.target.value)} maxLength={150}
                        className="w-full block border border-primary rounded h-30 mt-3 focus:outline-none focus:border-secondary pl-2 pr-5"></textarea>
                </div>
                <div className="flex justify-end mt-3">
                    <button
                        onClick={handleCancel}
                        className="px-6 py-3 mr-5 text-sm rounded bg-gray-100 text-primary focus:outline-none"
                        disabled={isLoading}>Cancelar</button>
                    {isLoading
                        ? <button className="px-6 py-3 text-sm bg-primary text-white rounded tracking-wider focus:outline-none">
                            <IconLoader
                                className="text-white fill-current animate-spin"
                                style={{ height: 20 }}
                            />
                        </button>
                        : <button
                            className="px-6 py-3 text-sm bg-primary text-white rounded tracking-wider focus:outline-none"
                            onClick={handleSubmit}>Enviar</button>
                    }
                </div>
            </ModalDialog> </>
        }        
    </>
}

const RatingStarts = ({ rating, width = 30, height = 30, handleRate }) => {
    const stars = new Array(5).fill('star')
    const props = {
        className: "cursor-pointer", width, height
    }
    return <span className="flex justify-center gap-x-0.5 mx-auto mt-3">
        {stars.map((star, key) => (key < rating ? <IconHeartFilled onClick={() => handleRate(key + 1)} key={`g-rating-${key}`} {...props} /> :
            <IconHeartEmpty onClick={() => handleRate(key + 1)} key={`g-rating-${key}`} {...props} />))
        }
    </span>
}

export default RateAnExpert
