import { ServicesThemesList } from "../Home/HoroscopeOfDay"
import { Link } from "react-router-dom"
import Recommended from '../../Components/Recommended'
import EmailSuscribe from "../Horoscopes/Components/Emailsuscribe"

const ServicesComponent = () => {
    return (
        <div className="mt-10">
            <div>
                <div className="text-3xl nunito-font-bold text-center">Nuestros servicios</div>
                <div className="text-lg mt-10 text-center">
                    Soluciones personalizadas para tus necesidades de paz mental
                </div>
            </div>
            <div className="mt-10 lg:px-20 max-w-7xl mx-auto">
                <div className="grid gap-y-14 mb-20 mt-11 grid-cols-2 xl:grid-cols-5">
                    {
                        Object.keys(ServicesThemesList).map((key, idx) => (
                            <Link to={`/servicios/${ServicesThemesList[key].link}`} key={`service-idx-${idx}`}
                                className="flex flex-col items-center">
                                <span className="flex items-center justify-center p-4 bg-white hover:bg-secondary rounded-full border border-primary shadow-xl">
                                    <img src={ServicesThemesList[key].icon} className="h-20 w-20" />
                                </span>
                                <div className="mt-4 text-center">{key}</div>
                            </Link>
                        ))
                    }
                </div>
            </div>
            <div>
                <EmailSuscribe />
            </div>
            <div className="max-w-6xl mx-auto">
                <Recommended />
            </div>
        </div>
    )
}

export default ServicesComponent