const SHORT_MONTHS = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"]
const MONTHS = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

let calculateFromIso = (dateString) => ({
    year: dateString.substring(0, 4),
    month: dateString.substring(5, 7),
    day: dateString.substring(8, 10)
})

const time = {
    toHumanShort: (date) => {
        let temp = new Date(date);
        if (isNaN(temp.getTime()))
            return 'invalid date'

        const {year, month, day} = calculateFromIso(temp.toISOString())
        
        return `${SHORT_MONTHS[month-1]}. ${day}, ${year}`
    },


    toHumanLong: (date) => {
        let temp = new Date(date);
        if (isNaN(temp.getTime()))
            return 'invalid date'

        const {year, month, day} = calculateFromIso(temp.toISOString())
        
        return `${MONTHS[month-1]}. ${day}, ${year}`
    }
}
export default time
