import React, { useState, useContext } from "react"
import Dialog from "../Dialog"
import { IconCross, UploadIcon, IconRoundCheck } from "../../../../Components/Icons"
import { SecondaryButton } from "../../../../Components/Button"
import Loader from "../../../../Components/Loader"
import profile from "../../../../integrations/profile"
import { ToastError } from "../../../../Components/Toast";
import { Context, StoreActions } from '../../../../store/useGlobalState'
import MyLocalStorage, { lsKeys } from "../../../../utils/localStorage"

const ImageUploader = ({ close }) => {
    const { globalState, globalDispatch } = useContext(Context)
    const [file, setFile] = useState(null)
    const [fileURI, setFileURI] = useState(null)
    const [fileBinary, setFileBinary] = useState(null)
    const [saved, setSaved] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleFileOnChange = (file) => {
        if (file) {
            if (file.size>(1024*1024)) {
                ToastError("El tamaño de la imagen no puede ser mayor a 1MB")
                return
            }
            
            setFile(file)
            setFileURI(URL.createObjectURL(file))
            var reader = new FileReader();
            reader.onload = function (event) {
                var data = event.target.result;
                setFileBinary(data)
            };
            reader.readAsBinaryString(file);
        }
    }

    const handleSaveFile = async () => {
        setSaving(true)
        const { user } = globalState
        try {
            const res = await profile.uploadImage(fileBinary, file?.name)
            if (res) {
                const userData = {
                    ...user,
                    image: res
                }
                globalDispatch({ type: StoreActions.SET_USER, payload: userData })
                if (MyLocalStorage.read(lsKeys.sesion)) {
                    MyLocalStorage.save(lsKeys.sesion, { ...globalState, user: userData })
                }
            }
            setSaved(true)
        } catch (error) {
            console.log("upload Err", error)
            ToastError("Se produjo un error. Por favor inténtalo de nuevo más tarde.")
        }
        setSaving(false)
    }
    return <Dialog
        darkOverlay
        dismisable
        onClose={close}
        className="w-full lg:w-45rem"
        render={({ closeDialog }) =>
            <div className="relative z-10 px-10 py-5 bg-white rounded-lg">
                {
                    saving && <Loader />
                }
                <>
                    {/* cross */}
                    <IconCross
                        onClick={close}
                        className="absolute top-5 right-5 cursor-pointer text-secondaryText"
                        style={{ height: 10, width: 10 }}
                    />
                    {
                        saved ? <SuccesMessage close={close} /> : !saving && <>
                            {/* title */}
                            <div className="justify-center text-center flex-column">
                                <div className="text-lg lg:text-2xl font-semibold mb-3">Actualizar foto de perfil</div>
                            </div>

                            {/* content */}
                            <div className="text-black text-sm">
                                <Uploader fileURI={fileURI}
                                    handleOnChange={handleFileOnChange} />
                            </div>
                            {/* actions */}
                            <div className="flex flex-row justify-end">
                                <button
                                    onClick={closeDialog}
                                    className="px-6 py-3 rounded-md text-center tracking-wider focus:outline-none bg-gray-200 text-primary"
                                >Cancelar</button>
                                {
                                    file && <button
                                        onClick={handleSaveFile}
                                        className="ml-2 px-6 py-3 rounded-md text-center tracking-wider focus:outline-none bg-primary text-white"
                                    >
                                        {
                                            saving ? (
                                                <span>Actualizando...</span>
                                            ): (
                                                <span>Actualizar</span>
                                            )
                                        }
                                    </button>
                                }
                            </div>
                        </>
                    }
                </>
            </div>
        }
    />
}

const Uploader = ({ fileURI, handleOnChange }) => {
    return <div className="my-2 md:my-10 flex justify-center">
        <div className="relative cursor-pointer w-4/5 lg:w-3/5">
            <input
                class={"absolute inset-0 opacity-0 cursor-pointer w-full"}
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                    let file = e.target.files[0];

                    handleOnChange(file)
                }}
            />
            {
                fileURI ? <img className="mx-auto" style={{ maxHeight: "20rem" }} src={fileURI} /> : <div className="cursor-pointer border-4 border-secondary-light rounded p-10 text-center" style={{ borderStyle: "dotted" }}>
                    <UploadIcon className="w-20 m-auto block mb-5" />
                    <p>Arrastra o sube una imagen.</p>
                </div>
            }

        </div>
    </div>
}

const SuccesMessage = ({ close }) => {
    return <div className="flex flex-col z-10 max-w-2xl bg-white py-9 px-20 rounded-2xl">
        {/* title */}
        <div className="flex flex-col w-full text-lg text-center text-black">
            <h2 className="flex-col text-2xl font-bold">Imagen actualizada</h2>
        </div>

        {/* content */}
        <div className="flex flex-row justify-center mt-6 mb-12">
            <IconRoundCheck className="text-greenSuccess" />
        </div>
        {/* actions */}
        <div className="flex flex-row justify-center">
            <SecondaryButton
                onClick={close}
                label="Cerrar"
                className="w-48 py-3 text-sm tracking-wider focus:outline-none"
                type="button"
            />
        </div>
    </div>
}

export default ImageUploader