import Icon from "../../../Assets/Icon"

export const OfferItemRightRow = ({ title, icon, description, href }) => (
    <div className="flex flex-row items-center content-center gap-x-6">
        <span className="flex flex-row text-left">
            <span className="flex-col inline-block w-0 h-6 mr-4 border-2 rounded-full" style={{ borderColor: 'rgb(110,146,201)' }}>&nbsp;</span>
            <span className="flex flex-row items-center content-center w-8 h-8 text-center rounded"
                style={{backgroundColor:'rgb(226,233,244)'}}>
                <Icon name={icon} className="mx-auto w-6 h-6" />
            </span>
        </span>
        <aside className="flex flex-col text-left">
            <a href={href}>
                <span className="flex-row text-primary">{title}</span>
            </a>
            <small className="flex-row">{description}</small>
        </aside>
    </div>
)

export const CustomOfferItemRightRow = ({ title, icon, description, href }) => (
    <div className="flex flex-row items-center content-center gap-x-6">
        <span className="flex flex-row text-left">
            <span className="flex-col inline-block w-0 h-6 mr-4 border-2 rounded-full" style={{ borderColor: 'rgb(110,146,201)' }}>&nbsp;</span>
            <span className="flex flex-row items-center content-center w-8 h-8 text-center rounded"
                style={{backgroundColor:'rgb(226,233,244)'}}>
                <Icon name={icon} className="mx-auto w-7 h-7" />
            </span>
        </span>
        <aside className="flex flex-col text-left">
            <a href={href}>
                <span className="flex-row text-primary">{title}</span>
            </a>
            <small className="flex-row line-clamp-3" dangerouslySetInnerHTML={{__html:description}} />
        </aside>
    </div>
)


export default OfferItemRightRow