import React from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"
import { ShieldCheck } from "../../Components/Icons"

const FAQ = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]
  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Preguntas frecuentes</div>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.ClientServiceHeader} />
          <div className="block mt-5">
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Quienes somos?</div>
              <p>
                Mi Paz Mental es una plataforma confiable de psicólogos, terapeutas 
                y coaches que se dedican a empoderar vidas ayudando a las personas a 
                encontrar soluciones personalizadas para sus necesidades de paz mental, 
                descubrir respuestas a sus preguntas más urgentes de salud mental, 
                lograr un cierre, encontrar su propósito en la vida y más. Somos 
                profesionales apasionados y compasivos, impulsados por la misión de 
                ayudar a más personas a vivir una vida mejor y más feliz todos los días. 
                Los psicólogos, terapeutas y coaches en Mi Paz Mental tienen diversas 
                especialidades y pueden ofrecer consultas psicológicas, terapias, 
                soluciones personalizadas para tus necesidades de paz mental, dar 
                consejos sobre el amor y las relaciones, responder a cuestiones de la 
                vida, y muchos temas más. No hay dos psicólogos, terapeutas y coaches 
                en nuestra comunidad iguales, ya que cada uno tiene su propio toque 
                personal cuando se trata de brindar servicios que ayudan a encontrar 
                la paz mental.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Qué hacemos en Mi Paz Mental?</div>
              <p>
                En Mi Paz Mental te conectamos con psicólogos, terapeutas y coaches 
                en servicios intuitivos y espirituales de una forma segura, desde 
                donde quieras y cuando quieras.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Por qué Mi Paz Mental?</div>
              <p>
                Por la experiencia, la comprensión y la atención de los psicólogos, 
                terapeutas y coaches y el equipo de servicio al cliente.
              </p>
              <p>
                Los mejores psicólogos, terapeutas y coaches : Conocimiento, dones, 
                habilidades y experiencia. Cada uno de nuestros psicólogos, terapeutas 
                y coaches cuenta con capacidades y estilos diferentes. Encuentra el 
                que más te guste.
              </p>
              <p>
                Flexibilidad: Haz tu consulta desde donde quieras y cuando quieras. 
                Fácil de usar y directo.
              </p>
              <p>
                Código de ética: Nuestros psicólogos, terapeutas y coaches se comprometen 
                a tratar a los clientes con respeto, compasión y profesionalismo.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Qué es y cómo funciona Mi Paz Mental?</div>
              <p className="mb-3">
                Los psicólogos, terapeutas y coaches de Mi Paz Mental ayudan a los 
                clientes con consultas psicológicas, terapias, soluciones personalizadas 
                para tus necesidades de paz mental, consejos sobre el amor y las relaciones, 
                responden a cuestiones de la vida, y muchos temas más. Sin importar si 
                ya eres cliente regular o nuevo, tenemos diversas formas de contactar 
                a un psicólogo, terapeuta o coach de Mi Paz Mental. Conéctate fácilmente 
                con tus psicólogos, terapeutas y coaches favoritos:
              </p>
              <ol className="mb-3 pl-4">
                <li className="list-decimal">Crea una cuenta</li>
                <li className="list-decimal">Agrega saldo</li>
                <li className="list-decimal">Elige al experto de tu preferencia</li>
                <li className="list-decimal">Haz tu consulta</li>
                <li className="list-decimal">Termina la consulta cuando tú quieras.</li>
              </ol>
              <p className="mb-3">
                Mi Paz Mental está abierto 24/7 y nuestros psicólogos, terapeutas 
                y coaches hacen lo posible por estar disponible en diversas zonas 
                horarias, pero como son humanos y no computadoras, algunas veces, 
                no están disponibles. Así que, si has elegido a un psicólogo, terapeuta 
                o coach y no está disponible, puedes programar una cita. No hay 
                necesidad de que pierdas tu tiempo esperando si algún psicólogo, 
                terapeuta o coach en específico se pone disponible. Simplemente 
                programa una cita a la hora que te sea más conveniente.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Qué es Mi Paz Mental?</div>
              <p>
                Mi Paz Mental es una plataforma confiable de psicólogos, terapeutas 
                y coaches que se dedican a empoderar vidas ayudando a las personas a 
                encontrar soluciones personalizadas para sus necesidades de paz mental, 
                descubrir respuestas a sus preguntas más urgentes de salud mental, 
                lograr un cierre, encontrar su propósito en la vida y más. Somos 
                profesionales apasionados y compasivos, impulsados por la misión de ayudar 
                a más personas a vivir una vida mejor y más feliz todos los días. 
                Los psicólogos, terapeutas y coaches en Mi Paz Mental tienen diversas 
                especialidades y pueden ofrecer consultas psicológicas, terapias, 
                soluciones personalizadas para tus necesidades de paz mental, dar 
                consejos sobre el amor y las relaciones, responder a cuestiones de la 
                vida, y muchos temas más. No hay dos psicólogos, terapeutas y coaches 
                en nuestra comunidad iguales, ya que cada uno tiene su propio toque 
                personal cuando se trata de brindar servicios.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Cómo funcionan las consultas en línea en Mi Paz Mental?</div>
              <p className="mb-3">
                En Mi Paz Mental puedes hablar de forma segura y privada con un psicólogo, 
                terapeuta o coach de confianza por teléfono o videollamada. Para tener 
                una consulta en línea con alguno de nuestros psicólogos, terapeutas y 
                coaches solo tienes que darle clic al botón de “llamada” o “videollamada” 
                o puedes agendar previamente una cita. La disponibilidad de horario 
                varía de acuerdo con la agenda del psicólogo, terapeuta o coach que 
                elecciones. Hacer una consulta en Mi Paz Mental es muy fácil:
              </p>
              <ul className="mb-5">
                <li>Paso 1: Crea una cuenta</li>
                <li>Paso 2: Agrega saldo</li>
                <li>Paso 3: Elige al experto de tu preferencia</li>
                <li>Paso 4: Haz tu consulta</li>
                <li>Paso 5: Termina la consulta cuando tú quieras.</li>
              </ul>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Cómo agendar mi primera cita en Mi Paz Mental?</div>
              <p>
                Haz clic en el botón “Agendar cita” del psicólogo, terapeuta o coach que 
                selecciones. La disponibilidad de horario varía de acuerdo con la agenda 
                del psicólogo, terapeuta o coach. Elige el tipo de sesión, la zona horaria, 
                la duración y el horario.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Cómo tomar mi primera cita en Mi Paz Mental?</div>
              <p>
                Una vez agendada la cita, cuando sea hora solo tienes que iniciar sesión e ingresar 
                a tu cuenta y en la sección “Agenda” darle clic a la cita agendada en la fecha 
                y hora programada.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Las consultas en línea son efectivas?</div>
              <p>
                Las consultas en línea te permiten recibir la misma atención que recibirías 
                en una sesión presencial, pero de forma remota. Podrás platicar con tu 
                psicólogo, terapeuta o coach por videollamada o teléfono sin salir de casa.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Necesito descargar alguna App de videollamada?</div>
              <p>
                No necesitas descargar ninguna App. dentro de Mi Paz Mental se abrirá la sesión.
              </p>
            </div>
            <div className="mb-5">
              <div className="font-semibold mb-3">¿Los pagos son seguros?</div>
              <p>
                ¡Si! Nuestro sistema de pagos está actualizado con los estándares más altos 
                de la industria. Toda la información personal es mantenida y protegida por 
                un procesador de pagos externo. Nosotros nunca almacenamos ninguna información 
                financiera personal.
              </p>
            </div>
          </div>
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>
      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <ul className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <li className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </li>
      })
    }
  </ul>
}


export default FAQ