import { React, useEffect } from "react"
import Recommended from "../../Components/Recommended"
import { Link } from "react-router-dom"
import assets from "../../Assets"

const SatisfactionGuaranteed = () => {
  const sideMenuItems = [
    {
      title: "Manifiesto",
      url: "manifiesto"
    },
    {
      title: "Código de ética",
      url: "codigo-de-etica"
    },
    {
      title: "Satisfacción garantizada",
      url: "satisfaccion-garantizada"
    },
    {
      title: "Testimonios",
      url:"testimonios"
    },
    {
      title: "Contáctanos",
      url:"contacto"
    },
    {
      title: "Preguntas frecuentes",
      url:"preguntas-frecuentes"
    },
    {
      title: "Cómo funciona",
      url:"como-funciona"
    },
    {
      title: "Precios",
      url:"precios"
    }

  ]

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return <>
    <div className="px-3 max-w-md px-6 pt-10 mx-auto lg:max-w-7xl sm:max-w-3xl lg:px-0">
      <section className="grid grid-cols-1 md:grid-cols-5">
        <div className="col-span-2 lg:pr-10 pb-3">
          <div className="text-3xl font-semibold mb-3">Satisfacción garantizada</div>
          <sub className="block text-base">
            Si no estás satisfecho con tu última consulta pagada, es gratis. Confiamos en 
            nuestro servicio y lo respaldamos con esta garantía sin riesgo.
          </sub>
          <SideMenu src={sideMenuItems} />
        </div>
        <div className="col-span-3">
          <img className="rounded" src={assets.Satisfaction} alt="satisfaction" />
          <div className="block mt-5">
            <div className="mb-5">
              <p>
                El equipo de Mi Paz Mental está dedicado a brindar una experiencia 
                al cliente excelente. Si no estás satisfecho, llámanos a nuestro 
                centro de servicio al cliente o envíanos un correo electrónico a 
                <a href="mailto:hola@portalmistico.com" className="text-blue-500 ml-2">hola@mipazmental.com</a>.
                Navegar el mundo psicológico no siempre es fácil, usar nuestros 
                servicios si lo debe ser. Tu satisfacción es nuestra prioridad, 
                por favor compártenos tus comentarios, preguntas o problemas.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Estamos muy contentos de que estés aquí. Gracias por elegirnos.
              </p>
            </div>
            <div className="mb-5">
              <div className="block mb-5 font-semibold">Garantía de satisfacción</div>
              <p>
                Queremos que cada conversación en nuestra plataforma cumpla con tus 
                expectativas. Si no es así, nos comprometemos a compensarlo.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Si tuviste un problema con la plataforma, puedes ser elegible para recibir 
                un crédito en tu cuenta, hasta un límite de $500 pesos de Mi Paz Mental, 
                que puede usarse en futuras conversaciones.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Ten en cuenta que se te permite recibir un reembolso por una experiencia 
                insatisfactoria cada 30 días y solo las conversaciones que ocurran dentro 
                de las últimas 72 horas son elegibles para el reembolso.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Los créditos de la Garantía de satisfacción se emiten en créditos de Mi Paz 
                Mental y es posible que los fondos promocionales no sean elegibles para reembolso.
              </p>
            </div>
            <div className="mb-5">
              <p>
                Mi Paz Mental se reserva el derecho, a su entera discreción, de aprobar, denegar 
                o emitir un crédito parcial por cualquier solicitud.
              </p>
            </div>
          </div>          
          <div>
            <img className="h-40" src={assets.SatisfactionGuaranteed} />
          </div>
        </div>

      </section>
    </div>
    <Recommended />
  </>
}

const SideMenu = ({ src, ...props }) => {
  return <div className="mt-30 pr-10 lg:pr-0">
    {
      src.map((x, i) => {
        return <div className="mb-6" key={`sub-consultation-${i}`}>
          <Link to={`/${x.url}`} className="pb-3 border-b block max-w-xs">{x.title}</Link>
        </div>
      })
    }
  </div>
}


export default SatisfactionGuaranteed