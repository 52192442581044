import React, { useState, useEffect } from "react";

const RigthPanel = () => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [animate, setAnimate] = useState(false)
    const [timeId, setTimeId] = useState(-1)

    useEffect(()=> {
        setAnimate(true)
        
        if (timeId>0) {
            try{
                clearTimeout(timeId)
            }catch(err){}
        }

        setTimeId(window.setTimeout(()=> {
            setTabTo((selectedTab+1)%4)
        }, 10000))
    }, [selectedTab])

    const contentStyle = {
        ...{
            opacity: '0',
            transition: 'opacity 0.8s linear'
        },
        ...animate?{opacity:'1'}:{}
    }

    const setTabTo = (tab)=> {
        setAnimate(false)
        setSelectedTab(tab)
    }

    return (
        <div className='relative pt-12 text-sm flex-5 bg-secondary-light p-7'>
            {selectedTab === 0 && <div style={contentStyle}>
                <div className="pb-5 text-xl font-bold">Nuestros psicólogos, terapeutas y coaches</div>
                <p className="mb-2 font-light">
                    <span className="font-bold">Mi Paz Mental</span> es una comunidad confiable 
                    de psicólogos, terapeutas y coaches que se dedican a empoderar vidas 
                    impulsados por la misión de ayudar a más personas a vivir una vida mejor 
                    y más feliz todos los días. No hay dos expertos en nuestra comunidad iguales, 
                    ya que cada uno tiene su propio toque personal cuando se trata de brindar 
                    servicios profesionales personalizados.
                </p>
                <p className="mb-2 font-light">
                    Solo aceptamos a psicólogos, terapeutas y coaches que realmente tengan 
                    la preparación, los estudios y la experiencia y estén dedicados a ayudar 
                    a los demás, y ponemos a todos los que aplican en un proceso riguroso 
                    de selección. Nos enorgullece nuestra comunidad de profesionales atentos 
                    y compasivos, con gran experiencia y conocimiento.
                </p>
                <p className=" font-light">
                    Obtén el apoyo y ayuda que necesitas y vive una vida mejor y más feliz. 
                    Sigue adelante con confianza.
                </p>
            </div>}
            {selectedTab === 1 && <div style={contentStyle}>
                <div className="pb-5 text-xl font-bold">¿Cómo funciona?</div>
                <p className=" font-light">
                    En <span className="font-bold">Mi Paz Mental</span> tu controlas cuanto gastas, y solo te
                    cobramos por el tiempo que hables con el profesional. No hay costos
                    escondidos y cargos sorpresa.
                </p>
                <div className="my-2 font-bold">
                    Hacer tu consulta es muy fácil:
                </div>
                <p className="mb-2 font-light"><span className="font-bold">Paso 1:</span> Regístrate y abre una cuenta.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 2:</span> Visita la página de nuestros psicólogos y elige al de tu preferencia.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 3:</span> Haz clic en el botón “Llamar” o “Videollamar” dependiendo del servicio que prefieras.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 4:</span> Haz tu consulta. Recibirás una llamada o se abrirá una ventana del chat para iniciar la consulta con el profesional.</p>
                <p className="mb-2 font-light"><span className="font-bold">Paso 5:</span> Termina la consulta. Tú decides cuando quieres terminar la consulta. Solo tienes que colgar o cerrar el chat.</p>
                <p className=" font-light">
                    También puedes agendar previamente una cita. La disponibilidad de horario varía de acuerdo con la agenda del profesional que selecciones.
                </p>
            </div>}
            {selectedTab === 2 && <div style={contentStyle}>
                <div className="pb-5 text-xl font-bold">Garantía de satisfacción</div>
                <p className="mb-2 font-light">
                    Nuestra garantía de satisfacción es simple: ¡Si no estas contento con tu última consulta es gratis!
                    Queremos que cada conversación en nuestra plataforma cumpla con tus expectativas. Si no es así, nos comprometemos a compensarlo.
                    Si tuviste un problema con la plataforma, puedes ser elegible para recibir un
                    crédito en tu cuenta, hasta un límite de $500 pesos de Mi Paz Mental, que puede usarse en futuras conversaciones.
                </p>
                <p className=" font-light">
                    Llámanos a nuestro centro de servicio al cliente o envíanos un correo electrónico a 
                    hola@mipazmental.com. Navegar el mundo del bienestar mental no siempre es fácil, usar nuestros 
                    servicios si lo debe ser. Tu satisfacción es nuestra prioridad, por favor compártenos 
                    tus comentarios, preguntas o problemas.
                </p>
                <div className="font-bold mt-2">
                    Estamos muy contentos de que estés aquí. 
                </div>
                <p className=" font-light">
                    Gracias por elegirnos.
                </p>
            </div>}
            {selectedTab === 3 && <div style={contentStyle}>
                <div className="pb-5 text-xl font-bold">Estamos para ayudarte</div>
                <p className="mb-2 font-light">
                    En <span className="font-bold">Mi Paz Mental</span> estamos dedicados a ofrecerte la mejor atención y servicio, creemos de 
                    todo corazón que cada experiencia debe ser impecable, y estamos comprometidos a lograrlo.
                </p>
                <p className="mb-2 font-light">
                    Nuestra prioridad es conectarte con los mejores psicólogos, terapeutas y coaches en la industria mientras 
                    protegemos tu seguridad y garantizamos una experiencia de cliente de primer nivel. 
                </p>
                <p className=" font-light">
                    Nuestro centro de atención a clientes esta disponible las 24 horas los 7 días de la semana. 
                    Por favor compártenos tus comentarios, preguntas o problemas.
                </p>
            </div>}

            {/* tabs */}
            {<div className="absolute flex bottom-5 right-5">
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 0 ? 'w-5 bg-primary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(0)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 1 ? 'w-5 bg-primary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(1)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 2 ? 'w-5 bg-primary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(2)} />
                <div
                    className={`rounded-full h-2.5 ml-1 cursor-pointer ${selectedTab === 3 ? 'w-5 bg-primary' : 'w-2.5 bg-white'}`}
                    onClick={() => setTabTo(3)} />
            </div>}
        </div>
    )
}

const SlipitCard = ({ children }) => (
    <div className="flex flex-col w-full border-2 lg:flex-row border-superLight rounded-2xl min-h-44">
        {/* left */}
        <div className='flex flex-col w-full p-4 pb-8 lg:p-8 lg:pr-12 flex-8'>
            {children}
        </div>

        {/* rigth (purple) */}
        <RigthPanel />
    </div>
)

export default SlipitCard